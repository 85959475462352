import { FC, useCallback, useMemo } from 'react';
import { useStoreState } from 'state';
import { isTransfer, ITransfer } from 'types';
import { generateTransferTableColumns } from './utils';
import useUrlValues from 'hooks/useUrlValues';
import Table from '../Table/Table';
import { TableProps } from '../Table/types';

interface OwnProps
  extends Pick<TableProps<ITransfer>, 'isVirtualized' | 'data'> {
  data: ITransfer[];
}

const TransferTable: FC<OwnProps> = ({ data, isVirtualized }) => {
  const { setUrlValue } = useUrlValues('contractId');
  const { transfersByBulkId } = useStoreState((state) => state.TransfersState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);

  const goToTransfer = useCallback(
    (record) =>
      isTransfer(record)
        ? setUrlValue({
            contractId: '',
            bulkPaymentId: '',
            transferId: record.id,
          })
        : setUrlValue({
            contractId: '',
            transferId: '',
            bulkPaymentId: record.id,
          }),
    [setUrlValue]
  );

  const transferTableColumns = useMemo(
    () =>
      generateTransferTableColumns(
        transfersByBulkId,
        goToTransfer,
        currencyByCode
      ),
    [currencyByCode, goToTransfer, transfersByBulkId]
  );

  return (
    <Table<ITransfer>
      onRowClick={goToTransfer}
      data={data}
      columns={transferTableColumns}
      sortable
      isVirtualized={isVirtualized}
      defaultRowHeight={48}
    />
  );
};
export default TransferTable;
