import { ContextMenu, Row } from 'components';
import { SetStateAction, Dispatch, useMemo, useState } from 'react';
import { IWalletTransaction } from '../../types';
import ConversionInner from 'components/shared/ConversionInner/ConversionInner';
import TransferInner from 'components/shared/TransferInner/TransferInner';
import { writeWalletTransactionToSourceSystem } from 'services/transactions';
import { errorHandler } from 'utils/errors';
import WriteWarningPopup from './components/WriteWarningPopup/WriteWarningPopup';
import WriteBankTransferPopup from './components/WriteBankTransferPopup/WriteBankTransferPopup';
import useIntegrationEngine from 'hooks/useIntegrationEngine';
import PopupMatchInvoices from 'components/shared/PopupMatchInvoices/PopupMatchInvoices';
import { walletTransactionToResourceToMatchTo } from './utils';

interface IOwnProps {
  walletTransaction: IWalletTransaction;
  setWalletTransactions: Dispatch<SetStateAction<IWalletTransaction[]>>;
}

const StatementTableActionsCell = ({
  walletTransaction,
  setWalletTransactions,
}: IOwnProps) => {
  const { isIntegratedWithXero } = useIntegrationEngine();
  const [showConversionDetails, setShowConversionDetails] = useState(false);
  const [showTransferDetails, setShowTransferDetails] = useState(false);
  const [showWriteWarningPopup, setShowWriteWarningPopup] = useState(false);
  const [isWritingToSourceSystem, setIsWritingToSourceSystem] = useState(false);
  const [showWriteBankTransferPopup, setShowWriteBankTransferPopup] = useState(
    false
  );
  const [showMatchInvoicesPopup, setShowMatchInvoicesPopup] = useState(false);

  const contextMenuData = useMemo(() => {
    const menuItems = [
      {
        id: 'details',
        title: 'Details',
        onClick: () => {
          if (walletTransaction.type === 'FX Exchange') {
            setShowConversionDetails(true);
          }

          if (
            walletTransaction.type === 'Transfer' ||
            walletTransaction.type === 'Transfer in' ||
            walletTransaction.type === 'Transfer out'
          ) {
            setShowTransferDetails(true);
          }
        },
      },
    ];

    if (
      (walletTransaction.type === 'FX Exchange' ||
        walletTransaction.type === 'Transfer out' ||
        walletTransaction.type === 'Transfer in') &&
      walletTransaction.writableResources.find(
        (resource) =>
          resource.status === 'error' ||
          resource.status === 'permission-error' ||
          resource.status === undefined
      ) &&
      isIntegratedWithXero
    ) {
      menuItems.push({
        id: 'write-to-source-system',
        title: 'Write to Xero',
        onClick: async () => {
          setShowWriteWarningPopup(true);
        },
      });
    }

    const isReconciled = walletTransaction.writableResources.filter(
      (writableResource) =>
        !writableResource.status ||
        writableResource.status === 'error' ||
        writableResource.status === 'permission-error'
    ).length === 0;
    const hasInvoicePayments = walletTransaction.writableResources.find(
      (writableResource) => writableResource.type === 'invoicePayment'
    );

    // TODO: consider restricting this just for Xero
    if (
      (walletTransaction.type === 'Transfer in' ||
        walletTransaction.type === 'Transfer out') &&
      !isReconciled &&
      !hasInvoicePayments &&
      isIntegratedWithXero
    ) {
      menuItems.push({
        id: 'create-bank-transfer',
        title: 'Write bank transfer to Xero',
        onClick: () => setShowWriteBankTransferPopup(true),
      });
      menuItems.push({
        id: 'match-invoices',
        title: 'Match payment to invoices in Xero',
        onClick: () => setShowMatchInvoicesPopup(true),
      });
    }

    return menuItems;
  }, [
    isIntegratedWithXero,
    walletTransaction.type,
    walletTransaction.writableResources,
  ]);

  const handleConfirmWrite = async () => {
    try {
      if (isWritingToSourceSystem) {
        return;
      }

      setIsWritingToSourceSystem(true);
      setWalletTransactions((prevState) =>
        prevState.map((walletTransactionInner) =>
          walletTransaction.id === walletTransactionInner.id
            ? {
                ...walletTransactionInner,
                writableResources: walletTransactionInner.writableResources.map(
                  (resource) => {
                    if (
                      resource.status === 'error' ||
                      resource.status === 'permission-error' ||
                      !resource.status
                    ) {
                      return { ...resource, status: 'pending' };
                    }

                    return resource;
                  }
                ),
              }
            : walletTransactionInner
        )
      );
      await writeWalletTransactionToSourceSystem(walletTransaction.id);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsWritingToSourceSystem(false);
    }
  };

  if (contextMenuData.length === 0) {
    return null;
  }

  return (
    <>
      <Row flex={1} justifyContent="flex-end">
        <ContextMenu portal list={contextMenuData} />
      </Row>
      {showConversionDetails && (
        <ConversionInner
          conversionId={walletTransaction.sourceId}
          onClose={() => setShowConversionDetails(false)}
        />
      )}
      {showTransferDetails && (
        <TransferInner
          transferId={walletTransaction.sourceId}
          onClose={() => setShowTransferDetails(false)}
        />
      )}
      {showWriteWarningPopup && (
        <WriteWarningPopup
          onClose={() => setShowWriteWarningPopup(false)}
          onConfirm={handleConfirmWrite}
        />
      )}
      {showWriteBankTransferPopup && (
        <WriteBankTransferPopup
          onClose={() => setShowWriteBankTransferPopup(false)}
          record={walletTransaction}
          onSuccess={() => {
            setWalletTransactions((prevState) =>
              prevState.map((walletTransactionInner) =>
                walletTransaction.id === walletTransactionInner.id
                  ? {
                      ...walletTransactionInner,
                      writableResources: walletTransactionInner.writableResources.map(
                        (resource) => {
                          if (
                            resource.status === 'error' ||
                            resource.status === 'permission-error' ||
                            !resource.status
                          ) {
                            return { ...resource, status: 'pending' };
                          }

                          return resource;
                        }
                      ),
                    }
                  : walletTransactionInner
              )
            );
          }}
        />
      )}
      {showMatchInvoicesPopup && (
        <PopupMatchInvoices
          onClose={() => setShowMatchInvoicesPopup(false)}
          resourceToMatchTo={walletTransactionToResourceToMatchTo(
            walletTransaction
          )}
          onSubmitCallback={async () => {
            setShowMatchInvoicesPopup(false);
          }}
        />
      )}
    </>
  );
};

export default StatementTableActionsCell;
