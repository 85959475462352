import { action, Action, thunk, Thunk } from 'easy-peasy';
import {
  getEntityCashflowsSettings,
  updateEntityCashflowsSettings,
} from 'services/firebase';
import { IEntityCashflowsSettings } from 'types/entities';

export interface EntityStateModel {
  entityCashflowsSettings: IEntityCashflowsSettings | null;
  setState: Action<EntityStateModel, ['entityCashflowsSettings', IEntityCashflowsSettings]>;
  getEntityCashflowsSettings: Thunk<EntityStateModel, string>;
  updateEntityCashflowsSettings: Thunk<
    EntityStateModel,
    {
      entityId: string;
      settings: IEntityCashflowsSettings;
    }
  >;
}

export const EntityState: EntityStateModel = {
  entityCashflowsSettings: null,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    state[prop] = to;
  }),
  getEntityCashflowsSettings: thunk(async ({ setState }, entityId) => {
    const { data } = await getEntityCashflowsSettings(entityId);

    if (data.data) {
      setState(['entityCashflowsSettings', data.data]);
    }
  }),
  updateEntityCashflowsSettings: thunk(
    async ({ setState }, { entityId, settings }) => {
      await updateEntityCashflowsSettings(entityId, settings);
      setState(['entityCashflowsSettings', settings]);
    }
  ),
};
