import { FC } from 'react';

import { Paragraph, Row, TextHint, Col, Subtitle, Icon } from 'components';
import { parseIntoCurrencyString } from 'utils';
import { ICurrency, Nullable } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import ExchangeRate from '../ExchangeRate/ExchangeRate';

interface TransferConvertInfoProps {
  buyAmount: number;
  sellAmount: number;
  buyCurrency: ICurrency;
  sellCurrency: ICurrency;
  rate: number;
  conversionFeeRate: number;
  isRateLoading: boolean;
  fee: Nullable<number>;
  payAmount: number;
  paymentFeeLabel: string;
  isBulkPayment?: boolean;
}

const TransferConvertInfo: FC<TransferConvertInfoProps> = ({
  buyAmount,
  sellAmount,
  buyCurrency,
  sellCurrency,
  rate,
  conversionFeeRate,
  isRateLoading,
  fee,
  payAmount,
  paymentFeeLabel,
  isBulkPayment,
}) => {
  return (
    <Col>
      <Subtitle variant="bold" mb>
        Summary
      </Subtitle>

      <RowInfo>
        <Paragraph>Recipient gets</Paragraph>

        <Row>
          <Paragraph variant="bold" mr>
            {`${buyCurrency.code} ${parseIntoCurrencyString(buyAmount)}`}
          </Paragraph>

          <Icon icon={buyCurrency.countryCode} />
        </Row>
      </RowInfo>

      {!isBulkPayment && (
        <ExchangeRate
          rate={rate}
          sellCurrencyCode={sellCurrency.code}
          buyCurrencyCode={buyCurrency.code}
          isRateLoading={isRateLoading}
        />
      )}

      <RowInfo alignSelf="stretch">
        <Paragraph>We convert</Paragraph>
        <Row>
          <Paragraph variant="bold" mr>
            {`${sellCurrency?.code} ${parseIntoCurrencyString(sellAmount)}`}
          </Paragraph>

          <Icon icon={sellCurrency?.countryCode} />
        </Row>
      </RowInfo>

      {!isBulkPayment && (
        <TextHint>
          Includes conversion fee {sellCurrency?.code}{' '}
          {parseIntoCurrencyString(sellAmount * conversionFeeRate)}
        </TextHint>
      )}

      {!!fee && (
        <RowInfo>
          <Paragraph>{`Payment fee ${paymentFeeLabel}`}</Paragraph>
          <Paragraph variant="bold">
            {sellCurrency?.code} {parseIntoCurrencyString(fee)}
          </Paragraph>
        </RowInfo>
      )}

      <RowInfo>
        <Paragraph>You pay</Paragraph>
        <Paragraph variant="bold">
          {sellCurrency?.code} {parseIntoCurrencyString(payAmount)}
        </Paragraph>
      </RowInfo>
    </Col>
  );
};

export default TransferConvertInfo;
