import { FC, useEffect, useState, useMemo } from 'react';
import { StaleCheckboxControlled, Row, PermissionsChecker } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { EntityInputField } from './NotificationSettings.styles';
import { useStoreState } from 'state';
import { Notify } from 'utils';
import { updateEntitySettings } from 'services/firebase';
import { IEntitySettings } from 'types/entities';
import { useTheme } from 'styled-components';

import { SettingsField } from 'pages/Settings/Settings.styles';
import Button from 'components/shared/Button/Button';
import CreatableSelectMenu from 'components/shared/CreatableSelectMenu/CreatableSelectMenu';
import {
  createCreatableSelectMenuOption,
  onValidateEmails,
} from 'components/shared/CreatableSelectMenu/utils';
import { CreatableSelectMenuOption } from 'components/shared/CreatableSelectMenu/types';
import {
  WhiteFooterContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';

const NotificationSettings: FC = () => {
  const theme = useTheme();
  const { userEntity } = useStoreState((state) => state.UserState);
  const [isLoading, setIsLoading] = useState(false);

  const defaultValues = useMemo(() => {
    return {
      ...userEntity,
      emails:
        userEntity?.email?.split(',').map(createCreatableSelectMenuOption) ||
        [],
    };
  }, [userEntity]);

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<
    Omit<
      IEntitySettings,
      'riskSettingCommittedCashFlows' | 'riskSettingExpectedCashFlows'
    > & { emails: CreatableSelectMenuOption[] }
  >({
    mode: 'all',
    defaultValues,
  });

  useEffect(() => {
    if (userEntity) {
      reset(defaultValues);
    }
  }, [userEntity, reset, defaultValues]);

  const onSubmit = async (values: any) => {
    try {
      setIsLoading(true);

      const response = await updateEntitySettings({
        ...values,
        email: values.emails
          ?.map((email: CreatableSelectMenuOption) => email.value)
          .join(','),
      });

      if (response?.data?.success) {
        reset(values);
        Notify.success('Notification settings saved successfully!');
      }
    } catch (error: any) {
      Notify.error(error.response?.data?.error);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form id="notification-form" onSubmit={handleSubmit(onSubmit)}>
      <WhiteContentContainer>
        <EntityInputField>
          <Controller
            name="emails"
            control={control}
            rules={{
              validate: onValidateEmails,
            }}
            render={({ value, name, onChange }) => (
              <CreatableSelectMenu
                name={name}
                label="Notifications sent to"
                data={value}
                onChange={onChange}
                disabled={isLoading}
                // @ts-expect-error - Property 'message' does not exist on type '(DeepMap<CreatableSelectOption, FieldError> | undefined)[]'
                error={errors.emails?.message}
              />
            )}
          />
        </EntityInputField>

        <SettingsField>
          <StaleCheckboxControlled
            id="shouldSendTransactionConfirmations"
            name="shouldSendTransactionConfirmations"
            control={control}
            Label="Email me transaction confirmations"
            disabled={isLoading}
          />
        </SettingsField>
        <StaleCheckboxControlled
          id="shouldSendRemittanceCopiesToSelf"
          name="shouldSendRemittanceCopiesToSelf"
          control={control}
          Label="Email me copies of Remittances"
          disabled={isLoading}
        />

        <StaleCheckboxControlled
          id="shouldSendApprovalAlerts"
          name="shouldSendApprovalAlerts"
          control={control}
          Label="Send approval alerts"
          disabled={isLoading}
        />

        <StaleCheckboxControlled
          id="notificationSettingsRiskAlertsEnabled"
          name="notificationSettingsRiskAlertsEnabled"
          control={control}
          Label="Send risk alerts"
          disabled={isLoading}
        />
      </WhiteContentContainer>
      <WhiteFooterContainer>
        <Row columnGap={theme.spacing.m} justifyContent="flex-start">
          <PermissionsChecker action="update" resource="settings">
            <Button
              variant="secondary"
              form="notification-form"
              type="submit"
              disabled={isLoading}
              isLoading={isLoading}
            >
              Save
            </Button>
          </PermissionsChecker>
        </Row>
      </WhiteFooterContainer>
    </form>
  );
};

export default NotificationSettings;
