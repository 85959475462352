import { FC, useState, Dispatch, SetStateAction, useEffect } from 'react';
import { Button, Popup, Row, Title } from 'components';
import { useTheme } from 'styled-components';
import {
  IHedgeRecommendationResult,
  IHedgeRecommendationResults,
} from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { useStoreState } from 'state';
import { roundToPrecision } from 'utils';
import dayjs from 'dayjs';
import { createRateContract } from 'services/firebase';
import { DB_DATE_FORMAT } from 'variables';
import ConvertInfo from './components/ConvertInfo/ConvertInfo';
import usePrebookRate from 'hooks/usePrebookRate';

interface IOwnProps {
  onClose: () => void;
  hedgeRecommendationForPrebook: IHedgeRecommendationResult;
  setRecommendations: Dispatch<
    SetStateAction<IHedgeRecommendationResults | undefined>
  >;
}

const getSellAndBuyAmounts = ({
  newHedgeAmount,
  rate,
  expectedCashFlowsAmount,
}: {
  newHedgeAmount: number;
  rate: number | null;
  expectedCashFlowsAmount: number;
}) => {
  if (expectedCashFlowsAmount >= 0) {
    const sellAmountAsNumber = newHedgeAmount;
    const buyAmountAsNumber = newHedgeAmount * (rate || 0);
    return { sellAmountAsNumber, buyAmountAsNumber };
  } else {
    const buyAmountAsNumber = newHedgeAmount;
    const sellAmountAsNumber = newHedgeAmount / (rate || 0);
    return { sellAmountAsNumber, buyAmountAsNumber };
  }
};

const PrebookHedgesPopup: FC<IOwnProps> = ({
  onClose,
  hedgeRecommendationForPrebook,
  setRecommendations,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const [isPrebookCreating, setIsPrebookCreating] = useState(false);

  const {
    rate,
    bookingFeeRate,
    conversionFeeRate,
    isRateLoading,
    getFwdRateAndFees,
  } = usePrebookRate({
    sellCurrencyCode: hedgeRecommendationForPrebook.sellCurrency,
    buyCurrencyCode: hedgeRecommendationForPrebook.buyCurrency,
  });

  // New hedge amount
  const { sellAmountAsNumber, buyAmountAsNumber } = getSellAndBuyAmounts({
    rate,
    newHedgeAmount: hedgeRecommendationForPrebook.newHedgeAmount,
    expectedCashFlowsAmount:
      hedgeRecommendationForPrebook.expectedCashFlowsAmount,
  });
  const sellCurrency = currencyByCode(
    hedgeRecommendationForPrebook.sellCurrency
  );
  const buyCurrency = currencyByCode(hedgeRecommendationForPrebook.buyCurrency);

  const onPrebookHandler = async () => {
    if (!rate || !sellCurrency || !buyCurrency) {
      return undefined;
    }

    setIsPrebookCreating(true);

    /**
     * We need to take Math.abs as BE can return negative values to
     * indicate direction of the exposure and corresponding hedge recommendation.
     */
    const bookingFeeAmount = Math.abs(
      roundToPrecision(Math.abs(sellAmountAsNumber) * bookingFeeRate)
    );
    const sellAmountForBackend = Math.abs(
      roundToPrecision(Math.abs(sellAmountAsNumber) - bookingFeeAmount)
    );
    const buyAmountForBackend = Math.abs(roundToPrecision(buyAmountAsNumber));
    const totalAmountForBackend = Math.abs(
      roundToPrecision(sellAmountAsNumber)
    );

    const data = await createRateContract({
      // booking fee is included into sell amount on the client, but we need pure sell amount on the BE
      sellCurrency: hedgeRecommendationForPrebook.sellCurrency,
      buyCurrency: hedgeRecommendationForPrebook.buyCurrency,

      sellAmount: sellAmountForBackend,
      buyAmount: buyAmountForBackend,
      rate,
      feeRate: bookingFeeRate,
      feeAmount: bookingFeeAmount,
      conversionFeeRate,
      totalAmount: totalAmountForBackend,
      expiryDate: dayjs(hedgeRecommendationForPrebook.expiryDate).format(
        DB_DATE_FORMAT
      ),
    });

    if (data?.success) {
      setRecommendations((prevRecommendations) => {
        if (!prevRecommendations) {
          return undefined;
        }

        const updatedRecommendation = prevRecommendations[
          hedgeRecommendationForPrebook.currency
        ].find(
          (recommendation) =>
            recommendation.period === hedgeRecommendationForPrebook.period
        );

        if (!updatedRecommendation) {
          return;
        }

        const hedgeAmountAmountToAdd =
          updatedRecommendation.expectedCashFlowsAmount < 0
            ? data.data.buyAmount
            : data.data.totalAmount * -1;

        return {
          ...prevRecommendations,
          [hedgeRecommendationForPrebook.currency]: prevRecommendations[
            hedgeRecommendationForPrebook.currency
          ].map((recommendation) =>
            recommendation.period === hedgeRecommendationForPrebook.period
              ? {
                  ...recommendation,
                  existingHedgeAmount:
                    updatedRecommendation.existingHedgeAmount +
                    hedgeAmountAmountToAdd,
                  newHedgeAmount: 0,
                }
              : recommendation
          ),
        };
      });

      onClose();
    }

    setIsPrebookCreating(false);
  };

  useEffect(() => {
    getFwdRateAndFees({
      sellCurrency: hedgeRecommendationForPrebook.sellCurrency,
      buyCurrency: hedgeRecommendationForPrebook.buyCurrency,
      dateString: dayjs(hedgeRecommendationForPrebook.expiryDate).format(
        DB_DATE_FORMAT
      ),
    });
  }, [hedgeRecommendationForPrebook, getFwdRateAndFees]);

  useEffect(() => {
    if (rate) {
      setRecommendations((prevRecommendations) => {
        if (!prevRecommendations) {
          return undefined;
        }

        const updatedRecommendationsForCurrency = prevRecommendations[
          hedgeRecommendationForPrebook.currency
        ].map((recommendation) => ({
          ...recommendation,
          rate: recommendation.expectedCashFlowsAmount < 0 ? 1 / rate : rate,
        }));

        return {
          ...prevRecommendations,
          [hedgeRecommendationForPrebook.currency]: updatedRecommendationsForCurrency,
        };
      });
    }
  }, [hedgeRecommendationForPrebook, rate, setRecommendations]);

  return (
    <Popup
      HeaderContent={<Title variant="h3">Confirm hedge</Title>}
      FooterContent={
        <Row flex={1} gap={theme.spacing.m}>
          <Button
            variant="secondary"
            disabled={isPrebookCreating || isRateLoading}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            disabled={isPrebookCreating || isRateLoading}
            isLoading={isRateLoading || isPrebookCreating}
            variant="primary"
            onClick={onPrebookHandler}
          >
            Confirm
          </Button>
        </Row>
      }
      width="439px"
      onClose={onClose}
    >
      {buyCurrency && sellCurrency && (
        <ConvertInfo
          sellCurrency={sellCurrency}
          buyCurrency={buyCurrency}
          rate={rate}
          sellAmountAsNumber={sellAmountAsNumber}
          buyAmountAsNumber={buyAmountAsNumber}
          bookingFeeAmount={sellAmountAsNumber * bookingFeeRate}
          date={new Date(hedgeRecommendationForPrebook.expiryDate)}
          fromDate={new Date(hedgeRecommendationForPrebook.from)}
          isRateLoading={isRateLoading}
        />
      )}
    </Popup>
  );
};

export default PrebookHedgesPopup;
