import { Dispatch, FC, SetStateAction, useCallback, useState } from 'react';
import {
  getInvoiceSimpleTransferLink,
  getInvoiceTransferOrSimpleTransferLink,
} from 'utils/links';
import { IContact, IInvoiceFromSearch, PartialContact } from 'types';
import { useHistory, useLocation } from 'react-router-dom';
import { ActionButton } from 'components/shared/ActionButton/ActionButton.styles';
import useInvoicesApprovalStatus from 'hooks/useInvoiceApprovalStatus';
import { isCurrencyEnabledForBuying } from 'utils/currencies';
import { useStoreState } from 'state';
import useInvoicePrebookAndTransfer from '../../hooks/useInvoicePrebookAndTransfer';
import StaleTooltip from 'components/shared/StaleTooltip/StaleTooltip';
import useInvoiceFromSearchRecord from 'hooks/useInvoiceFromSearchRecord';
import PermissionsChecker from 'components/PermissionsChecker/PermissionsChecker';
import dayjs from 'dayjs';
import { getPartialRecipientFromInvoiceFromSearch } from '../../utils';
import { useTheme } from 'styled-components';
import { Row } from 'components/shared/Row/Row';
import { Paragraph } from 'components/shared/Typography/Typography';
import { deletePaymentRunInvoice } from 'services/paymentRuns';
import { errorHandler } from 'utils/errors';

interface OwnProps {
  record: IInvoiceFromSearch;
  updateInMemoryInvoices: (
    updateFunction: (invoices: IInvoiceFromSearch[]) => IInvoiceFromSearch[]
  ) => void;
  setContactForEdit?: Dispatch<
    SetStateAction<IContact | PartialContact | null>
  >;
  setInvoiceForAddContact?: Dispatch<SetStateAction<IInvoiceFromSearch | null>>;
  paymentRunId?: string;
}

const DoActionCell: FC<OwnProps> = ({
  record,
  updateInMemoryInvoices,
  setContactForEdit,
  setInvoiceForAddContact,
  paymentRunId,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { pathname } = useLocation();
  const risks = pathname.includes('risks');
  const { currencies } = useStoreState((state) => state.CurrenciesState);
  const { balanceByCurrencyCode } = useStoreState(
    ({ BalancesState }) => BalancesState
  );
  const { user, userEntity, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const { recipientById } = useStoreState(
    ({ RecipientsState }) => RecipientsState
  );
  const { goToInvoicePrebook } = useInvoicePrebookAndTransfer();
  const {
    isUpdatingInvoicesApprovalStatus,
    updateInvoicesApprovalStatus,
  } = useInvoicesApprovalStatus();
  const {
    isDisabled,
    isSameCurrency,
    isPayable,
    isCanBePaid,
    isApprovable,
    isApprovableByCurrentUser,
    isPrebookable,
    submittableForReview,
    isReceivable,
  } = useInvoiceFromSearchRecord({ record });
  const isInvoiceCurrencyEnabledForBuying = isCurrencyEnabledForBuying({
    currencyCode: record.currency,
    currencies,
  });

  const balanceByCurrency = balanceByCurrencyCode(record.currency);
  const isInvoiceWithContact = !!record.contactId;
  const canPayWithBalance =
    isCanBePaid && !isSameCurrency && !!balanceByCurrency;

  const requiredNumberOfApprovalsOnUserEntity =
    userEntity?.approvalSettings?.requiredNumberOfApprovals || 1;

  const recipientToUse = getPartialRecipientFromInvoiceFromSearch({
    recipientById,
    invoice: record,
  });
  const [isUpdatingPaymentRun, setIsUpdatingPaymentRun] = useState(false);

  const removeInvoiceFromPaymentRun = useCallback(
    async (paymentRunInvoiceId: string) => {
      if (paymentRunId) {
        try {
          setIsUpdatingPaymentRun(true);
          await deletePaymentRunInvoice({
            paymentRunId: paymentRunId,
            paymentRunInvoiceId,
            regeneratePaymentRun: false,
          });
          updateInMemoryInvoices((invoices) =>
            invoices.filter((invoice) => invoice.id !== paymentRunInvoiceId)
          );
        } catch (error: any) {
          errorHandler(error);
        } finally {
          setIsUpdatingPaymentRun(false);
        }
      }
    },
    [paymentRunId, updateInMemoryInvoices]
  );

  const renderContent = () => {
    if (isDisabled) {
      return null;
    }

    if (paymentRunId) {
      return (
        <PermissionsChecker action="update" resource="payment_runs">
          <ActionButton
            disabled={isUpdatingPaymentRun}
            onClick={() => removeInvoiceFromPaymentRun(record.id)}
          >
            Remove
          </ActionButton>
        </PermissionsChecker>
      );
    }

    if (
      !isReceivable &&
      ((recipientToUse && !recipientToUse.enabled) || !isInvoiceWithContact)
    ) {
      return (
        <ActionButton
          as="button"
          onClick={(e: any) => {
            e.stopPropagation();

            // contacts with ID from invoices
            if (
              record.contactId &&
              recipientToUse?.enabled &&
              recipientToUse?.id
            ) {
              setContactForEdit?.(recipientToUse);
            } else {
              setInvoiceForAddContact?.(record);
            }
          }}
        >
          Add recipient
        </ActionButton>
      );
    }

    if (submittableForReview) {
      return (
        <ActionButton
          disabled={isUpdatingInvoicesApprovalStatus}
          onClick={(event) => {
            event.stopPropagation();
            updateInvoicesApprovalStatus({
              invoiceIds: [record.id],
              approvalStatus: 'submitted',
            }).then(() =>
              updateInMemoryInvoices((invoices) =>
                invoices.map((invoice) => {
                  if (invoice.id === record.id) {
                    return {
                      ...invoice,
                      approvalStatus: 'submitted',
                    };
                  }
                  return invoice;
                })
              )
            );
          }}
        >
          Submit
        </ActionButton>
      );
    }

    if (isApprovable) {
      return (
        <StaleTooltip
          content={<Paragraph color="white">Awaiting 2nd Approval</Paragraph>}
          placement="left"
          disabled={isApprovableByCurrentUser}
          strategy="absolute"
          containerWidth="100%"
        >
          <ActionButton
            disabled={
              isUpdatingInvoicesApprovalStatus || !isApprovableByCurrentUser
            }
            onClick={async (event) => {
              event.stopPropagation();
              await updateInvoicesApprovalStatus({
                invoiceIds: [record.id],
                approvalStatus: 'approved',
              });

              updateInMemoryInvoices((invoices) =>
                invoices.map((invoice) => {
                  if (invoice.id === record.id) {
                    return {
                      ...invoice,
                      approvalStatus:
                        requiredNumberOfApprovalsOnUserEntity === 2 &&
                        record.approvedBy
                          ? 'approved'
                          : 'submitted',
                      approvedBy: record.approvedBy
                        ? record.approvedBy
                        : user?.id,
                      approvedAt: record.approvedAt
                        ? record.approvedAt
                        : dayjs().toISOString(),
                      approvedBy2: record.approvedBy ? user?.id : '',
                      approvedAt2: record.approvedAt2
                        ? dayjs().toISOString()
                        : '',
                    };
                  }
                  return invoice;
                })
              );
            }}
          >
            Approve
          </ActionButton>
        </StaleTooltip>
      );
    }

    if (
      isInvoiceCurrencyEnabledForBuying &&
      isCanBePaid &&
      isPayable &&
      isInvoiceWithContact
    ) {
      return (
        <PermissionsChecker action="create" resource="transfers">
          <Row flex={1} gap={theme.spacing.xxs}>
            <ActionButton
              disabled={isUpdatingInvoicesApprovalStatus}
              onClick={(event) => {
                event.stopPropagation();

                history.push(
                  getInvoiceTransferOrSimpleTransferLink(
                    record,
                    entityCurrencyCode
                  )
                );
              }}
            >
              Pay {canPayWithBalance ? entityCurrencyCode : ''}
            </ActionButton>

            {canPayWithBalance && (
              <ActionButton
                disabled={isUpdatingInvoicesApprovalStatus}
                onClick={(event) => {
                  event.stopPropagation();

                  history.push(getInvoiceSimpleTransferLink(record));
                }}
              >
                Pay {record.currency}
              </ActionButton>
            )}
          </Row>
        </PermissionsChecker>
      );
    }

    if (
      !isSameCurrency &&
      isPrebookable &&
      !!risks &&
      isInvoiceCurrencyEnabledForBuying
    ) {
      return (
        <PermissionsChecker action="create" resource="prebooks">
          <ActionButton
            disabled={isUpdatingInvoicesApprovalStatus}
            onClick={(event) => {
              event.stopPropagation();
              goToInvoicePrebook(record);
            }}
          >
            Book
          </ActionButton>
        </PermissionsChecker>
      );
    }

    return null;
  };

  return <>{renderContent()}</>;
};

export default DoActionCell;
