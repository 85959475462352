import { ContextMenu, Row } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import ContactCellNew from 'components/shared/ContactCellNew/ContactCellNew';
import DateCell from 'components/shared/DateCell/DateCell';
import InvoiceNumberCell from 'components/shared/InvoiceNumberCell/InvoiceNumberCell';
import { CellProps, Column } from 'react-table';
import { IInvoice, IInvoiceFromSearch } from 'types';
import { Notify } from 'utils';
import {
  isInvoiceFromSearch,
  isInvoiceOrInvoiceFromSearchFromXero,
} from 'utils/invoices';
import InvoiceContactCell from '../components/InvoiceContactCell/InvoiceContactCell';

export const generateInvoiceTableColumns = (
  goToInvoice: (record: IInvoice | IInvoiceFromSearch) => void
): Column<IInvoice | IInvoiceFromSearch>[] => {
  return [
    {
      accessor: 'invoiceNumber',
      Header: 'Invoice',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IInvoice | IInvoiceFromSearch>) => (
        <InvoiceNumberCell record={row.original} />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      id: 'recipientName',
      Header: 'Recipient',
      disableSortBy: true,
      Cell: ({ row: { original } }: CellProps<IInvoice | IInvoiceFromSearch>) =>
        isInvoiceFromSearch(original) ? (
          <ContactCellNew
            invoice={original}
            withReviewStatus={false}
            withSourceSystemName={false}
          />
        ) : (
          <InvoiceContactCell invoice={original} />
        ),
      width: 150,
      minWidth: 110,
    },
    {
      accessor: 'dueDate',
      Header: 'Due date',
      disableSortBy: true,
      Cell: ({ value }: CellProps<IInvoice | IInvoiceFromSearch>) => (
        <DateCell value={value} />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      id: 'CCY',
      Header: 'Total amount',
      disableSortBy: true,
      Cell: ({ row }: CellProps<IInvoice | IInvoiceFromSearch>) => (
        <AmountCell
          amount={row.original.totalAmount}
          currencyCode={row.original.currency}
        />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      accessor: 'amountPaid',
      Header: 'Amount paid',
      disableSortBy: true,
      Cell: ({ value, row }: CellProps<IInvoice | IInvoiceFromSearch>) => (
        <AmountCell amount={value} currencyCode={row.original.currency} />
      ),
      width: 130,
      minWidth: 110,
    },
    {
      id: 'dots',
      Header: () => null,
      disableSortBy: true,
      Cell: ({ row }: CellProps<IInvoice | IInvoiceFromSearch>) => {
        const isFromXero = isInvoiceOrInvoiceFromSearchFromXero(row.original);

        return (
          <Row
            className="delete"
            style={{ justifyContent: 'flex-end', flex: 1 }}
          >
            <ContextMenu
              strategy="fixed"
              list={[
                {
                  id: 0,
                  title: 'See details',
                  onClick: () => goToInvoice(row.original),
                },
                ...(isFromXero
                  ? [
                      {
                        id: 'show-in-xero',
                        title: 'Open in Xero',
                        onClick: () => {
                          const deepLinkUrl =
                            (row.original as IInvoice).externalRefs
                              ?.deepLinkUrl ||
                            (row.original as IInvoiceFromSearch)
                              .externalRefsDeepLinkUrl;
                          if (deepLinkUrl) {
                            window.open(deepLinkUrl);
                          } else {
                            Notify.info('Xero link is not available');
                          }
                        },
                      },
                    ]
                  : []),
              ]}
            />
          </Row>
        );
      },
      width: 60,
      minWidth: 70,
    },
  ];
};
