import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';
import { useHistory } from 'react-router';
import { useStoreActions, useStoreState } from 'state';
import {
  getInvoicePrebookLink,
  getInvoiceTransferOrSimpleTransferLink,
} from 'utils/links';
import { IInvoice, IInvoiceFromSearch } from 'types';

const useInvoicePrebookAndTransfer = () => {
  const history = useHistory();
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  const goToInvoicePrebook = (record: IInvoiceFromSearch | IInvoice) => {
    if (isAwaitingRegistration || isAwaitingComplianceReview) {
      setShowLimitedAccess(true);
    } else if (entityCurrencyCode) {
      history.push(getInvoicePrebookLink(record, entityCurrencyCode));
    }
  };

  const goToInvoiceTransfer = (record: IInvoiceFromSearch | IInvoice) => {
    if (isAwaitingRegistration || isAwaitingComplianceReview) {
      setShowLimitedAccess(true);
      return;
    }

    history.push(
      getInvoiceTransferOrSimpleTransferLink(record, entityCurrencyCode)
    );
  };

  return {
    goToInvoicePrebook,
    goToInvoiceTransfer,
  };
};

export default useInvoicePrebookAndTransfer;
