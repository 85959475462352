import { IInvoice } from 'types';
import { useTheme } from 'styled-components';
import { useStoreState } from 'state';
import {
  Paragraph,
  ParagraphWithEllipsis,
} from 'components/shared/Typography/Typography';
import { Col } from 'components/shared/Col/Col';
import InlineLoader from 'components/shared/InlineLoader/InlineLoader';

interface IOwnProps {
  invoice: IInvoice;
}

const InvoiceContactCell: React.FC<IOwnProps> = ({ invoice }) => {
  const theme = useTheme();
  const { isLoadingRecipients } = useStoreState(
    (state) => state.RecipientsState
  );
  const { recipientById } = useStoreState((state) => state.RecipientsState);

  const recipient = recipientById(invoice.contactId);

  const recipientNameToUse = recipient?.recipientName ?? 'Contact is missing';

  if (isLoadingRecipients) {
    return <InlineLoader />;
  }

  return (
    <Paragraph>
      <Col rowGap={theme.spacing.xxs} overflow="hidden">
        <ParagraphWithEllipsis maxWidth="150px">
          {recipientNameToUse}
        </ParagraphWithEllipsis>
      </Col>
    </Paragraph>
  );
};

export default InvoiceContactCell;
