import { IInvoiceFromSearch } from 'types';

interface IFilterOutRejectedInvoicesProps {
  invoices: IInvoiceFromSearch[];
  invoicesForRejection: IInvoiceFromSearch[];
}

export const filterOutRejectedInvoices = ({
  invoices,
  invoicesForRejection,
}: IFilterOutRejectedInvoicesProps): IInvoiceFromSearch[] =>
  invoices.reduce<IInvoiceFromSearch[]>((acc, invoiceFromMemory) => {
    const hideInvoice = !!invoicesForRejection.find(
      (invoiceForReject) => invoiceForReject.id === invoiceFromMemory.id
    );

    if (hideInvoice) {
      return acc;
    }

    return [...acc, invoiceFromMemory];
  }, []);
