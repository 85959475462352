import { useHistory } from 'react-router';
import { useStoreState } from 'state';
import useUrlValues from 'hooks/useUrlValues';
import { openInNewTab } from 'utils';
import {
  getHedgeInvoicesPageLink,
  getHedgeRecommendationsPageLink,
  getRiskSettingsPageLink,
} from 'utils/links';
import { BOOK_A_DEMO_URL, SCHEDULE_MEET_URL } from 'variables';
import { useCreatePrebooksPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';
import { INetWorthValues, TCashflowAtRiskNew } from 'types';

type TReduceRisksCard = {
  id: number;
  icon: string;
  title: string;
  textOne: string | JSX.Element;
  textTwo?: string | JSX.Element;
  buttonText: string;
  onClick?: () => void;
  disabled?: boolean;
};

const useReduceRisks = (cashflowsRisksData: {
  perPeriod: TCashflowAtRiskNew[];
  netWorthValues: INetWorthValues;
}) => {
  const history = useHistory();
  const hasCreatePrebooksPermission = useCreatePrebooksPermissionsCheck();
  const { cashflowsRisks } = useStoreState((state) => state.ReferenceDataState);
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { currency: currencyCode } = useUrlValues('currency');
  const currency = currencyByCode(currencyCode);
  const cashflowRisksByCurrency = currency
    ? cashflowsRisks?.[currency.code]
    : undefined;

  const cards: TReduceRisksCard[] = [
    {
      id: 1,
      icon: 'risk-convert-ico',
      title: 'Micro hedging',
      textOne: `Review and hedge individual invoices and cashflows contributing to your FX risks.`,
      textTwo:
        'Suitable for companies with large individual foreign cashflows or granular balance sheet hedging.',
      buttonText: 'See contributors',
      onClick: () => history.push(getHedgeInvoicesPageLink()),
      disabled: !hasCreatePrebooksPermission,
    },
    {
      id: 2,
      icon: 'risk-book-ico',
      title: 'Cashflow hedging',
      textOne:
        'Simplify hedging decisions by targeting specific hedge levels for each hedging period.',
      textTwo:
        'Get more stable FX rates by layering hedges over time and follow suitable hedge ratios.',
      buttonText: 'Calculate hedges',
      onClick: () => history.push(getHedgeRecommendationsPageLink()),
    },
    {
      id: 3,
      icon: 'risk-fix-fx-ico',
      title: 'Dynamic hedging',
      textOne:
        'Adjust hedge ratios based on FX rate levels while respecting hedging policy requirements.',
      textTwo: 'Catch FX rates when they are good or reach your targets.',
      buttonText: 'Calculate hedges',
      onClick: () => {},
      disabled: true,
    },
    {
      id: 4,
      icon: 'search-ico',
      title: 'Protect budget rates',
      textOne: 'Set up and protect your budget rates.',
      textTwo: `Suitable for companies that don't want to fix FX rates in advance but want to protect from large FX swings.`,
      buttonText: 'Speak to sales',
      onClick: () => openInNewTab(BOOK_A_DEMO_URL),
    },
    {
      id: 5,
      icon: 'risk-forecasted-ico',
      title: 'Set your hedge ratio targets',
      textOne:
        'Review your target hedge ratios to calculate the right hedge amounts based on your exposures.',
      buttonText: 'Set hedge ratios',
      onClick: () => history.push(getRiskSettingsPageLink({ step: '4' })),
    },
    {
      id: 6,
      icon: 'support-ico',
      title: 'Schedule a consultation',
      textOne: 'Unsure what to do - talk to our experts.',
      textTwo:
        'We have helped 1000s of businesses and blue-chip corporations worldwide since 1990s.',
      buttonText: 'Schedule',
      onClick: () => openInNewTab(SCHEDULE_MEET_URL),
    },
  ];

  return {
    cards,
    isLoading: !cashflowRisksByCurrency,
    cashflowRisksByCurrency,
  };
};

export default useReduceRisks;
