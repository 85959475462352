import { FC, useCallback, useEffect, useMemo, useState } from 'react';
import { RouteComponentProps } from 'react-router-dom';
import { useTheme } from 'styled-components';
import {
  StaleButtonsPopup,
  Paragraph,
  TableSearchInput,
  Row,
  PermissionsChecker,
  Icon,
  StaleInfo,
} from 'components';
import { useStoreActions, useStoreState } from 'state';
import { filterTypes, generateTableColumns } from './utils';
import { IContact, TRANSFER_TYPE } from 'types';
import Table from 'components/shared/Table/Table';
import useTableFiltering from 'hooks/useTableFiltering';
import Button from 'components/shared/Button/Button';
import useUrlValues from 'hooks/useUrlValues';
import {
  deleteQueryStringKey,
  updateQueryStringValueWithoutNavigation,
} from 'utils/navigation';
import { TableHeader } from 'components/shared/TableHeader/TableHeader.styles';
import AddContactForm from 'components/shared/AddContactForm/AddContactForm';

const Contacts: FC<RouteComponentProps> = ({ history }) => {
  const theme = useTheme();

  const { recipients } = useStoreState((state) => state.RecipientsState);

  const { contactId: recipientId } = useUrlValues<'contactId'>('contactId');
  const [recipientIdFromQuery, setRecipientIdFromQuery] = useState(recipientId);

  const { userEntity } = useStoreState((state) => state.UserState);
  const { countryByCode } = useStoreState((state) => state.ReferenceDataState);
  const { currencies, currencyByCode } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );
  const { deleteRecipient } = useStoreActions(
    (actions) => actions.RecipientsState
  );

  const sellCurrency = currencyByCode(
    userEntity?.entityCurrency || systemVariables?.defaultSellCurrency
  );
  const [showDeleteRecipient, setShowDeleteRecipient] = useState(false);
  const [recipientIdForDelete, setRecipientIdForDelete] = useState('');
  const [recipientForEdit, setRecipientForEdit] = useState<IContact>();
  const [isAddRecipient, setIsAddRecipient] = useState(false);

  const onDeleteRecipient = async () => {
    await deleteRecipient(recipientIdForDelete);

    setRecipientIdForDelete('');
    setShowDeleteRecipient(false);
  };

  const setRecipientForEditWrapper = useCallback(
    (recipient?: IContact) => {
      if (recipient) {
        setIsAddRecipient(true);
        setRecipientForEdit(recipient);
        updateQueryStringValueWithoutNavigation('contactId', recipient.id);
      } else {
        setIsAddRecipient(false);
        setRecipientForEdit(undefined);
        deleteQueryStringKey('contactId');
      }
    },
    [setIsAddRecipient, setRecipientForEdit]
  );

  // set recipient for edit if we can find one given the query string value
  useEffect(() => {
    if (!!recipientIdFromQuery) {
      const matchingRecipient = recipients.find(
        (recipient) => recipient.id === recipientIdFromQuery
      );
      if (matchingRecipient) {
        setRecipientForEditWrapper(matchingRecipient);
        setRecipientIdFromQuery('');
      }
    }
  }, [
    recipientIdFromQuery,
    recipients,
    setRecipientForEditWrapper,
    setRecipientIdFromQuery,
  ]);

  const { searchValue, setSearchValue, tableRef } = useTableFiltering<
    string,
    IContact
  >({
    filterTypeName: null,
  });

  const tableColumns = useMemo(
    () =>
      generateTableColumns({
        setShowDeleteRecipient,
        setRecipientIdForDelete,
        countryByCode,
        currencies,
        setRecipientForEdit: setRecipientForEditWrapper,
        setIsAddRecipient,
      }),
    [
      setShowDeleteRecipient,
      setRecipientIdForDelete,
      countryByCode,
      currencies,
      setRecipientForEditWrapper,
      setIsAddRecipient,
    ]
  );

  const initialCurrency = recipientForEdit
    ? currencyByCode(recipientForEdit.currency)
    : currencyByCode(systemVariables?.defaultSellCurrency);

  return (
    <>
      <TableHeader gap={theme.spacing.m}>
        <Row gap={theme.spacing.l}>
          <TableSearchInput
            placeholder="Search by contact name"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            width="254px"
          />
          <PermissionsChecker action="create" resource="recipients">
            <Button onClick={() => setIsAddRecipient(true)} variant="link">
              + Add new
            </Button>
          </PermissionsChecker>
        </Row>

        <PermissionsChecker action="create" resource="recipients">
          <StaleInfo
            infoSize={theme.iconSizes.m}
            mode="hover"
            strategy="fixed"
            portal
            placement="top"
            trigger={
              <Button
                variant="link"
                onClick={() => history.push('/app/bulk-contacts')}
              >
                <Icon icon="import-ico" />
              </Button>
            }
          >
            <Paragraph color="white">Upload contacts</Paragraph>
          </StaleInfo>
        </PermissionsChecker>
      </TableHeader>

      <Row>
        {recipients?.length > 0 ? (
          <Table<IContact>
            ref={tableRef}
            sortable
            filterTypes={filterTypes}
            globalFilter="text"
            columns={tableColumns}
            data={recipients}
            autoResetGlobalFilter={false}
          />
        ) : (
          <Paragraph>There are no contacts</Paragraph>
        )}
      </Row>

      {showDeleteRecipient && (
        <StaleButtonsPopup
          title="Delete recipient?"
          text="Please confirm that you want to delete this recipient."
          mainButtonText="Yes, delete"
          secondaryButtonText="Cancel"
          width="347px"
          onCancel={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onClose={() => {
            setShowDeleteRecipient(false);
            setRecipientIdForDelete('');
          }}
          onContinue={() => {
            onDeleteRecipient();
          }}
        />
      )}

      {/* TODO: strange condition for add and edit, revisit */}
      {isAddRecipient && initialCurrency && sellCurrency && (
        <AddContactForm
          onClose={() => {
            setRecipientForEditWrapper();
          }}
          withDraftWarning
          recipientForEdit={recipientForEdit}
          initialCurrency={initialCurrency}
          sellCurrency={sellCurrency}
          initialTransferType={
            recipientForEdit
              ? recipientForEdit.paymentType === 'swift'
                ? TRANSFER_TYPE.priority
                : TRANSFER_TYPE.regular
              : TRANSFER_TYPE.regular
          }
          onContinue={() => {
            setIsAddRecipient(false);
          }}
        />
      )}
    </>
  );
};

export default Contacts;
