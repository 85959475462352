import { FC, useMemo, useState } from 'react';
import { Col, Popup, StaleSelectMenu, Title } from 'components';
import { Controller, useForm } from 'react-hook-form';
import { useStoreActions, useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { Firebase } from 'services';
import { Notify } from 'utils';
import { ERROR_MESSAGES } from 'variables';
import Button from 'components/shared/Button/Button';
import { IEntityUser } from 'types/entities';

import Field from 'components/shared/Field/Field.styles';
import InputBase from 'components/shared/InputBase/InputBase';
import { IMenuItem } from 'components/shared/StaleSelectMenu/StaleSelectMenu';
import { IPermitRoleV2 } from 'types/permissions';

interface OwnProps {
  userForEdit: IEntityUser | null;
  onClose: () => void;
  roles: IPermitRoleV2[];
  packageSpecificRoles: IPermitRoleV2[];
}

interface IFormValues {
  email: string;
  firstName: string;
  lastName: string;
  roles: IMenuItem[];
}

const generateRolesMenuItem = (role: IPermitRoleV2) => ({
  label: role.name,
  value: role,
});

const EntityUserPopup: FC<OwnProps> = ({
  onClose,
  packageSpecificRoles,
  roles,
  userForEdit,
}) => {
  const theme = useTheme();
  const { entityId, userId } = useStoreState((state) => state.UserState);
  const [isSubmitting, setIsSubmitting] = useState(false);

  const {
    getUserPermissionsOnEntity,
    getEntityUsers,
    getEntityInvites,
  } = useStoreActions(({ UserState }) => UserState);

  const { featureFlagById } = useStoreState(
    ({ FeatureFlagsState }) => FeatureFlagsState
  );

  const isPermitFeatureFlagEnabled = featureFlagById('permit');
  const rolesMenuItems = roles.map(generateRolesMenuItem);

  const defaultRoles = useMemo(() => {
    if (userForEdit) {
      return roles
        .reduce<IPermitRoleV2[]>((accumulator, role) => {
          if (
            !!userForEdit.roles.find((userRole) => userRole.role === role.name)
          ) {
            return [...accumulator, role];
          }
          return accumulator;
        }, [])
        .map(generateRolesMenuItem);
    }
    return [];
  }, [roles, userForEdit]);

  const {
    control,
    handleSubmit,
    errors,
    formState: { isValid },
    watch,
  } = useForm<IFormValues>({
    mode: 'onChange',
    defaultValues: userForEdit
      ? {
          ...userForEdit,
          roles: defaultRoles,
        }
      : {},
  });

  const watchRoles = watch('roles');

  const onInviteSubmit = async (values: IFormValues) => {
    try {
      if (!entityId) {
        return;
      }
      setIsSubmitting(true);

      await Firebase.createInvitation({
        ...values,
        roles: isPermitFeatureFlagEnabled
          ? values.roles.map(({ value }) => value.name)
          : ['admin'],
        entityId,
      });

      Notify.success('Invitation sent');
      getEntityInvites({ entityId });
      onClose();
    } catch (error: any) {
      Notify.error(error.response?.data?.error || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const editUserRolesAndAttributesSubmit = async (values: IFormValues) => {
    try {
      if (!entityId || !userForEdit) {
        return;
      }
      setIsSubmitting(true);

      const savedPackageSpecificRoles = userForEdit
        ? packageSpecificRoles.reduce<IPermitRoleV2[]>((accumulator, role) => {
            if (
              !!userForEdit.roles.find(
                (userRole) => userRole.role === role.name
              )
            ) {
              return [...accumulator, role];
            }
            return accumulator;
          }, [])
        : [];

      const notPackageSpecificRoles = values.roles.map(({ value }) => value);

      const allRoles = [
        ...savedPackageSpecificRoles,
        ...notPackageSpecificRoles,
      ];

      await Firebase.editEntityUserRoles({
        userId: userForEdit.id,
        roles: isPermitFeatureFlagEnabled
          ? allRoles.map(({ name }) => name)
          : ['admin'],
        entityId,
      });

      // only call getUserPermissions if user edited is same as current user
      if (userForEdit.id === userId) {
        await getUserPermissionsOnEntity();
      }

      Notify.success('Roles edited successfully');
      getEntityUsers({ entityId });
      onClose();
    } catch (error: any) {
      Notify.error(error.response?.data?.error || error.message);
    } finally {
      setIsSubmitting(false);
    }
  };

  const requiredRules = userForEdit
    ? {}
    : { required: ERROR_MESSAGES.requiredField };

  return (
    <Popup
      HeaderContent={
        <Title variant="h3">{userForEdit ? 'Edit' : 'Invite'} colleague</Title>
      }
      FooterContent={
        <Button
          form="invite-member"
          disabled={
            userForEdit ? !watchRoles?.length : !isValid || isSubmitting
          }
          isLoading={isSubmitting}
        >
          {userForEdit ? 'Save' : 'Invite colleague'}
        </Button>
      }
      width="500px"
      height="auto"
      onClose={onClose}
    >
      <form
        id="invite-member"
        onSubmit={handleSubmit(
          userForEdit ? editUserRolesAndAttributesSubmit : onInviteSubmit
        )}
      >
        <Col gap={theme.spacing.m}>
          <Field flexDirection="column" fluid>
            <Controller
              name="firstName"
              control={control}
              rules={requiredRules}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="First Name"
                  value={value}
                  onChange={onChange}
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>

          <Field flexDirection="column" fluid>
            <Controller
              name="lastName"
              control={control}
              rules={requiredRules}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="Last Name"
                  value={value}
                  onChange={onChange}
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>
          <Field flexDirection="column" fluid>
            <Controller
              name="email"
              control={control}
              rules={requiredRules}
              disabled={!!userForEdit}
              render={({ onChange, value, name }) => (
                <InputBase
                  id={name}
                  label="Email"
                  value={value}
                  onChange={onChange}
                  // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
                  error={errors?.[name]?.message}
                  disabled={!!userForEdit}
                />
              )}
            />
          </Field>
          {isPermitFeatureFlagEnabled && (
            <>
              <Controller
                id="roles"
                name="roles"
                control={control}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                }}
                render={({ onChange, name, value }) => (
                  <StaleSelectMenu
                    id={name}
                    name={name}
                    label="Roles"
                    isMulti
                    data={rolesMenuItems}
                    value={value}
                    onChange={(item) => {
                      if (!Array.isArray(item)) {
                        onChange(null);
                      } else {
                        onChange(item);
                      }
                    }}
                    menuPosition="fixed"
                    withBackdrop={false}
                  />
                )}
              />
            </>
          )}
        </Col>
      </form>
    </Popup>
  );
};

export default EntityUserPopup;
