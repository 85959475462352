import { FC, useState, useEffect, useCallback } from 'react';
import { useTheme } from 'styled-components';
import {
  Col,
  Row,
  Title,
  Paragraph,
  Subtitle,
  Icon,
  InlineLoader,
} from 'components';
import { IRateContract } from 'types';
import Popup from 'components/shared/Popup/Popup';
import Button from '../Button/Button';
import {
  getMoveContractDueDateDetails,
  IExtendedContractDetails,
  moveContractDueDate,
} from 'services/firebase';
import { errorHandler } from 'utils/errors';
import { StyledForm } from '../Form/Form.styles';
import { Controller, useForm } from 'react-hook-form7';
import RadioButtons from '../RadioButtons/RadioButtons';
import InputDateUncontrolled from '../InputDateUncontrolled/InputDateUncontrolled';
import dayjs from 'dayjs';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import Field from '../Field/Field.styles';
import InputBase from '../InputBase/InputBase';
import { InnerDetailsWrapper } from 'components/shared/InnerDetails/InnerDetails.styles';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { useStoreActions, useStoreState } from 'state';
import ExchangeSummary from 'components/shared/ExchangeSummary/ExchangeSummary';
import ExchangeSummarySkeleton from 'components/shared/ExchangeSummarySkeleton/ExchangeSummarySkeleton';
import { Notify } from 'utils';
import { getFirstValidDay } from 'utils/dates';
import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';

export interface IInputs {
  dateToExtendTo: string;
  whichAmountToAdjust: 'sell' | 'buy';
  reason: string;
}

interface IOwnProps {
  selectedRateContract: IRateContract;
  onClose: () => void;
}

const PopupExtendDueDate: FC<IOwnProps> = ({
  selectedRateContract,
  onClose,
}) => {
  const theme = useTheme();
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const { nonTradingDays } = useStoreState((state) => state.ReferenceDataState);
  const [isLoading, setIsLoading] = useState(false);
  const [
    extendedContractDetails,
    setExtendedContractDetails,
  ] = useState<IExtendedContractDetails>();
  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  const sellCurrency = currencyByCode(selectedRateContract.sellCurrency);
  const buyCurrency = currencyByCode(selectedRateContract.buyCurrency);

  const firstValidDay = getFirstValidDay(
    dayjs(selectedRateContract.expiryDate).toDate(),
    7,
    nonTradingDays,
    true
  );

  const { control, watch, handleSubmit } = useForm<IInputs>({
    defaultValues: {
      dateToExtendTo: dayjs(firstValidDay).format(DATE_FORMAT) || '',
      whichAmountToAdjust: 'sell',
      reason: '',
    },
  });

  const { dateToExtendTo, whichAmountToAdjust } = watch();

  const onSubmit = async (values: IInputs) => {
    if (!extendedContractDetails) {
      return;
    }
    if (isAwaitingRegistration || isAwaitingComplianceReview) {
      setShowLimitedAccess(true);
      return;
    }

    try {
      setIsLoading(true);

      const { data } = await moveContractDueDate({
        contractId: selectedRateContract.id,
        dateToExtendTo: dayjs(dateToExtendTo, DATE_FORMAT).format(
          DB_DATE_FORMAT
        ),
        whichAmountToAdjust,
        remainingTotalAmount: extendedContractDetails.remainingTotalAmount,
        remainingBuyAmount: extendedContractDetails.remainingBuyAmount,
        rate: extendedContractDetails.rate,
        reason: values.reason,
      });

      if (data.success) {
        Notify.success('Contract due date extended successfully');
        onClose();
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  };

  const getExtendDueDateDetails = useCallback(async () => {
    try {
      setIsLoading(true);

      const { data } = await getMoveContractDueDateDetails({
        contractId: selectedRateContract.id,
        dateToExtendTo: dayjs(dateToExtendTo, DATE_FORMAT).format(
          DB_DATE_FORMAT
        ),
        whichAmountToAdjust,
      });

      if (data.success) {
        setExtendedContractDetails(data.data);
      } else {
        errorHandler(data);
      }
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoading(false);
    }
  }, [selectedRateContract, whichAmountToAdjust, dateToExtendTo]);

  useEffect(() => {
    getExtendDueDateDetails();
  }, [getExtendDueDateDetails, dateToExtendTo, whichAmountToAdjust]);

  return (
    <>
      <Popup
        HeaderContent={<Title variant="h3">Extend Prebooked FX due date</Title>}
        FooterContent={
          <Row
            gap={theme.spacing.m}
            justifyContent="flex-start"
            flexWrap="wrap"
          >
            <Button
              isLoading={isLoading}
              disabled={isLoading || !extendedContractDetails}
              form="extend-dueDate-form"
            >
              Confirm
            </Button>

            <Button
              isLoading={isLoading}
              disabled={isLoading}
              variant="secondary"
              onClick={onClose}
            >
              Go back
            </Button>
          </Row>
        }
        width="900px"
        height="800px"
        onClose={onClose}
      >
        <Col rowGap={theme.spacing.xl}>
          <InnerDetailsWrapper>
            <Col flex={1} alignSelf="stretch">
              <Subtitle variant="bold">Existing contract summary</Subtitle>

              {sellCurrency && buyCurrency && (
                <ExchangeSummary
                  sellCurrency={sellCurrency}
                  buyCurrency={buyCurrency}
                  buyAmountAsNumber={selectedRateContract.remainingBuyAmount}
                  sellAmountAsNumber={selectedRateContract.remainingTotalAmount}
                  rate={selectedRateContract.rate}
                  conversionFeeRate={selectedRateContract.conversionFeeRate}
                />
              )}

              <RowInfo>
                <Paragraph>Exchange anytime until</Paragraph>

                <Row gap={theme.spacing.m}>
                  <Paragraph>
                    {dayjs(selectedRateContract.expiryDate).format(DATE_FORMAT)}
                  </Paragraph>
                  <Icon icon="calendar-ico" />
                </Row>
              </RowInfo>
            </Col>

            <Col flex={1} alignSelf="stretch">
              <Subtitle variant="bold">New contract summary</Subtitle>

              {(!extendedContractDetails || isLoading) && (
                <ExchangeSummarySkeleton />
              )}

              {!isLoading &&
                extendedContractDetails &&
                sellCurrency &&
                buyCurrency && (
                  <ExchangeSummary
                    sellCurrency={sellCurrency}
                    buyCurrency={buyCurrency}
                    buyAmountAsNumber={
                      extendedContractDetails.remainingBuyAmount
                    }
                    sellAmountAsNumber={
                      extendedContractDetails.remainingTotalAmount
                    }
                    rate={extendedContractDetails.rate}
                    conversionFeeRate={selectedRateContract.conversionFeeRate}
                  />
                )}

              <RowInfo>
                <Paragraph>Exchange anytime until</Paragraph>
                {!extendedContractDetails || isLoading ? (
                  <InlineLoader />
                ) : (
                  <Row gap={theme.spacing.m}>
                    <Paragraph>
                      {dayjs(extendedContractDetails.dateToExtendTo).format(
                        DATE_FORMAT
                      )}
                    </Paragraph>
                    <Icon icon="calendar-ico" />
                  </Row>
                )}
              </RowInfo>
            </Col>
          </InnerDetailsWrapper>

          <StyledForm
            id="extend-dueDate-form"
            onSubmit={handleSubmit(onSubmit)}
          >
            <Title variant="h4">Extend to later date</Title>
            <Field mt fluid>
              <Row>
                <Controller
                  control={control}
                  name="dateToExtendTo"
                  render={({
                    field: { value, onChange, name },
                    fieldState: { error },
                  }) => (
                    <InputDateUncontrolled
                      id={name}
                      label="New expiry date"
                      value={value}
                      onChange={onChange}
                      error={error?.message}
                      calendarProps={{
                        minDate: dayjs(
                          selectedRateContract.expiryDate
                        ).toDate(),
                        maxDate: dayjs()
                          .add(12, 'month')
                          .subtract(1, 'day')
                          .toDate(),
                        minDetail: 'month',
                      }}
                      disabledDates={nonTradingDays}
                    />
                  )}
                />
              </Row>

              <Row gap={theme.spacing.m}>
                <Paragraph>Include costs in:</Paragraph>

                <Row>
                  <Controller
                    name="whichAmountToAdjust"
                    control={control}
                    render={({ field: { value, name, onChange } }) => (
                      <RadioButtons
                        flex={1}
                        data={[
                          {
                            id: 'whichAmountToAdjust-sell',
                            value: 'sell',
                            checked: value === 'sell',
                            name,
                            displayName: (
                              <Paragraph whiteSpace="nowrap">
                                {selectedRateContract.sellCurrency} amount
                              </Paragraph>
                            ),
                            onChange,
                          },
                          {
                            id: 'whichAmountToAdjust-buy',
                            value: 'buy',
                            checked: value === 'buy',
                            name,
                            displayName: (
                              <Paragraph whiteSpace="nowrap">
                                {selectedRateContract.buyCurrency} amount
                              </Paragraph>
                            ),
                            onChange,
                          },
                        ]}
                      />
                    )}
                  />
                </Row>
              </Row>
            </Field>

            <Field mt flexDirection="column" fluid>
              <Controller
                name="reason"
                control={control}
                rules={{
                  required: ERROR_MESSAGES.requiredField,
                }}
                render={({
                  fieldState: { error },
                  field: { value, name, onChange },
                }) => (
                  <InputBase
                    id={name}
                    label="Reason for extending FX contract"
                    value={value}
                    onChange={onChange}
                    error={error?.message}
                  />
                )}
              />
            </Field>
          </StyledForm>
        </Col>
      </Popup>
    </>
  );
};

export default PopupExtendDueDate;
