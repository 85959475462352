import { FC, Fragment } from 'react';
import { Redirect, Switch, useLocation, useRouteMatch } from 'react-router-dom';

import {
  BulkUploadContacts,
  ManualUpload,
  CompanyRegistration,
  Contacts,
  Dashboard,
  IntegrationLogs,
  IntegrationSettings,
  InvoiceInner,
  Invoices,
  InvoicesToCollect,
  MyClients,
  NewClientXero,
  Prebook,
  Settings,
  SignUpSoleTrader,
  SwitchClients,
  Transactions,
  Transfer,
  Transfers,
  FXTrades,
} from 'pages';
import { PrivateRoute } from 'hoc';
import { useStoreState } from 'state';
import { ENTITY_TYPE } from 'types';
import CurrencyExchange from 'pages/CurrencyExchange/CurrencyExchange';
import SimpleTransfer from 'pages/SimpleTransfer/SimpleTransfer';
import Reports from 'pages/Reports/Reports';
import RiskSettings from 'pages/RiskSettings/RiskSettings';
import PrebooksOverview from 'pages/PrebooksOverview/PrebooksOverview';
import {
  platformNamesToLogsPaths,
  platformNamesToSettingsPaths,
} from './IntegrationsRouteUtil';
import PaymentRun from 'pages/PaymentRun/PaymentRun';
import PaymentRuns from 'pages/PaymentRuns/PaymentRuns';
import SidebarPageTemplate from 'components/pageTemplates/SidebarPageTemplate/SidebarPageTemplate';
import Risks from 'pages/Risks/Risks';
import TotpSetup from 'pages/TotpSetup/TotpSetup';
import TransactionsDownload from 'pages/TransactionsDownload/TransactionsDownload';
import OpenBankingSettings from 'pages/OpenBankingSettings/OpenBankingSettings';
import AddBackupMethodPopup from 'components/shared/AddBackupMethodPopup/AddBackupMethodPopup';
import RisksGrouped from 'pages/RisksGrouped/RisksGrouped';
import LimitedAccessPopup from 'components/shared/LimitedAccessPopup/LimitedAccessPopup';
import Statements from 'pages/Statements/Statements';

const SIDEBAR_PAGE_TEMPLATE_ROUTES = [
  '/app/invoices',
  '/app/receivables',
  '/app/risks',
  '/app/risks/reduce-recommendations',
  '/app/risks/hedge-recommendations',
  '/app/risks/hedge-invoices',
  '/app/invoices/manual-upload',
  '/app/dashboard',
  '/app/prebook',
  '/app/transfer',
  '/app/fx-trades',
  '/app/transfers',
  '/app/settings',
  '/app/reports',
  '/app/statements',
  '/app/contacts',
  '/app/currency-exchange',
  '/app/simple-transfer',
  '/app/simple-transfer',
  '/app/logs/xero',
  '/app/logs/sage',
  '/app/logs/quickbookssandbox',
  '/app/logs/quickbooks',
  '/app/logs/dynamics',
  '/app/my-clients',
  '/app/group-view',
  '/app/transactions',
  '/app/payment-runs',
  '/app/invoices/prebookings',
  '/app/invoices/payment-run',
  '/app/invoice-details',
  '/app/bulk-contacts',
  '/app/payment-initiation',
  '/app/payment-initiation/processing',
];

const isSidebarTemplatePage = (pathname: string) =>
  SIDEBAR_PAGE_TEMPLATE_ROUTES.includes(pathname);

const ApplicationRoutes: FC = () => {
  const { path } = useRouteMatch();
  const { pathname } = useLocation();
  const { entityType, isAccountant, showReports } = useStoreState(
    (state) => state.UserState
  );

  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);

  const isCompany = entityType === ENTITY_TYPE.limitedCompany;
  const isSoleTrader = entityType === ENTITY_TYPE.soleTrader;

  const settingsPaths = Object.values(
    platformNamesToSettingsPaths
  ).map((settingPath) => settingPath.replace('/app', ''));

  const logsPaths = Object.values(platformNamesToLogsPaths).map((logPath) =>
    logPath.replace('/app', '')
  );

  const ApplicationRoutesWrapper = isSidebarTemplatePage(pathname)
    ? SidebarPageTemplate
    : Fragment;

  return (
    <ApplicationRoutesWrapper>
      <AddBackupMethodPopup />
      <LimitedAccessPopup />

      <Switch>
        <PrivateRoute exact path={`${path}/dashboard`} component={Dashboard} />
        <PrivateRoute exact path={`${path}/prebook`} component={Prebook} />
        <PrivateRoute exact path={`${path}/transfers`} component={Transfers} />
        <PrivateRoute exact path={`${path}/fx-trades`} component={FXTrades} />
        <PrivateRoute exact path={`${path}/transfer`} component={Transfer} />
        <PrivateRoute exact path={`${path}/settings`} component={Settings} />

        <PrivateRoute
          exact
          path={`${path}/risk-settings`}
          component={RiskSettings}
        />
        <PrivateRoute
          exact
          path={`${path}/open-banking-settings`}
          component={OpenBankingSettings}
        />
        <PrivateRoute
          exact
          path={`${path}/settings/totp`}
          component={TotpSetup}
        />
        {featureFlagById('backtester') && showReports && (
          <PrivateRoute exact path={`${path}/reports`} component={Reports} />
        )}
        <PrivateRoute exact path={`${path}/statements`} component={Statements} />
        <PrivateRoute exact path={`${path}/contacts`} component={Contacts} />
        <PrivateRoute
          exact
          path={`${path}/currency-exchange`}
          component={CurrencyExchange}
        />
        <PrivateRoute
          exact
          path={`${path}/simple-transfer`}
          component={SimpleTransfer}
        />
        {settingsPaths.map((settingPath) => (
          <PrivateRoute
            key={settingPath}
            exact
            path={`${path}${settingPath}`}
            component={IntegrationSettings}
          />
        ))}

        {logsPaths.map((logPath) => (
          <PrivateRoute
            key={logPath}
            exact
            path={`${path}${logPath}`}
            component={IntegrationLogs}
          />
        ))}

        <PrivateRoute
          exact
          path={`${path}/bulk-contacts`}
          component={BulkUploadContacts}
        />

        <PrivateRoute
          exact
          path={`${path}/new-client/xero`}
          component={NewClientXero}
        />

        {isAccountant && (
          <PrivateRoute
            exact
            path={`${path}/my-clients`}
            component={MyClients}
          />
        )}

        {!isAccountant && (
          <PrivateRoute
            exact
            path={`${path}/group-view`}
            component={SwitchClients}
          />
        )}

        <PrivateRoute exact path={`${path}/invoices`} component={Invoices} />

        {featureFlagById('showInvoicesToCollect') && (
          <PrivateRoute
            exact
            path={`${path}/receivables`}
            component={InvoicesToCollect}
          />
        )}
        <PrivateRoute
          exact
          path={`${path}/invoices/manual-upload`}
          component={ManualUpload}
        />
        <PrivateRoute
          exact
          path={`${path}/transactions`}
          component={Transactions}
        />
        <PrivateRoute
          exact
          path={`${path}/transactions/download`}
          component={TransactionsDownload}
        />
        <PrivateRoute
          exact
          path={`${path}/payment-runs`}
          component={PaymentRuns}
        />
        <PrivateRoute
          exact
          path={`${path}/invoices/prebookings`}
          component={PrebooksOverview}
        />
        <PrivateRoute
          exact
          path={`${path}/invoices/payment-run`}
          component={PaymentRun}
        />
        <PrivateRoute
          exact
          path={`${path}/invoice-details`}
          component={InvoiceInner}
        />

        {(isCompany || isSoleTrader) && (
          <PrivateRoute
            exact
            path={`${path}/company-registration`}
            component={isCompany ? CompanyRegistration : SignUpSoleTrader}
          />
        )}

        <PrivateRoute path={`${path}/risks`} component={Risks} />
        <PrivateRoute path={`${path}/risks-grouped`} component={RisksGrouped} />

        <Redirect to={`${path}/dashboard`} />
      </Switch>
    </ApplicationRoutesWrapper>
  );
};

export default ApplicationRoutes;
