import { FC, useMemo } from 'react';
import { useTheme } from 'styled-components';
import {
  Paragraph,
  Col,
  Title,
  Row,
  Icon,
  FundingBankTransfer,
} from 'components';
import RadioButtons from '../RadioButtons/RadioButtons';
import { IRadioButtonProps } from '../RadioButton/RadioButton';
import FundingOpenBankingPopup from './components/FundingBankTransfer/FundingOpenBankingPopup/FundingOpenBankingPopup';
import useOpenBankingPayments from 'hooks/useOpenBankingPayments';
import OpenBankingRedirectResponse from '../OpenBankingRedirectResponse/OpenBankingRedirectResponse';
import { useStoreState } from 'state';
import { IFundingAccountCredentialsActions } from './useFundingAccountCredentialsActions';
import { getTransactionStatusDetails } from 'utils/transactions';
import { ITransaction } from 'types';
import { isAssetIdBulPaymentId } from 'utils/bulkPayments';
import { useIsBulkPaymentApproved } from 'hooks/useIsBulkPaymentApproved';

interface IOwnProps {
  assetId?: string;
  sellCurrency: string;
  canBePaidWithBalance?: boolean;
  fundingAccountCredentialsActions: IFundingAccountCredentialsActions;
  status?: ITransaction['status'];
  isFundingInitiated?: boolean;
}

const FundingAccountCredentials: FC<IOwnProps> = ({
  assetId,
  sellCurrency,
  canBePaidWithBalance,
  fundingAccountCredentialsActions,
  status,
  isFundingInitiated = false,
}) => {
  const theme = useTheme();
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);
  const isOpenBankingEnabled =
    featureFlagById('openBanking') && sellCurrency === 'GBP';

  const {
    isConfirmed,
    setIsConfirmed,
    activeRadioButton,
    setActiveRadioButton,
    isSingleFundingMethod,
    setIsSingleFundingMethod,
    isFundingManually,
  } = fundingAccountCredentialsActions;

  const {
    institutions,
    selectedInstitution,
    paymentAuthResponse,
    isRedirected,
    isLoadingPaymentAuthUrl,
    isLoadingInstitutions,
    setIsRedirected,
    onPaymentAuthorisationInstitutionSelect,
    onUnselectInstitution,
  } = useOpenBankingPayments({
    fetchInstitutions: isOpenBankingEnabled,
    fetchOpenBankingSettings: false,
    assetId,
  });

  const { isApproved } = useIsBulkPaymentApproved(assetId);

  const isAssetPayabaleWithBalance = !!(
    canBePaidWithBalance &&
    ((isAssetIdBulPaymentId(assetId) && isApproved) ||
      !isAssetIdBulPaymentId(assetId))
  );

  const radioButtonsData: IRadioButtonProps[] = useMemo(() => {
    const dataToReturn: IRadioButtonProps[] = [
      {
        id: 'manualTransfer',
        value: 'manualTransfer',
        checked: activeRadioButton === 'manualTransfer',
        name: 'fund',
        displayName: <Paragraph>Transfer funds manually</Paragraph>,
        onChange: (event) => setActiveRadioButton(event.target.value),
      },
    ];

    if (isOpenBankingEnabled) {
      dataToReturn.unshift({
        id: 'openBanking',
        value: 'openBanking',
        checked: activeRadioButton === 'openBanking',
        name: 'fund',
        displayName: <Paragraph>Initiate payment via Open Banking</Paragraph>,
        onChange: (event) => setActiveRadioButton(event.target.value),
      });
    }

    if (isAssetPayabaleWithBalance) {
      dataToReturn.unshift({
        id: 'payWithBalance',
        value: 'payWithBalance',
        checked: activeRadioButton === 'payWithBalance',
        name: 'fund',
        displayName: <Paragraph>From my HedgeFlows balance</Paragraph>,
        onChange: (event) => setActiveRadioButton(event.target.value),
      });
    }

    setIsSingleFundingMethod(isRedirected || dataToReturn.length === 1);

    return dataToReturn;
  }, [
    activeRadioButton,
    isAssetPayabaleWithBalance,
    isOpenBankingEnabled,
    setActiveRadioButton,
    setIsSingleFundingMethod,
    isRedirected,
  ]);

  if (isFundingInitiated && !!paymentAuthResponse) {
    return (
      <>
        <Row gap={theme.spacing.xs} mt justifyContent="flex-start">
          <Icon icon="done-ico" />
          {!!status && (
            <Paragraph>{getTransactionStatusDetails(status)?.text}</Paragraph>
          )}
        </Row>
        <Row gap={theme.spacing.xs} mt justifyContent="flex-start">
          <Paragraph>
            Payment has been initiated by {selectedInstitution?.name} to account
            number {paymentAuthResponse.recipientAccountNumber} and sort code{' '}
            {paymentAuthResponse.recipientSortCode} with reference{' '}
            {paymentAuthResponse.recipientReference}.
          </Paragraph>
        </Row>
      </>
    );
  }

  if (isRedirected) {
    return (
      <OpenBankingRedirectResponse
        onRetry={() => {
          onUnselectInstitution();
          setIsConfirmed(false);
        }}
      />
    );
  }

  if (isSingleFundingMethod) {
    return <FundingBankTransfer sellCurrency={sellCurrency} />;
  }

  return (
    <Col>
      {!isFundingManually && (
        <>
          <Title variant="h4" mb>
            How do you want to fund it:
          </Title>
          <RadioButtons
            flex={1}
            flexDirection="column"
            alignItems="stretch"
            data={radioButtonsData}
          />
        </>
      )}

      {isConfirmed && (
        <>
          {activeRadioButton === 'openBanking' && (
            <FundingOpenBankingPopup
              institutions={institutions}
              selectedInstitution={selectedInstitution}
              paymentAuthResponse={paymentAuthResponse}
              isLoadingPaymentAuthUrl={isLoadingPaymentAuthUrl}
              isLoadingInstitutions={isLoadingInstitutions}
              setIsRedirected={setIsRedirected}
              onPaymentAuthorisationInstitutionSelect={
                onPaymentAuthorisationInstitutionSelect
              }
              onUnselectInstitution={onUnselectInstitution}
              onClose={() => setIsConfirmed(false)}
            />
          )}
          {activeRadioButton === 'manualTransfer' && (
            <FundingBankTransfer sellCurrency={sellCurrency} />
          )}
        </>
      )}
    </Col>
  );
};

export default FundingAccountCredentials;
