import { FC } from 'react';

import { StaleNotification, Paragraph } from 'components';
import useIsAwaitingReviewOrRegistration from '../../../hooks/useIsAwaitingReviewOrRegistration';

interface Props {
  bgColor?: string;
  style?: object;
  title?: string;
  icon?: string;
}

const StaleAwaitingReviewNotification: FC<Props> = ({
  title = 'Full access required to continue',
  icon = 'look-smile',
  bgColor = '',
  style,
}) => {
  const {
    isAwaitingReview,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();

  return isAwaitingReview || isAwaitingComplianceReview ? (
    <StaleNotification
      icon={icon}
      bgColor={bgColor}
      title={title}
      cross={false}
      style={style}
      titleColor="white"
    >
      <Paragraph color="white">
        {isAwaitingComplianceReview
          ? 'We are reviewing your company details and will notify you when you have full access. You currently have non-transactional access'
          : 'Your application is being reviewed. Once approved you will have full access to this feature. For now you can only play around with the screens.'}
      </Paragraph>
    </StaleNotification>
  ) : null;
};

export default StaleAwaitingReviewNotification;
