import { IResourceToMatchTo } from 'components/shared/PopupMatchInvoices/PopupMatchInvoices';
import { IWalletTransaction } from '../../types';

export const walletTransactionToResourceToMatchTo = (
  walletTransaction: IWalletTransaction
): IResourceToMatchTo => {
  // Some wallet transactions have payment fees which we want to exclude from the amount to match
  const paymentFeesAmount = walletTransaction.writableResources
    .filter((writableResource) => writableResource.type === 'paymentFee')
    .reduce((acc, fee) => acc + (fee.amount ?? 0), 0);

  return {
    type: walletTransaction.type === 'Transfer in' ? 'funding' : 'transfer',
    amount: Math.abs(walletTransaction.amount) - paymentFeesAmount,
    id: walletTransaction.sourceId,
    currency: walletTransaction.currency,
    senderName: walletTransaction.description,
    paymentDate: walletTransaction.settledOn,
    paymentRef: walletTransaction.reference,
  };
};
