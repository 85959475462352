import { FC } from 'react';
import { Row, Button } from 'components';
import { useTheme } from 'styled-components';
import { IFundingAccountCredentialsActions } from '../FundingAccountCredentials/useFundingAccountCredentialsActions';
import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';
import { useStoreActions } from 'state';

interface IOwnProps {
  onPayWithBalanceHandler?: () => void;
  onSecondaryButtonClick: () => void;
  isLoading?: boolean;
  fundingAccountCredentialsActions: IFundingAccountCredentialsActions;
  secondaryButtonText: string;
  isFundingInitiated?: boolean;
}

const FundingAccountCredentialsActions: FC<IOwnProps> = ({
  onPayWithBalanceHandler,
  onSecondaryButtonClick,
  isLoading,
  fundingAccountCredentialsActions,
  secondaryButtonText,
  isFundingInitiated = false,
}) => {
  const theme = useTheme();
  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );
  const {
    isSingleFundingMethod,
    activeRadioButton,
    isConfirmed,
    setIsConfirmed,
    isFundingManually,
  } = fundingAccountCredentialsActions;

  const onConfirm = () => {
    if (isAwaitingRegistration || isAwaitingComplianceReview) {
      return () => setShowLimitedAccess(true);
    }

    if (activeRadioButton === 'payWithBalance') {
      return onPayWithBalanceHandler;
    }

    return () => setIsConfirmed((prev) => !prev);
  };

  return (
    <Row gap={theme.spacing.m}>
      {!isFundingManually && !isFundingInitiated && !isSingleFundingMethod && (
        <Button
          variant="primary"
          disabled={!activeRadioButton || isLoading}
          onClick={onConfirm()}
        >
          {isConfirmed ? 'Go back' : 'Confirm'}
        </Button>
      )}

      <Button
        disabled={isLoading}
        variant={
          isFundingManually || isSingleFundingMethod || isFundingInitiated
            ? 'primary'
            : 'link'
        }
        onClick={onSecondaryButtonClick}
      >
        {secondaryButtonText}
      </Button>
    </Row>
  );
};

export default FundingAccountCredentialsActions;
