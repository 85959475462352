import { FC } from 'react';
import { Paragraph, Title, Popup } from 'components';
import { useHistory } from 'react-router-dom';
import { Col } from '../Col/Col';
import { useTheme } from 'styled-components';
import Button from '../Button/Button';
import useIsAwaitingReviewOrRegistration from 'hooks/useIsAwaitingReviewOrRegistration';
import { useStoreActions, useStoreState } from 'state';
import { getDashboardPageLink, getCompanyRegistrationPageLink } from 'utils';

const LimitedAccessPopup: FC = () => {
  const history = useHistory();
  const theme = useTheme();
  const {
    isAwaitingRegistration,
    isAwaitingComplianceReview,
  } = useIsAwaitingReviewOrRegistration();
  const isOnReview = isAwaitingRegistration || isAwaitingComplianceReview;
  const { showLimitedAccess } = useStoreState(({ UtilsState }) => UtilsState);
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  if (!showLimitedAccess) {
    return null;
  }

  const onClose = () => setShowLimitedAccess(false);

  return (
    <Popup
      HeaderContent={<Title variant="h4">Limited access</Title>}
      FooterContent={
        <Col flex={1} mt rowGap={theme.spacing.m}>
          {isOnReview && (
            <Button onClick={() => history.push(getDashboardPageLink())}>
              Back to dashboard
            </Button>
          )}

          {!isOnReview && (
            <>
              <Button
                variant="secondary"
                onClick={() => history.push(getCompanyRegistrationPageLink())}
              >
                Register company now
              </Button>
              <Button onClick={onClose}>Cancel</Button>
            </>
          )}
        </Col>
      }
      width="347px"
      height="auto"
      onClose={onClose}
    >
      <Paragraph>
        {isOnReview &&
          'Your company registration is being reviewed. We will notify you once your account has been enabled and you are able to perform this action.'}

        {!isOnReview &&
          'To complete this operation, you need to first finish the company registration.'}
      </Paragraph>
    </Popup>
  );
};

export default LimitedAccessPopup;
