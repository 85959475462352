import Icon from 'components/shared/Icon/Icon';
import StaleInfo from 'components/shared/StaleInfo/StaleInfo';
import { Paragraph } from 'components/shared/Typography/Typography';
import { FC } from 'react';
import { useTheme } from 'styled-components';
import { parseRateWithPrecision } from 'utils';

interface IOwnProps {
  trackingId?: boolean;
  contractId?: string;
  prebookRate?: number;
}

const StatusIconCell: FC<IOwnProps> = ({
  trackingId,
  contractId,
  prebookRate,
}) => {
  const theme = useTheme();

  return (
    <>
      {trackingId && <Icon icon="eye-table-ico" fill={theme.color.blue} />}

      {contractId && (
        <StaleInfo
          mode="hover"
          strategy="fixed"
          placement="top"
          portal
          trigger={<Icon icon="lock-ico" fill={theme.color.coral} />}
          disabled={!prebookRate}
        >
          <Paragraph color="white">
            Prebooked rate: {parseRateWithPrecision(prebookRate)}
          </Paragraph>
        </StaleInfo>
      )}
    </>
  );
};

export default StatusIconCell;
