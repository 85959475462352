import { openQuery } from 'utils';
import firestore from './firestore';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { IResponse } from 'types';
import { IWalletTransaction } from 'pages/Statements/components/StatementsTable/types';
import { IPaginatedResponse } from './firebase';

interface SubscribeToTransactionsParams {
  entityId: string;
  limit?: number;
  callback: (transactions: any) => void;
}

export const subscribeToTransactions = ({
  entityId,
  limit = 250,
  callback,
}: SubscribeToTransactionsParams) => {
  return firestore
    .collection('transactions')
    .where('_owner', '==', entityId)
    .orderBy('_created', 'desc')
    .limit(limit)
    .onSnapshot((transactions) => callback(openQuery(transactions)));
};

export const getTransactionsCsvByCurrency = async (
  currencies: string,
  dateFrom: string,
  dateTo: string
) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<Array<{ currency: string; csv: string }>>
  >(
    `/transactions/csv?currencies=${currencies}&dateFrom=${dateFrom}&dateTo=${dateTo}`
  );

export const getTransactionCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(
    `/transactions/currencies`
  );

export const getWalletTransactions = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<
    IResponse<IPaginatedResponse<IWalletTransaction>>
  >(`/wallets/transactions${query}`);

export const getWalletCurrencies = async () =>
  AxiosPrivateFirebaseInstance.get<IResponse<string[]>>(`/wallets/currencies`);

export const writeWalletTransactionToSourceSystem = async (
  walletTransactionId: string
) =>
  AxiosPrivateFirebaseInstance.post(
    `/integration/wallet-transactions/${walletTransactionId}/write-to-source-system`
  );

export const writeBankTransferToSourceSystem = async (payload: {
  hedgeFlowsId: string;
  isReconciled: boolean;
  sourceSystemFromAccountId: string;
  sourceSystemToAccountId: string;
  amount: number;
  currency: string;
  reference: string;
  /** ISO 8601 format */
  postingDate: string;
}) => AxiosPrivateFirebaseInstance.post(`/integration/bank-transfers`, payload);
