import { FC } from 'react';
import { useTheme } from 'styled-components';
import { Title, Col, Row, Button, Popup } from 'components';
import { IWalletTransaction } from 'pages/Statements/components/StatementsTable/types';
import WriteBankTransferForm from './components/WriteBankTransferForm/WriteBankTransferForm';

interface IOwnProps {
  record: IWalletTransaction;
  onClose: () => void;
  onSuccess: () => void;
}

const WriteBankTransferPopup: FC<IOwnProps> = ({
  record,
  onClose,
  onSuccess,
}) => {
  const theme = useTheme();

  return (
    <Popup
      HeaderContent={
        <Row flex={1} alignItems="flex-start">
          <Title variant="h3">Write Bank Transfer</Title>
        </Row>
      }
      FooterContent={
        <Row justifyContent="flex-start" columnGap={theme.spacing.m}>
          <Button
            variant="primary"
            form="write-bank-transfer-form"
            type="submit"
          >
            Record in Xero
          </Button>
          <Button variant="secondary" onClick={onClose} type="button">
            Cancel
          </Button>
        </Row>
      }
      width="900px"
      height="800px"
      onClose={onClose}
    >
      <Col rowGap={theme.spacing.xl} flex={1}>
        <WriteBankTransferForm
          record={record}
          onClose={onClose}
          onSuccess={onSuccess}
        />
      </Col>
    </Popup>
  );
};

export default WriteBankTransferPopup;
