import { useStoreState } from 'state';

const useIsAwaitingReviewOrRegistration = () => {
  const { isEntityEnabled, isEntityOnboarded, userEntity } = useStoreState(
    (state) => state.UserState
  );

  return {
    isAwaitingReview:
      !isEntityEnabled && userEntity?.status === 'onboardingStep4Completed',
    isAwaitingComplianceReview:
      !isEntityEnabled &&
      isEntityOnboarded &&
      userEntity.subStatusAfterOnboarding === 'onHold',
    isAwaitingRegistration:
      !isEntityEnabled &&
      userEntity?.status !== 'onboardingStep4Completed' &&
      userEntity.subStatusAfterOnboarding !== 'onHold',
  };
};

export default useIsAwaitingReviewOrRegistration;
