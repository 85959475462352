import {
  RATE_TYPE,
  SWIFT_TYPE,
  ISystemFieldsWithOwner,
  IBulkPayment,
} from 'types';

export enum TRANSFER_TYPE {
  regular = 'regular',
  priority = 'priority',
}

interface ITransferInputInvoice {
  id: string;
  amount: number;
}

export interface ITransferInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number; // 1.2345
  rateType: RATE_TYPE;
  conversionFeeRate?: number;
  payAmount: number;

  purpose?: string;
  purposeCode?: string;
  transferType: TRANSFER_TYPE;

  recipientId: string;
  recipientAccountCountry?: string;

  contractId?: string;
  priorityTransferFeeAmount?: number;
  reference?: string;
  paymentRunId?: string;
  scheduledPaymentDate?: string;

  invoiceIds?: string[];
  invoices?: ITransferInputInvoice[];
  swiftType?: SWIFT_TYPE;
  challenge?: string;
}

export interface ITransfer extends ITransferInput, ISystemFieldsWithOwner {
  status: TRANSFER_STATUS;
  payByDate: string;
  recipient: any;
  bulkPaymentId?: string;
  bulkId?: string;
  payByTimeGMT?: string;
  externalRefs?: {
    ccId: string;
    ccConversionId: string;
    ccRecipientId: string;
  };
  mt103?: string;
}

export enum TRANSFER_STATUS {
  /** Instructions have been received successfully and follow-up processes initiated */
  initiated = 'initiated',
  /** Once the conversion has been created the transfer waits for funding */
  awaitingPayment = 'awaiting_payment',
  /** If the client doesn't fund the payment by PayByTimeDate then it will be timed_out and cancelled */
  timedOut = 'timed_out',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  /** Client has provided funds for the transfer */
  funded = 'funded',
  /** All conditions ahve been met */
  approved = 'approved',
  /** Payment instructions have been submitted and awaiting further update */
  processing = 'processing',
  /** Payment has been sent to the recipient bank */
  paymentSent = 'payment_sent',
  /** Payment has been received by the recipient (SWIFT only) */
  paymentConfirmed = 'payment_confirmed',
  /** Payment was cancelled by the user, admins or payment processing institution  */
  paymentCancelled = 'payment_cancelled',
  /** Payment failed for any reason during processing by payment processing institution */
  paymentFailed = 'payment_failed',
}

export enum PAYMENT_TYPE {
  local = 'local',
  iban = 'iban',
  swift = 'swift',
}

export const isTransfer = (
  transfer: ITransfer | IBulkPayment
): transfer is ITransfer => (transfer as ITransfer).recipient !== undefined;
