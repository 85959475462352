import { FC } from 'react';
import {
  StaleInputConvert,
  StaleGetRateFailed,
  Subtitle,
  PermissionsChecker,
} from 'components';
import PrebookedRateBlock from 'components/shared/PrebookedRateBlock/PrebookedRateBlock';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import { useHistory } from 'react-router-dom';
import RateSelect from 'components/shared/RateSelect/RateSelect';
import { RATE_TYPE } from 'types';
import { useTheme } from 'styled-components';
import { UseExchangeDetailsReturnValues } from 'pages/CurrencyExchange/hooks/useExchangeDetails';
import Button from 'components/shared/Button/Button';
import { isRateContractRemainingAmountValid } from 'utils';
import { ERROR_MESSAGES } from 'variables';
import { useStoreState, useStoreActions } from 'state';

interface OwnProps extends UseExchangeDetailsReturnValues {
  onContinue: () => void;
}

const ExchangeDetails: FC<OwnProps> = ({
  onContinue,
  rateContracts,
  selectedRateContract,
  rate,
  rateType,
  isLoading,
  buyAmountAsNumber,
  sellAmountAsNumber,
  buyCurrency,
  sellCurrency,
  sellCurrencies,
  setRateType,
  setSelectedRateContract,
  updateBuyAmount,
  updateSellAmount,
  updateSellCurrency,
  setFixedSide,
  updateBuyCurrency,
  isMarketRate,
  buyCurrencies,
  rateToUse,
  canContinue,
  prebookAndMarketRateDifference,
  isError,
  getRate,
  invoiceId,
}) => {
  const theme = useTheme();
  const history = useHistory();
  const { isEntityEnabled } = useStoreState((state) => state.UserState);
  const { setShowLimitedAccess } = useStoreActions(
    ({ UtilsState }) => UtilsState
  );

  const isRateContractAmountValid = isRateContractRemainingAmountValid({
    selectedRateContract,
    buyAmountAsNumber,
  });

  const onContinueHandler = () => {
    if (isEntityEnabled && rateToUse) {
      onContinue();
    } else {
      setShowLimitedAccess(true);
    }
  };

  return (
    <>
      <FlowStepWrapper>
        <FlowStepContent>
          <FlowContentLeft>
            <RateSelect
              rateContracts={rateContracts}
              selectedRateContract={selectedRateContract}
              rate={rate}
              rateType={rateType}
              isRateLoading={isLoading}
              sellCurrency={sellCurrency}
              buyCurrency={buyCurrency}
              setSelectedRateContract={setSelectedRateContract}
              setRateType={setRateType}
              invoiceId={invoiceId}
            />

            <Subtitle variant="bold" mt mb mbValue={theme.spacing.xs}>
              Convert from
            </Subtitle>

            <StaleInputConvert
              id="sellAmount"
              value={sellAmountAsNumber}
              onChange={updateSellAmount}
              currencies={sellCurrencies}
              selectedValue={sellCurrency}
              onSelect={(value) => {
                updateSellCurrency(value);
                setRateType(RATE_TYPE.market);
              }}
              onFocus={() => setFixedSide('sell')}
            />

            <Subtitle variant="bold" mt mb mbValue={theme.spacing.xs}>
              To
            </Subtitle>

            <StaleInputConvert
              id="buyAmount"
              value={buyAmountAsNumber}
              onChange={updateBuyAmount}
              currencies={buyCurrencies}
              selectedValue={buyCurrency}
              onSelect={updateBuyCurrency}
              onFocus={() => setFixedSide('buy')}
              error={
                !isRateContractAmountValid
                  ? ERROR_MESSAGES.abovePrebookedAmount
                  : ''
              }
            />
          </FlowContentLeft>

          <FlowContentRight>
            <PrebookedRateBlock
              isMarketRate={isMarketRate}
              buyCurrency={buyCurrency}
              sellCurrency={sellCurrency}
              sellAmountAsNumber={sellAmountAsNumber}
              buyAmountAsNumber={buyAmountAsNumber}
              rate={rate}
              selectedRateContract={selectedRateContract}
              prebookAndMarketRateDifference={prebookAndMarketRateDifference}
              setRateType={setRateType}
              setSelectedRateContract={setSelectedRateContract}
            />
          </FlowContentRight>
        </FlowStepContent>
        <FlowStepFooter>
          <PermissionsChecker action="update" resource="balances">
            <Button onClick={onContinueHandler} disabled={!canContinue}>
              Exchange now
            </Button>
          </PermissionsChecker>
        </FlowStepFooter>
      </FlowStepWrapper>

      {isError && (
        <StaleGetRateFailed
          onCancel={() => history.push('/app/dashboard')}
          onRetry={() => getRate(sellCurrency?.code, buyCurrency?.code)}
          onClose={() => history.push('/app/dashboard')}
        />
      )}
    </>
  );
};

export default ExchangeDetails;
