import { FC, useEffect } from 'react';
import { useTheme } from 'styled-components';
import { useStoreActions, useStoreState } from 'state';
import useReduceRisks from './hooks/useReduceRisks';
import useUrlValues from 'hooks/useUrlValues';
import { Col, Row, Title, Subtitle, InlineLoader } from 'components';
import ReduceRiskCard from './components/ReduceRiskCard/ReduceRiskCard';
import { getRiskCalculationsText } from './utils';
import { INetWorthValues, TCashflowAtRiskNew } from 'types';

interface IOwnProps {
  cashflowsRisksData: {
    perPeriod: TCashflowAtRiskNew[];
    netWorthValues: INetWorthValues;
  };
}

const ReduceRisks: FC<IOwnProps> = ({ cashflowsRisksData }) => {
  const theme = useTheme();
  const { currency } = useUrlValues('currency');
  const { entityCurrencyCode, entityId, userEntity } = useStoreState(
    (state) => state.UserState
  );
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const entityCurrency = currencyByCode(entityCurrencyCode);

  const {
    cashflowRisksByCurrency,
    cards,
    isLoading: isLoadingCashflowRisksByCurrency,
  } = useReduceRisks(cashflowsRisksData);
  const { isRefreshingCashflowsRisksData } = useStoreState(
    ({ ReferenceDataState }) => ReferenceDataState
  );
  const { refreshCashflowsRisksData } = useStoreActions(
    ({ ReferenceDataState }) => ReferenceDataState
  );

  const isLoading =
    isLoadingCashflowRisksByCurrency || isRefreshingCashflowsRisksData;

  useEffect(() => {
    if (entityId && entityCurrencyCode) {
      refreshCashflowsRisksData({
        entityId,
        sellCurrency: entityCurrencyCode,
      });
    }
  }, [entityCurrencyCode, entityId, refreshCashflowsRisksData]);

  return (
    <>
      <Col mb gap={theme.spacing.xs}>
        <Title variant="h4">Consider reducing risks</Title>
        <Subtitle color="greyDark">
          Your exposure to swings in {currency} exchange rates can result in
          potential currency losses of up to
          {isLoading && <InlineLoader ml />}
          {!isLoading && (
            <b>
              {getRiskCalculationsText({
                cashflowRisksByCurrency,
                riskTolerance: userEntity?.riskTolerance,
                symbol: entityCurrency?.symbol,
              })}
              your risk tolerance.
            </b>
          )}
        </Subtitle>
      </Col>

      <Title variant="h5">Select your hedging method:</Title>

      <Row flexWrap="wrap" justifyContent="flex-start" gap={theme.spacing.m} mt>
        {cards.map((card) => (
          <ReduceRiskCard key={card.id} {...card} />
        ))}
      </Row>
    </>
  );
};

export default ReduceRisks;
