import {
  Col,
  Paragraph,
  Row,
  StaleSwitch,
  StaleSelectMenu,
  Loader,
} from 'components';
import Field from 'components/shared/Field/Field.styles';
import { FC, useEffect } from 'react';
import { Controller, UseFormReturn } from 'react-hook-form7';
import { useStoreActions, useStoreState } from 'state';
import { useTheme } from 'styled-components';
import { ERROR_MESSAGES } from 'variables';
import { TInputs } from '../../ApprovalSettings';

interface IOwnProps {
  form: UseFormReturn<TInputs, any>;
}

export const PaymentRunApprovalSettings: FC<IOwnProps> = ({ form }) => {
  const theme = useTheme();
  const { control, watch } = form;
  const { entityId, entityUsers } = useStoreState(({ UserState }) => UserState);
  const { getEntityUsers } = useStoreActions(({ UserState }) => UserState);

  const entityUsersForSelect = entityUsers.map((entityUser) => ({
    value: entityUser.id,
    label: entityUser.name,
  }));

  const watchPaymentRunsTwoApprovers = watch('paymentRunsTwoApprovers');
  const watchPaymentRunsApprovalFlowEnabled = watch(
    'paymentRunsApprovalsFlowEnabled'
  );

  // Ensure we have an up to date list of entity users
  useEffect(() => {
    if (entityId) {
      getEntityUsers({ entityId });
    }
  }, [entityId, getEntityUsers]);

  if (!entityUsers) {
    return <Loader size="large" />;
  }

  return (
    <Col>
      <Col mb>
        <Row mb gap={theme.spacing.l}>
          <Field fluid flexDirection="column">
            <Paragraph mb>These users can approve:</Paragraph>
            <Controller
              name="paymentRunsApprover1List"
              control={control}
              rules={{
                validate: (value: TInputs['paymentRunsApprover1List']) => {
                  if (
                    watchPaymentRunsApprovalFlowEnabled &&
                    (!value || !value.length)
                  ) {
                    return ERROR_MESSAGES.chooseApprovers;
                  }
                  return true;
                },
              }}
              render={({ field: { value, onChange } }) => (
                <StaleSelectMenu
                  id="paymentRunsApprover1List"
                  label="Approvers"
                  name="paymentRunsApprover1List"
                  isMulti={true}
                  data={entityUsersForSelect}
                  value={value}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    if (!value.length) {
                      onChange(null);
                    } else {
                      onChange(value);
                    }
                  }}
                />
              )}
            />
          </Field>

          <Field fluid flexDirection="column">
            <Row>
              <Paragraph mr>These users can give 2nd approval:</Paragraph>
              <Controller
                name="paymentRunsTwoApprovers"
                control={control}
                render={({ field: { value, onChange } }) => (
                  <StaleSwitch
                    id="paymentRunsTwoApprovers"
                    isOn={!!value}
                    handleToggle={onChange}
                  />
                )}
              />
            </Row>
            <Controller
              name="paymentRunsApprover2List"
              control={control}
              rules={{
                validate: (value: TInputs['paymentRunsApprover2List']) => {
                  if (
                    watchPaymentRunsApprovalFlowEnabled &&
                    watchPaymentRunsTwoApprovers &&
                    (!value || !value.length)
                  ) {
                    return ERROR_MESSAGES.chooseApprovers;
                  }
                  return true;
                },
              }}
              render={({ field: { value, onChange } }) => (
                <StaleSelectMenu
                  id="paymentRunsApprover2List"
                  label="Approvers"
                  name="paymentRunsApprover2List"
                  isMulti={true}
                  data={entityUsersForSelect}
                  value={value}
                  style={{ width: '100%' }}
                  onChange={(value) => {
                    if (!value.length) {
                      onChange(null);
                    } else {
                      onChange(value);
                    }
                  }}
                  disabled={!watchPaymentRunsTwoApprovers}
                />
              )}
            />
          </Field>
        </Row>
      </Col>
    </Col>
  );
};

export default PaymentRunApprovalSettings;
