import { FC } from 'react';
import dayjs from 'dayjs';
import { Col, Subtitle, Paragraph } from 'components';
import { parseIntoCurrencyString, roundToPrecision } from 'utils';
import { useStoreState } from 'state';
import { IConversion } from 'types';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { DATE_FORMAT } from 'variables';
import { InnerDetailsWrapper } from '../../../InnerDetails/InnerDetails.styles';
import { getTransactionStatusDetails } from 'utils/transactions';
import ExchangeRate from 'components/shared/ExchangeRate/ExchangeRate';

interface IExchangeDetails {
  selectedConversion: IConversion;
}

const ExchangeDetails: FC<IExchangeDetails> = ({ selectedConversion }) => {
  const { currencyByCode } = useStoreState((state) => state.CurrenciesState);
  const statusInfo = getTransactionStatusDetails(selectedConversion.status);

  return (
    <InnerDetailsWrapper>
      <Col flex={1} alignSelf="stretch">
        <Subtitle mb variant="bold">
          Exchange details
        </Subtitle>

        <RowInfo alignItems="flex-start">
          <Paragraph>Amount sold</Paragraph>

          <Paragraph textAlign="right" variant="bold">
            {`${parseIntoCurrencyString(
              selectedConversion.sellAmount,
              currencyByCode(selectedConversion.sellCurrency)?.precision
            )} ${selectedConversion.sellCurrency}`}
          </Paragraph>
        </RowInfo>

        <RowInfo alignItems="flex-start">
          <Paragraph>Amount bought</Paragraph>

          <Paragraph textAlign="right" variant="bold">
            {`${parseIntoCurrencyString(
              selectedConversion.buyAmount,
              currencyByCode(selectedConversion.buyCurrency)?.precision
            )} ${selectedConversion.buyCurrency}`}
          </Paragraph>
        </RowInfo>

        <ExchangeRate
          rate={selectedConversion.rate}
          sellCurrencyCode={selectedConversion.sellCurrency}
          buyCurrencyCode={selectedConversion.buyCurrency}
        />

        <RowInfo>
          <Paragraph>Our fees</Paragraph>

          <Paragraph textAlign="right">
            {parseIntoCurrencyString(
              selectedConversion.sellAmount *
                selectedConversion.conversionFeeRate
            )}{' '}
            {selectedConversion.sellCurrency} (
            {roundToPrecision(selectedConversion.conversionFeeRate * 100)}%)
          </Paragraph>
        </RowInfo>

        {statusInfo && (
          <RowInfo alignItems="flex-start">
            <Paragraph>Status</Paragraph>

            <Paragraph style={{ color: statusInfo.color }} textAlign="right">
              {statusInfo?.text}
            </Paragraph>
          </RowInfo>
        )}

        <RowInfo alignItems="flex-start">
          <Paragraph>Instructed on</Paragraph>

          <Paragraph textAlign="right">
            {/* TODO: Fix this and get  _created as string from BE */}
            {
              // @ts-ignore
              dayjs(selectedConversion._created.seconds * 1000).format(
                DATE_FORMAT
              )
            }
          </Paragraph>
        </RowInfo>
      </Col>
    </InnerDetailsWrapper>
  );
};

export default ExchangeDetails;
