import { useMemo, useEffect, useState, useCallback } from 'react';
import { useHistory } from 'react-router';

import { useStoreActions, useStoreState } from 'state';
import { Firebase } from 'services';
import { IClient } from 'types/entities';
import { Notify } from 'utils';
import { getDashboardPageLink } from 'utils/links';
import { IEntityLoadingState } from 'types';
import { chunk } from 'lodash';
import {
  getAccountantEntitySummaries,
  subscribeToEntitiesLoadingState,
} from 'services/firebase';
import { errorHandler } from 'utils/errors';
import usePrevious from './usePrevious';

const getEntitySummary = async (entityId: string) => {
  const { data } = await Firebase.getUserEntitySummary({
    entityId,
  });

  return data.data;
};

const useSwitchClients = () => {
  const history = useHistory();
  const { userId } = useStoreState(({ UserState }) => UserState);
  const { getUser } = useStoreActions(({ UserState }) => UserState);
  const [isLoadingClients, setIsLoadingClients] = useState(true);
  const [clients, setClients] = useState<Array<IClient>>([]);
  const [entitiesLoadingState, setEntitiesLoadingState] = useState<
    Array<IEntityLoadingState>
  >([]);
  const prevEntitiesLoadingState = usePrevious(entitiesLoadingState);

  useEffect(() => {
    const unsubscribeFunctions: Record<number, () => void> = {};

    if (clients.length) {
      const entityIds = clients.map((client) => client.entityId);
      const entityIdsChunks = chunk(entityIds, 10);

      entityIdsChunks.forEach((entityIdsChunk, index) => {
        unsubscribeFunctions[index] = subscribeToEntitiesLoadingState({
          entityIds: entityIdsChunk,
          callback: (newLoadingStates) => {
            setEntitiesLoadingState((prevState) => {
              const prevStateWithoutNewLoadingStates = prevState.filter(
                (prevStateItem) =>
                  !newLoadingStates.some(
                    (loadingState) => loadingState?.id === prevStateItem?.id
                  )
              );

              return [...prevStateWithoutNewLoadingStates, ...newLoadingStates];
            });
          },
        });
      });
    }

    return () => {
      Object.values(unsubscribeFunctions).forEach((unsubscribe) =>
        unsubscribe()
      );
    };
  }, [clients]);

  useEffect(() => {
    if (
      entitiesLoadingState.length &&
      prevEntitiesLoadingState.length &&
      entitiesLoadingState.length === prevEntitiesLoadingState.length
    ) {
      const clientIdsToUpdate = entitiesLoadingState.filter(
        (entityLoadingState) => {
          const prevEntityLoadingState = prevEntitiesLoadingState.find(
            (prevEntityLoadingStateItem) =>
              prevEntityLoadingStateItem.id === entityLoadingState.id
          );
          const isNewStateChanged =
            prevEntityLoadingState?.cashflowAtRisk !==
              entityLoadingState.cashflowAtRisk ||
            prevEntityLoadingState?.reports !== entityLoadingState.reports;

          return (
            isNewStateChanged &&
            !entityLoadingState.cashflowAtRisk &&
            !entityLoadingState.reports
          );
        }
      );

      if (clientIdsToUpdate.length) {
        const refreshClientsData = async () => {
          await Promise.allSettled(
            clientIdsToUpdate.map(async ({ id }) => {
              try {
                const updatedClientData = await getEntitySummary(id);

                if (updatedClientData) {
                  setClients((prevState) =>
                    prevState.map((client) =>
                      client.entityId === id ? updatedClientData : client
                    )
                  );
                }
              } catch (error: any) {
                errorHandler(error);
              }
            })
          );
        };

        refreshClientsData();
      }
    }
  }, [entitiesLoadingState, prevEntitiesLoadingState]);

  const getUserClients = useCallback(async () => {
    try {
      setIsLoadingClients(true);

      const { data } = await getAccountantEntitySummaries();

      if (data.success && data.data) {
        setClients(data.data);
      }
    } catch (error: any) {
      Notify.error(error.message);
    } finally {
      setIsLoadingClients(false);
    }
  }, []);

  useEffect(() => {
    getUserClients();
  }, [getUserClients]);

  const clientsWithLoadingState = useMemo(
    () =>
      clients.map((client) => {
        const { id: _, ...clientLoadingState } =
          entitiesLoadingState.find((item) => item.id === client.entityId) ||
          ({} as IEntityLoadingState);

        const defaultLoadingState: Omit<IEntityLoadingState, 'id'> = {
          cashflowAtRisk: true,
          reports: true,
        };

        return {
          ...client,
          entityLoadingState: clientLoadingState || defaultLoadingState,
        };
      }),
    [clients, entitiesLoadingState]
  );

  const onSwitchAccount = useMemo(
    () => async (id: string) => {
      const switchUserAccount = async () => {
        try {
          if (userId) {
            await Firebase.updateUser({
              user: {
                entityId: id,
              },
            });

            await getUser({ id: userId });
            history.push(getDashboardPageLink());
            // force reload to ensure clean redux state
            window.location.reload();
          }
        } catch (error: any) {
          errorHandler(error);
        }
      };

      switchUserAccount();
    },
    [getUser, userId, history]
  );

  return {
    clients: clientsWithLoadingState,
    isLoadingClients,
    onSwitchAccount,
    getUserClients,
  };
};

export default useSwitchClients;
