import { IRateContract, ISystemFieldsWithOwner, RATE_TYPE } from 'types';

export enum CONVERSION_STATUS {
  awaitingPayment = 'awaiting_payment',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
}

export interface IConversionInput {
  sellAmount: number;
  sellCurrency: string;
  buyAmount: number;
  buyCurrency: string;
  rate: number;
  rateType: RATE_TYPE;
  contractId?: IRateContract['id'];
  conversionFeeRate: number;
  invoiceId?: string;
}

export interface IConversion extends IConversionInput, ISystemFieldsWithOwner {
  conversionDate?: string;
  status: CONVERSION_STATUS;
}

export interface IConversionDates {
  firstConversionCutoffDatetime: string;
  firstConversionDate: string;
  nextDayConversionDate: string;
}
