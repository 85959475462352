import { IFileWithId } from 'components/shared/StaleInputFile/StaleInputFile';
import { action, Action, computed, Computed, thunk, Thunk } from 'easy-peasy';

import { Firebase } from 'services';
import {
  refreshCashflowFromCsv,
  uploadFileWithCustomCashflows,
} from 'services/cashflows';
import {
  hedgeCashflows,
  HedgeCashflowsParams,
  hedgeCashflowsByIds,
  HedgeCashflowsByIdsParams,
} from 'services/firebase';
import {
  refreshCashflowRisks,
  RefreshCashflowsRisksParams,
  subscribeToCashflowsRisks,
  SubscribeToCashflowsRisksParams,
} from 'services/firebase/analysis';
import { StateModel } from 'state';
import {
  ICountry,
  IFees,
  ICurrency,
  IFundingAccount,
  IPotentialSavings,
  IPotentialSavingsWithDate,
  IRatings,
  TBusinessNatureUnion,
  SWIFT_TYPE,
  Nullable,
  IRateAnalyses,
} from 'types';
import { CashflowsRisksData } from 'types/analyses';
import { Notify } from 'utils';
import { errorHandler } from 'utils/errors';

export interface ReferenceDataStateModel {
  isRefreshingCashflowsRisksData: boolean;
  showSpinnerInRecalculateButton: boolean;
  nonTradingDays: string[];
  countries: ICountry[];
  fees: IFees[];
  fundingAccounts: IFundingAccount[];
  cashflowsRisks: CashflowsRisksData | null;
  isCashflowsRisksLoaded: boolean;
  systemVariables: {
    calculatedAmountDiffThreshold: number;
    standardTransferCharge: number;
    depositFeeDiffThreshold: number;
    prebookDefaultAmount: number;
    prebookMinimumAmount: number;
    priorityTransferFeeAmount: number;
    rateRelativeDiffThreshold: number;
    shortIdAlphabet: number;
    shortIdLength: number;
    tradingEnabled: boolean;
    transferDefaultAmount: number;
    transferMinimumAmount: number;
    sessionTimeout: number;
    prebookDaysBuffer: number;
    defaultBuyCurrency: string;
    defaultSellCurrency: string;
    invoicePrebookCostAmountThreshold: number;
  } | null;
  naturesOfBusiness: TBusinessNatureUnion[];
  rateAnalyses: IRateAnalyses | null;
  potentialSavings: IPotentialSavings | null;
  potentialSavingsWithDate: IPotentialSavingsWithDate | null;
  ratings: IRatings | null;
  setState: Action<ReferenceDataStateModel, [string, any]>;
  /** This is used to show spinner when one of custom cashflow values is changed  */
  setShowSpinnerInRecalculateButton: Action<ReferenceDataStateModel, boolean>;
  getNonTradingDays: Thunk<
    ReferenceDataStateModel,
    Firebase.GetNonTradingDaysParams
  >;
  getNaturesOfBusiness: Thunk<ReferenceDataStateModel>;
  getSystemVariables: Thunk<ReferenceDataStateModel>;
  getCountries: Thunk<ReferenceDataStateModel>;
  getSwiftFees: Thunk<ReferenceDataStateModel>;
  getRateAnalyses: Thunk<
    ReferenceDataStateModel,
    Firebase.GetRateAnalysisParams
  >;
  getPotentialSavings: Thunk<
    ReferenceDataStateModel,
    Firebase.GetPotentialSavingsParams
  >;
  getPotentialSavingsWithDate: Thunk<
    ReferenceDataStateModel,
    Firebase.GetPotentialSavingsWithDateParams
  >;
  getRatings: Thunk<ReferenceDataStateModel, Firebase.GetRatingsParams>;
  countryByCode: Computed<
    ReferenceDataStateModel,
    (countryCode: Nullable<ICountry['alpha2']>) => ICountry | null
  >;
  feesByCurrencyCode: Computed<
    ReferenceDataStateModel,
    (currencyCode: Nullable<ICurrency['code']>) => IFees | null
  >;
  getSwiftShared: Computed<
    ReferenceDataStateModel,
    (currencyCode: Nullable<ICurrency['code']>) => IFees['swiftShared'] | null
  >;
  getSwiftOurs: Computed<
    ReferenceDataStateModel,
    (
      currencyCode: Nullable<ICurrency['code']>,
      country: Nullable<ICountry['alpha2']>
    ) => number | null
  >;
  getPriorityFee: Computed<
    ReferenceDataStateModel,
    (
      type: SWIFT_TYPE,
      currencyCode: Nullable<ICurrency['code']>,
      country: Nullable<ICountry['alpha2']>
    ) => number | null
  >;
  getFundingAccounts: Thunk<ReferenceDataStateModel, string>;
  fundingAccountByCurrency: Computed<
    ReferenceDataStateModel,
    (ccCode: ICurrency['code']) => IFundingAccount | undefined
  >;
  subscribeToCashflowsRisks: Thunk<
    ReferenceDataStateModel,
    Omit<SubscribeToCashflowsRisksParams, 'callback'>,
    null,
    object,
    (() => void) | undefined
  >;
  refreshCashflowsRisksData: Thunk<
    ReferenceDataStateModel,
    RefreshCashflowsRisksParams & { files?: IFileWithId[] },
    any,
    StateModel
  >;
  prebookAll: Thunk<ReferenceDataStateModel, HedgeCashflowsParams>;
  prebookAllByIds: Thunk<ReferenceDataStateModel, HedgeCashflowsByIdsParams>;
  xeroImportPeriodData: any[];
  getRiskContribution: Thunk<ReferenceDataStateModel>;
  riskContribution: Record<string, Record<string, number>>;
}

export const ReferenceDataState: ReferenceDataStateModel = {
  isRefreshingCashflowsRisksData: false,
  showSpinnerInRecalculateButton: false,
  xeroImportPeriodData: [],
  nonTradingDays: [],
  countries: [],
  fees: [],
  naturesOfBusiness: [],
  fundingAccounts: [],
  riskContribution: {},
  cashflowsRisks: null,
  isCashflowsRisksLoaded: false,
  systemVariables: null,
  rateAnalyses: null,
  potentialSavings: null,
  potentialSavingsWithDate: null,
  ratings: null,
  setState: action((state, payload) => {
    const [prop, to] = payload;
    // @ts-expect-error TS(7053) FIXME: Element implicitly has an 'any' type because expre... Remove this comment to see the full error message
    state[prop] = to;
  }),
  setShowSpinnerInRecalculateButton: action((state, payload) => {
    state.showSpinnerInRecalculateButton = payload;
  }),
  getNonTradingDays: thunk(async (actions, params) => {
    const data = await Firebase.getNonTradingDays(params);

    if (data) {
      actions.setState(['nonTradingDays', data.data]);
    }
  }),
  getNaturesOfBusiness: thunk(async (actions) => {
    const data = await Firebase.getNatureOfBusiness();

    if (data) {
      actions.setState(['naturesOfBusiness', data.values]);
    }
  }),
  getSystemVariables: thunk(async (actions) => {
    const data = await Firebase.getSystemVariables();

    if (data) {
      actions.setState(['systemVariables', data]);
    }
  }),
  getCountries: thunk(async (actions) => {
    const data = await Firebase.getCountries();

    if (data) {
      actions.setState(['countries', data]);
    }
  }),
  getSwiftFees: thunk(async (actions) => {
    try {
      const data = await Firebase.getSwiftFees();

      if (data) {
        actions.setState(['fees', data]);
      }
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  countryByCode: computed(
    [(state) => state.countries],
    (countries) => (countryCode) =>
      countries.find(
        (item) => item.alpha2.toLowerCase() === countryCode?.toLowerCase()
      ) || null
  ),
  feesByCurrencyCode: computed(
    [(state) => state.fees],
    (fees) => (currencyCode) =>
      fees.find((item) => item.id === currencyCode) || null
  ),
  getSwiftShared: computed((state) => (currencyCode) =>
    state.feesByCurrencyCode(currencyCode)?.swiftShared || null
  ),
  getSwiftOurs: computed((state) => (currencyCode, country) => {
    if (currencyCode && country) {
      return (
        state.feesByCurrencyCode(currencyCode)?.swiftOurs?.[
          country.toUpperCase()
        ] || null
      );
    }

    return null;
  }),
  getPriorityFee: computed((state) => (type, currencyCode, country) =>
    type === SWIFT_TYPE.ours
      ? state.getSwiftOurs(currencyCode, country)
      : state.getSwiftShared(currencyCode)
  ),
  getRateAnalyses: thunk(async (actions, payload) => {
    const data = await Firebase.getRateAnalysis(payload);

    if (data) {
      actions.setState(['rateAnalyses', data]);
    }
  }),

  getPotentialSavings: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavings(payload);

    if (data) {
      actions.setState(['potentialSavings', data]);
    }
  }),
  getPotentialSavingsWithDate: thunk(async (actions, payload) => {
    const data = await Firebase.getPotentialSavingsWithDate(payload);

    if (data) {
      actions.setState(['potentialSavingsWithDate', data]);
    }
  }),
  getRatings: thunk(async (actions, payload) => {
    const data = await Firebase.getRatings(payload);

    if (data) {
      actions.setState(['ratings', data]);
    }
  }),
  getFundingAccounts: thunk(async (actions, payload) => {
    const data = await Firebase.getFundingAccounts(payload);

    if (data) {
      actions.setState(['fundingAccounts', data.data.data]);
    }
  }),
  fundingAccountByCurrency: computed(
    [(state) => state.fundingAccounts],
    (fundingAccounts) => (ccCode) =>
      fundingAccounts.find((fundingAccount) =>
        fundingAccount.currencies.includes(ccCode)
      ) ||
      fundingAccounts.find((fundingAccount) =>
        fundingAccount.currencies.includes('*')
      )
  ),
  subscribeToCashflowsRisks: thunk(({ setState }, payload) => {
    const subscriber = subscribeToCashflowsRisks({
      ...payload,
      callback: (cashflowRisks) => {
        setState(['cashflowsRisks', cashflowRisks]);
        setState(['isCashflowsRisksLoaded', true]);
      },
    });

    return subscriber;
  }),
  refreshCashflowsRisksData: thunk(
    async ({ setState }, payload, { getState, getStoreState }) => {
      try {
        const { entityId, sellCurrency, files } = payload;
        const { isRefreshingCashflowsRisksData } = getState();
        const { entityCashflowsSettings } = getStoreState().EntityState;

        if (isRefreshingCashflowsRisksData) {
          return;
        }

        setState(['isRefreshingCashflowsRisksData', true]);

        /** @todo: quick fix, consider moving to the BE at some point */
        if (
          entityCashflowsSettings?.fileShareSystem === 'microsoftFileUpload' &&
          files?.length
        ) {
          const formData = new FormData();
          formData.append('file', files[0].file);
          await uploadFileWithCustomCashflows(formData);
        }

        /** for microsoft there is no sense of refreshing, because data is always the same until new file upload */
        if (
          entityCashflowsSettings?.fileShareUrl &&
          entityCashflowsSettings?.fileShareSystem === 'google'
        ) {
          /** @todo: quick fix, consider moving to the BE at some point */
          await refreshCashflowFromCsv();
        }

        await refreshCashflowRisks({
          entityId,
          sellCurrency,
        });
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setState(['isRefreshingCashflowsRisksData', false]);
      }
    }
  ),
  prebookAll: thunk(async (_, payload) => {
    try {
      await hedgeCashflows(payload);
    } catch (error: any) {
      errorHandler(error);
    }
  }),
  prebookAllByIds: thunk(async (_, payload) => {
    try {
      const { data } = await hedgeCashflowsByIds(payload);

      if (data.success) {
        const failureCount = data?.data?.failures?.length || 0;
        const successCount = data?.data?.successes?.length || 0;
        const totalCount = failureCount + successCount;

        if (failureCount > 0) {
          Notify.info(
            `We were able to prebook successfully for ${successCount} invoices out of ${totalCount}.${' '}
            ${failureCount} invoices unsuccessful. You can try again. If the issue persists, please contact support.`
          );
        } else {
          Notify.success(
            'A prebooking has been applied to all selected invoices'
          );
        }
      } else {
        Notify.error(`The action was unsuccessful. Error ${data?.message}`);
      }
    } catch (error: any) {
      Notify.error(error?.message);
    }
  }),

  getRiskContribution: thunk(async (actions) => {
    const data = await Firebase.getRiskContribution();

    if (data) {
      actions.setState(['riskContribution', data]);
    }
  }),
};
