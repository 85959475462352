import { Col } from '../../../Col/Col';
import { Paragraph } from '../../../Typography/Typography';
import { FC, useState } from 'react';
import { getInvoiceDetailsFromAttachments } from 'services/firebase/invoices';
import { useTheme } from 'styled-components';
import { IInvoiceDetailsFromAttachment } from 'types';
import { errorHandler } from 'utils/errors';
import InvoiceAttachmentsViewer from './components/InvoiceAttachmentsViewer/InvoiceAttachmentsViewer';
import { IImportableValuesFromOcr } from '../../types';
import { notUndefinedTypeGuard } from 'utils';
import { Row } from 'components/shared/Row/Row';
import Pill from 'components/shared/Pill/Pill';
import Loader from 'components/shared/Loader/Loader';
import ImportConfirmationPopup from '../ImportConfirmationPopup/ImportConfirmationPopup';
import { IInvoiceAttachmentsGrouped } from './types';

interface IOwnProps {
  disableCurrency: boolean;
  disableRecipientName: boolean;
  isLoadingInvoices: boolean;
  groupedInvoiceAttachments: IInvoiceAttachmentsGrouped[];
  onApplyImportedValues: (values: IImportableValuesFromOcr) => void;
  existingRecipientName?: string;
  existingCurrency?: string;
}

const InvoicesWithAttachments: FC<IOwnProps> = ({
  disableCurrency,
  disableRecipientName,
  isLoadingInvoices,
  groupedInvoiceAttachments,
  onApplyImportedValues,
  existingRecipientName,
  existingCurrency,
}) => {
  const theme = useTheme();

  const [
    selectedGroupOfInvoiceAttachments,
    setSelectedGroupOfInvoiceAttachments,
  ] = useState<IInvoiceAttachmentsGrouped | null>(null);
  const [
    invoiceDetailsFromAttachment,
    setInvoiceDetailsFromAttachment,
  ] = useState<IInvoiceDetailsFromAttachment | null>(null);
  const [
    isLoadingDataFromInvoiceAttachment,
    setIsLoadingDataFromInvoiceAttachment,
  ] = useState(false);
  const [isShowConfirmationPopup, setIsShowConfirmationPopup] = useState(false);

  const importDetailsFromSelectedInvoiceAttachmentData = async (
    data: IInvoiceDetailsFromAttachment
  ) => {
    const { recipientName, currency, ...restOfData } = data;

    if (!disableCurrency && currency) {
      const dataToFill = {
        currency,
      };

      onApplyImportedValues(dataToFill);

      // Wait for currency to populate, so we can show correct fields before populating them
      // Not ideal, but a quick win to avoid form refactoring
      await new Promise((resolve) => setTimeout(resolve, 2000));
    }

    const dataToFill: IImportableValuesFromOcr = {
      ...restOfData,
    };

    if (!disableRecipientName && recipientName) {
      dataToFill.recipientName = recipientName;
    }

    const dataToFillWithoutUndefinedValues = Object.fromEntries(
      Object.entries(dataToFill).filter(([, value]) =>
        notUndefinedTypeGuard(value)
      )
    );

    if (Object.keys(dataToFillWithoutUndefinedValues).length) {
      onApplyImportedValues(dataToFillWithoutUndefinedValues);
    }
  };

  const importDetailsFromSelectedInvoiceAttachment = async (
    attachmentId: string
  ) => {
    try {
      if (!selectedGroupOfInvoiceAttachments) {
        return;
      }

      setIsLoadingDataFromInvoiceAttachment(true);

      const { data } = await getInvoiceDetailsFromAttachments({
        invoiceId: selectedGroupOfInvoiceAttachments.invoiceId,
        attachmentId,
      });

      if (!data.data) {
        return;
      }

      const { recipientName, currency } = data.data;

      if (
        (recipientName &&
          existingRecipientName &&
          existingRecipientName !== recipientName &&
          !disableRecipientName) ||
        (currency &&
          existingCurrency &&
          existingCurrency !== currency &&
          !disableCurrency)
      ) {
        setInvoiceDetailsFromAttachment(data.data);
        setIsShowConfirmationPopup(true);
        setIsLoadingDataFromInvoiceAttachment(false);
        return;
      }

      await importDetailsFromSelectedInvoiceAttachmentData(data.data);
    } catch (error) {
      errorHandler(error);
    } finally {
      setIsLoadingDataFromInvoiceAttachment(false);
    }
  };

  return (
    <Col flex={1} rowGap={theme.spacing.s}>
      {isLoadingInvoices && <Loader size="small" />}

      {!isLoadingInvoices && groupedInvoiceAttachments.length === 0 ? (
        <Paragraph>No invoices with attachments</Paragraph>
      ) : null}

      {!isLoadingInvoices && groupedInvoiceAttachments.length > 0 ? (
        <Paragraph>Select invoice attachment to scan:</Paragraph>
      ) : null}

      <Row gap={theme.spacing.s} justifyContent="flex-start" flexWrap="wrap">
        {groupedInvoiceAttachments.map((invoiceAttachments) => (
          <button
            onClick={() => {
              setSelectedGroupOfInvoiceAttachments(invoiceAttachments);
            }}
            type="button"
            key={invoiceAttachments.invoiceId}
          >
            <Pill
              variant={
                selectedGroupOfInvoiceAttachments?.invoiceId ===
                invoiceAttachments.invoiceId
                  ? 'emerald'
                  : 'blue'
              }
              text={invoiceAttachments.invoiceNumber || 'No reference'}
            />
          </button>
        ))}
      </Row>

      {!!selectedGroupOfInvoiceAttachments && (
        <InvoiceAttachmentsViewer
          invoiceId={selectedGroupOfInvoiceAttachments.invoiceId}
          isFillingFromInvoice={isLoadingDataFromInvoiceAttachment}
          fillFromSelectedInvoiceAttachment={
            importDetailsFromSelectedInvoiceAttachment
          }
        />
      )}

      {isShowConfirmationPopup && (
        <ImportConfirmationPopup
          onClose={() => setIsShowConfirmationPopup(false)}
          onSubmit={(values) =>
            importDetailsFromSelectedInvoiceAttachmentData({
              ...invoiceDetailsFromAttachment,
              ...values,
            })
          }
          existingRecipientName={existingRecipientName ?? ''}
          existingCurrency={existingCurrency ?? ''}
          newRecipientName={invoiceDetailsFromAttachment?.recipientName ?? ''}
          newCurrency={invoiceDetailsFromAttachment?.currency ?? ''}
          disableRecipientName={disableRecipientName}
          disableCurrency={disableCurrency}
        />
      )}
    </Col>
  );
};

export default InvoicesWithAttachments;
