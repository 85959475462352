import { useEffect, useRef } from 'react';

const useExecuteOnce = ({
  callbackFunction,
  isReadyToRun = true,
}: {
  callbackFunction?: () => void;
  isReadyToRun?: boolean;
}) => {
  const wasExecuted = useRef(false);

  useEffect(() => {
    if (isReadyToRun && !wasExecuted.current && callbackFunction) {
      callbackFunction();
      wasExecuted.current = true;
    }
  }, [callbackFunction, isReadyToRun]);
};

export default useExecuteOnce;
