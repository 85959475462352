import { ICurrency } from 'types';
import dayjs from 'dayjs';
import quarterOfYear from 'dayjs/plugin/quarterOfYear';
import advancedFormat from 'dayjs/plugin/advancedFormat';
import { ICashflowsSummary } from 'types/cashflows';

dayjs.extend(quarterOfYear);
dayjs.extend(advancedFormat);

// TODO: take this info from the backend when we have it
export const getRiskLevelRatingText = (riskLevel?: number) => {
  if (typeof riskLevel === 'undefined') {
    return '';
  }

  if (riskLevel <= 1) {
    return 'VERY LOW';
  } else if (riskLevel === 2) {
    return 'LOW';
  } else if (riskLevel === 3) {
    return 'AVERAGE';
  } else if (riskLevel === 4) {
    return 'HIGH';
  } else {
    return 'VERY HIGH';
  }
};

interface IDetectCurrenciesFromCashflowsParams {
  currencies: ICurrency[];
  cashflowSummaries: ICashflowsSummary[];
  entityCurrencyCode?: string | null;
}

export const detectCurrenciesFromCashflows = ({
  currencies,
  cashflowSummaries,
  entityCurrencyCode,
}: IDetectCurrenciesFromCashflowsParams) =>
  cashflowSummaries
    .map(({ id }) => id)
    .sort()
    .filter(
      (currencyCode) =>
        currencyCode !== entityCurrencyCode &&
        currencies.some(
          (currency) => currency.code === currencyCode && currency.enabled
        )
    );
