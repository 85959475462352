import { FC, useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { LogoImage, LogoLink, SidebarWrapper } from './Sidebar.styles';
import { useStoreState } from 'state';
import {
  getContactsPageLink,
  getInvoicesPageLink,
  getInvoicesToCollectPageLink,
  getLink,
  getMyClientsLink,
  getReportsPageLink,
  getRisksPageLink,
} from 'utils/links';
import { Col } from 'components/shared';
import { useCreateTransfersPermissionsCheck } from 'hooks/useSpecificPermissionsCheck';
import SidebarMenuItem from './components/SidebarMenuItem';

interface OwnProps {
  hideIconLabels?: boolean;
}

interface ISidebarLinkValues {
  to: string;
  title: string;
  active: boolean;
  isIconsOnly: boolean;
  icon: string;
  text: string;
}

const Sidebar: FC<OwnProps> = ({ hideIconLabels }) => {
  const { pathname } = useLocation();
  const {
    showReports,
    isAccountant,
    isFxManagementPackageEnabled,
  } = useStoreState((state) => state.UserState);
  const { featureFlagById } = useStoreState((state) => state.FeatureFlagsState);

  const hasCreateTransfersPermission = useCreateTransfersPermissionsCheck();
  const isIconsOnly = !hideIconLabels;

  const links = useMemo(() => {
    let linksToReturn: ISidebarLinkValues[] = [];

    if (isAccountant) {
      linksToReturn.push({
        to: getMyClientsLink(),
        title: isIconsOnly ? 'My Clients' : '',
        active: pathname.includes('my-clients'),
        isIconsOnly: isIconsOnly,
        icon: 'briefcase-ico',
        text: 'My Clients',
      });
    }

    linksToReturn.push({
      to: '/app/dashboard',
      title: isIconsOnly ? 'Dashboard' : '',
      active: pathname.includes('dashboard'),
      isIconsOnly: isIconsOnly,
      icon: 'dashboard-ico',
      text: 'Dashboard',
    });

    linksToReturn.push({
      to: getInvoicesPageLink({
        currency: 'all',
      }),
      title: isIconsOnly ? 'Bills to pay' : '',
      active: pathname === '/app/invoices',
      isIconsOnly: isIconsOnly,
      icon: 'invoices',
      text: 'Bills to pay',
    });

    if (featureFlagById('showInvoicesToCollect')) {
      linksToReturn.push({
        to: getInvoicesToCollectPageLink({
          currency: 'all',
        }),
        title: isIconsOnly ? 'Receivables' : '',
        active: pathname.includes('receivables'),
        isIconsOnly: isIconsOnly,
        icon: 'receivables',
        text: 'Receivables',
      });
    }

    if (!isAccountant) {
      if (hasCreateTransfersPermission) {
        linksToReturn.push({
          to: getLink('/app/transfers'),
          title: isIconsOnly ? 'Transfers' : '',
          active: pathname.includes('transfer'),
          isIconsOnly: isIconsOnly,
          icon: 'transfer-ico',
          text: 'Transfers',
        });

        linksToReturn.push({
          to: getLink('/app/fx-trades'),
          title: isIconsOnly ? 'FX Trades' : '',
          active: pathname.includes('fx-trades'),
          isIconsOnly: isIconsOnly,
          icon: 'prebook-filled',
          text: 'FX Trades',
        });
      }
    }

    if (isFxManagementPackageEnabled) {
      linksToReturn.push({
        to: getRisksPageLink(),
        title: isIconsOnly ? 'Cashflows' : '',
        active: pathname.includes('risks'),
        isIconsOnly: isIconsOnly,
        icon: 'risks-ico',
        text: 'Cashflows',
      });
    }

    if (featureFlagById('backtester') && showReports) {
      linksToReturn.push({
        to: getReportsPageLink(),
        title: isIconsOnly ? 'Reports' : '',
        active: pathname.includes('reports'),
        isIconsOnly: isIconsOnly,
        icon: 'reports',
        text: 'Reports',
      });
    }

    linksToReturn.push({
      to: getLink('/app/statements'),
      title: isIconsOnly ? 'Statements' : '',
      active: pathname.includes('statements'),
      isIconsOnly: isIconsOnly,
      icon: 'reports',
      text: 'Statements',
    });

    if (!isAccountant) {
      linksToReturn.push({
        to: getContactsPageLink(),
        title: isIconsOnly ? 'Contacts' : '',
        active: pathname.includes('contacts'),
        isIconsOnly: isIconsOnly,
        icon: 'users-group',
        text: 'Contacts',
      });
    }

    return linksToReturn;
  }, [
    isAccountant,
    featureFlagById,
    showReports,
    isIconsOnly,
    pathname,
    hasCreateTransfersPermission,
    isFxManagementPackageEnabled,
  ]);

  return (
    <SidebarWrapper isIconsOnly={isIconsOnly}>
      <LogoLink
        href="https://www.hedgeflows.com"
        rel="noreferrer"
        target="_blank"
        isIconsOnly={isIconsOnly}
      >
        <LogoImage
          src="/assets/imgs/logo.png"
          alt="Logo"
          isIconsOnly={isIconsOnly}
        />
        HedgeFlows
      </LogoLink>

      <Col>
        {links.map((link) => (
          <SidebarMenuItem key={link.to} {...link} />
        ))}
      </Col>
    </SidebarWrapper>
  );
};

export default Sidebar;
