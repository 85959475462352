import { FC, useEffect, useState } from 'react';
import { RouteComponentProps, useRouteMatch } from 'react-router-dom';

import { getGoToStepQuery } from 'utils';
import { Col, StaleStepsHorizontal } from 'components';
import { useQuery } from 'hooks';
import Amounts from './components/Amounts/Amounts';
import Review from './components/Review/Review';
import { IContact, ICurrency, IInvoice } from 'types';
import Recipient from './components/Recipient/Recipient';
import StaleCancelTransfer from 'components/shared/StaleCancelTransfer/StaleCancelTransfer';
import { getLink } from 'utils/links';
import { withErrorBoundary } from 'hoc';
import TransferSummary from 'components/shared/TransferSummary/TransferSummary';

export interface FirstStepValues {
  sellAmount: number;
  sellCurrency: ICurrency;
  scheduledPaymentDate?: string;
}

export interface SecondStepValues {
  recipient: IContact;
  fee: number;
  invoice?: IInvoice;
}

const SimpleTransfer: FC<RouteComponentProps> = ({ history }) => {
  const [
    firstStepValues,
    setFirstStepValues,
  ] = useState<FirstStepValues | null>(null);
  const [
    secondStepValues,
    setSecondStepValues,
  ] = useState<SecondStepValues | null>(null);
  const [showCancelModal, setShowCancelModal] = useState(false);
  const [isFinishedTransfer, setIsFinishedTransfer] = useState(false);

  const { url } = useRouteMatch();
  const URLQuery = useQuery();
  const activeStep = URLQuery.get('step') ?? '0';

  const goToStep = (stepNumber: number, transferId?: string) => {
    if (transferId) {
      URLQuery.set('transferId', transferId);
    }

    history.push(getGoToStepQuery(url, URLQuery, stepNumber));
  };

  useEffect(() => {
    if (isFinishedTransfer && activeStep !== '3') {
      setIsFinishedTransfer(false);
      history.replace(getLink('/app/simple-transfer'));
    }
  }, [activeStep, history, isFinishedTransfer, url]);

  const renderContent = () => {
    switch (parseInt(activeStep)) {
      case 1:
        return (
          firstStepValues && (
            <Recipient
              onContinue={(values: SecondStepValues) => {
                setSecondStepValues(values);
                goToStep(2);
              }}
              firstStepValues={firstStepValues}
            />
          )
        );
      case 2:
        return (
          firstStepValues &&
          secondStepValues && (
            <Review
              onContinue={(transferId: string) => {
                goToStep(3, transferId);
              }}
              savedValues={{ ...firstStepValues, ...secondStepValues }}
            />
          )
        );
      case 3:
        return (
          <TransferSummary
            transferType="sameCurrency"
            setIsFinishedTransfer={setIsFinishedTransfer}
          />
        );
      default:
        return (
          <Amounts
            onContinue={(values: FirstStepValues) => {
              setFirstStepValues(values);
              goToStep(1);
            }}
          />
        );
    }
  };

  const renderSteps = (step: string) => {
    return (
      <StaleStepsHorizontal
        activeStep={parseInt(step) + 1}
        title="Same currency transfer"
        onClose={() =>
          parseInt(activeStep) === 3
            ? history.replace('/app/dashboard')
            : setShowCancelModal(true)
        }
        data={[
          {
            title: 'Amounts',
            onClick: () => goToStep(0),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Recipient',
            onClick: () => goToStep(1),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Review',
            onClick: () => goToStep(2),
            disabled: parseInt(step) === 3,
          },
          {
            title: 'Transfer',
            disabled: parseInt(step) === 3,
          },
        ]}
      >
        {renderContent()}
      </StaleStepsHorizontal>
    );
  };

  return (
    <>
      <Col flex={1} justifyContent="center" alignItems="center">
        {renderSteps(activeStep)}
      </Col>

      {showCancelModal && (
        <StaleCancelTransfer
          onCancel={() => {
            history.replace('/app/dashboard');
          }}
          onContinue={() => setShowCancelModal(false)}
          onClose={() => setShowCancelModal(false)}
        />
      )}
    </>
  );
};

export default withErrorBoundary(SimpleTransfer);
