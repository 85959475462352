import { useState, useEffect, Dispatch, SetStateAction } from 'react';
import { Masks, roundToPrecision } from 'utils';
import { Nullable } from 'types';
import { UseCurrencyRateReturnValues } from './useCurrencyRate';

interface GetFixedSideParams {
  predefinedSellAmount?: Nullable<string>;
  predefinedBuyAmount?: Nullable<string>;
}

const getInitialFixedSide = ({
  predefinedSellAmount,
  predefinedBuyAmount,
}: GetFixedSideParams) => {
  if (
    (predefinedSellAmount && !predefinedBuyAmount) ||
    (!predefinedSellAmount && !predefinedBuyAmount)
  ) {
    return 'sell';
  }

  return 'buy';
};

interface GetInitialAmountParams {
  predefinedAmount?: Nullable<string>;
  defaultAmount?: number;
}

const getInitialAmount = ({
  predefinedAmount,
  defaultAmount,
}: GetInitialAmountParams) => {
  if (predefinedAmount && predefinedAmount !== '') {
    return Number(predefinedAmount);
  }

  if (defaultAmount) {
    return defaultAmount;
  }

  return 0;
};

interface UseAmountsParams {
  predefinedBuyAmount?: Nullable<string>;
  predefinedSellAmount?: Nullable<string>;
  defaultAmount?: number;
  rate: UseCurrencyRateReturnValues['rate'];
}

export interface UseAmountsReturnValues {
  sellAmount: string;
  sellAmountAsNumber: number;
  buyAmount: string;
  buyAmountAsNumber: number;
  updateSellAmount: Dispatch<SetStateAction<number>>;
  updateBuyAmount: Dispatch<SetStateAction<number>>;
  setFixedSide: Dispatch<SetStateAction<'sell' | 'buy'>>;
}

const useAmounts = ({
  predefinedBuyAmount,
  predefinedSellAmount,
  defaultAmount,
  rate,
}: UseAmountsParams): UseAmountsReturnValues => {
  const [fixedSide, setFixedSide] = useState<'sell' | 'buy'>(
    getInitialFixedSide({ predefinedSellAmount, predefinedBuyAmount })
  );
  const [sellAmountAsNumber, setSellAmountAsNumber] = useState(
    getInitialAmount({
      predefinedAmount: predefinedSellAmount,
      // pass default amount here because this is fixed side by default
      defaultAmount,
    })
  );
  const [buyAmountAsNumber, setBuyAmountAsNumber] = useState(
    getInitialAmount({
      predefinedAmount: predefinedBuyAmount,
    })
  );

  useEffect(() => {
    if (rate) {
      if (fixedSide === 'sell') {
        setBuyAmountAsNumber(
          roundToPrecision(sellAmountAsNumber ? sellAmountAsNumber * rate : 0)
        );
      }

      if (fixedSide === 'buy') {
        setSellAmountAsNumber(
          roundToPrecision(buyAmountAsNumber ? buyAmountAsNumber / rate : 0)
        );
      }
    }
  }, [buyAmountAsNumber, fixedSide, rate, sellAmountAsNumber]);

  return {
    sellAmount: Masks.numberToCurrencyNumberString(sellAmountAsNumber),
    buyAmount: Masks.numberToCurrencyNumberString(buyAmountAsNumber),
    sellAmountAsNumber,
    buyAmountAsNumber,
    updateSellAmount: setSellAmountAsNumber,
    updateBuyAmount: setBuyAmountAsNumber,
    setFixedSide,
  };
};

export default useAmounts;
