import dayjs from 'dayjs';
import { CellProps, Column, HeaderProps } from 'react-table';
import { ActionCreator } from 'easy-peasy';
import { Paragraph, Row, StaleInfo } from 'components';
import AmountCell from 'components/shared/AmountCell/AmountCell';
import { TCashflowAtRiskNew } from 'types';
import { notUndefinedTypeGuard, roundToPrecision } from 'utils';
import { DB_DATE_FORMAT } from 'variables';
import DateThreeDotsMenu from '../DateThreeDotsMenu/DateThreeDotsMenu';
import HeaderWithHideControl from 'components/shared/HeaderWithHideControl/HeaderWithHideControl';
import { ICustomCashflowDefinition } from 'types/cashflows';
import HeaderEditable from './components/HeaderEditable/HeaderEditable';
import {
  getDateFormatFromPeriodType,
  onEditCustomCashflowAmount,
  onEditCustomCashflowName,
} from './utils';
import AmountCellEditable from './components/AmountCellEditable/AmountCellEditable';
import {
  HEDGES_COLUMNS_TO_HIDE,
  INFLOWS_COLUMNS_TO_HIDE,
  OUTFLOWS_COLUMNS_TO_HIDE,
} from './consts';
import {
  getInflowCustomDefinitions,
  getOutflowCustomDefinitions,
} from 'pages/Risks/utils';
import HedgesIcon from 'components/shared/HedgesIcon/HedgesIcon';
import InflowsIcon from 'components/shared/InflowsIcon/InflowsIcon';
import OutflowsIcon from 'components/shared/OutflowsIcon/OutflowsIcon';
import { TRisksDataPeriod } from 'pages/Risks/types';

export const generateTableColumns = ({
  currencyCode,
  customCashflowDefinitions,
  setShowSpinnerInRecalculateButton,
  entityCurrency,
  hasCashflowsCsvId,
  period,
  entityId,
}: {
  currencyCode: string;
  customCashflowDefinitions: ICustomCashflowDefinition[];
  setShowSpinnerInRecalculateButton: ActionCreator<boolean>;
  entityCurrency: string;
  hasCashflowsCsvId: boolean;
  period: TRisksDataPeriod;
  entityId: string;
}): Column<TCashflowAtRiskNew>[] => {
  const inflowCustomDefinitions = getInflowCustomDefinitions(
    customCashflowDefinitions
  );
  const outflowCustomDefinitions = getOutflowCustomDefinitions(
    customCashflowDefinitions
  );
  const isAllCurrencies = currencyCode === 'all';
  const currencyCodeToUse = isAllCurrencies ? entityCurrency : currencyCode;
  const areCellsEditable = !isAllCurrencies && !hasCashflowsCsvId;
  const isCurrencyEntityCurrency = currencyCodeToUse === entityCurrency;

  const inflowCustomDefinitionsColumns: Column<TCashflowAtRiskNew>[] = inflowCustomDefinitions.map(
    ({ id, name }) => ({
      id,
      accessor: 'customValues',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => (
        <HeaderEditable
          {...headerProps}
          title={name}
          onEdit={onEditCustomCashflowName({
            entityId,
            newValueId: id,
            customCashflowDefinitions,
            setShowSpinnerInRecalculateButton,
          })}
        />
      ),
      Cell: ({ row }: CellProps<TCashflowAtRiskNew>) => {
        const customCashflowValue = row.original.customValues[id] || 0;

        if (areCellsEditable) {
          return (
            <AmountCellEditable
              amount={customCashflowValue.sum}
              currencyCode={currencyCodeToUse}
              onEdit={onEditCustomCashflowAmount({
                date: row.original.date,
                currency: currencyCodeToUse,
                cashflowDefinitionId: id,
                currentCustomCashflows: customCashflowValue.details,
                setShowSpinnerInRecalculateButton,
              })}
            />
          );
        }

        return (
          <AmountCell
            amount={customCashflowValue.sum}
            currencyCode={currencyCodeToUse}
            withCurrencyPrecision={false}
          />
        );
      },
      minWidth: 80,
    })
  );
  const outflowCustomDefinitionsColumns: Column<TCashflowAtRiskNew>[] = outflowCustomDefinitions.map(
    ({ id, name }) => ({
      id,
      accessor: 'customValues',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => (
        <HeaderEditable
          {...headerProps}
          title={name}
          onEdit={onEditCustomCashflowName({
            entityId,
            newValueId: id,
            customCashflowDefinitions,
            setShowSpinnerInRecalculateButton,
          })}
        />
      ),
      Cell: ({ row }: CellProps<TCashflowAtRiskNew>) => {
        const customCashflowValue = row.original.customValues[id] || 0;

        if (areCellsEditable) {
          return (
            <AmountCellEditable
              amount={customCashflowValue.sum}
              currencyCode={currencyCodeToUse}
              withCurrencyPrecision={false}
              withMinusSign
              onEdit={onEditCustomCashflowAmount({
                date: row.original.date,
                currency: currencyCodeToUse,
                cashflowDefinitionId: id,
                currentCustomCashflows: customCashflowValue.details,
                setShowSpinnerInRecalculateButton,
              })}
            />
          );
        }

        return (
          <AmountCell
            amount={customCashflowValue.sum}
            currencyCode={currencyCodeToUse}
            withCurrencyPrecision={false}
          />
        );
      },
      minWidth: 80,
    })
  );
  const nonAllCurrenciesColumns: Column<TCashflowAtRiskNew>[] =
    isAllCurrencies || isCurrencyEntityCurrency
      ? []
      : [
          {
            accessor: 'hedgeRatio',
            Header: 'Hedge Ratio',
            Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
              <Paragraph>
                {value ? `${roundToPrecision(value, 2)}%` : 'N/A'}
              </Paragraph>
            ),
            minWidth: 80,
          },
          {
            accessor: 'budgetRate',
            Header: 'Budget rate',
            Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
              <Paragraph>
                {value ? `${roundToPrecision(value, 2)}` : 'N/A'}
              </Paragraph>
            ),
            minWidth: 80,
          },
          {
            accessor: 'hedgeRate',
            Header: 'Hedge rate',
            Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
              <Paragraph>
                {value ? `${roundToPrecision(value, 2)}` : 'N/A'}
              </Paragraph>
            ),
            minWidth: 80,
          },
        ];

  return [
    {
      accessor: 'date',
      Cell: ({ value, row }) => {
        const monthlyDate = dayjs(value, DB_DATE_FORMAT);
        const dateToUse = monthlyDate.format(
          getDateFormatFromPeriodType(period)
        );

        return (
          <>
            <Row justifyContent="space-between" flex={1}>
              <Row>
                <Paragraph mr={row.index === 0}>{dateToUse}</Paragraph>
                {row.index === 0 && (
                  <StaleInfo strategy="fixed" infoSize="16px" mode="hover">
                    <Paragraph color="white">
                      First period included overdue but unpaid invoices and
                      orders
                    </Paragraph>
                  </StaleInfo>
                )}
              </Row>
              <DateThreeDotsMenu date={value} />
            </Row>
          </>
        );
      },
      minWidth: 80,
    },
    {
      accessor: 'startingBalance',
      Header: 'Starting Balance',
      Cell: ({ value }: { value: any }) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'receivables',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Cash Inflows"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={[
              ...INFLOWS_COLUMNS_TO_HIDE,
              ...inflowCustomDefinitionsColumns
                .map(({ id }) => id)
                .filter(notUndefinedTypeGuard),
            ]}
            state={state}
            icon={<InflowsIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'receivableInvoices',
      Header: 'Invoices',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      id: 'salesOrders',
      accessor: 'salesOrders',
      Header: 'Sales Orders',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...inflowCustomDefinitionsColumns,
    {
      accessor: 'payables',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Cash Outflows"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={[
              ...OUTFLOWS_COLUMNS_TO_HIDE,
              ...outflowCustomDefinitionsColumns
                .map(({ id }) => id)
                .filter(notUndefinedTypeGuard),
            ]}
            state={state}
            icon={<OutflowsIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          withMinusSign={!value}
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'payableInvoices',
      Header: 'Suppliers',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          withMinusSign={!value}
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      id: 'purchaseOrders',
      accessor: 'purchaseOrders',
      Header: 'Purchase Orders',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          withMinusSign={!value}
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...outflowCustomDefinitionsColumns,
    {
      accessor: 'hedges',
      Header: (headerProps: HeaderProps<TCashflowAtRiskNew>) => {
        const { setHiddenColumns, state } = headerProps;

        return (
          <HeaderWithHideControl
            title="Hedges"
            setHiddenColumns={setHiddenColumns}
            columnsToHide={HEDGES_COLUMNS_TO_HIDE}
            state={state}
            icon={<HedgesIcon />}
          />
        );
      },
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'internalHedges',
      Header: 'HedgeFlows unused',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'hedgesAllocated',
      Header: 'HedgeFlows allocated',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'externalHedges',
      Header: 'External',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    {
      accessor: 'closingBalance',
      Header: 'Closing balance',
      Cell: ({ value }: CellProps<TCashflowAtRiskNew>) => (
        <AmountCell
          amount={value}
          currencyCode={currencyCodeToUse}
          withCurrencyPrecision={false}
        />
      ),
      minWidth: 80,
    },
    ...nonAllCurrenciesColumns,
  ];
};
