import { IBulkPayment, IResponse } from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import { openDoc, openQueryWithTimestamp } from 'utils';
import storage from 'services/storage';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';

export const cancelBulkPayment = async (bulkPaymentId: string) =>
  AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bulk_payments/${bulkPaymentId}/cancel`
  );

export const approveBulkPayment = async ({
  bulkPaymentId,
  userId,
}: {
  bulkPaymentId: string;
  userId: string;
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IBulkPayment>>(
    `/bulk_payments/${bulkPaymentId}/approve`,
    {
      approverId: userId,
    }
  );

export const getExampleFile = async (payload: string) => {
  try {
    return await storage.ref(payload).getDownloadURL();
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToBulkPayments {
  entityId: string;
  callback: (bulkPayments: IBulkPayment[]) => void;
}

export const subscribeToBulkPayments = ({
  entityId,
  callback,
}: SubscribeToBulkPayments) => {
  try {
    return db
      .collection('bulkPayments')
      .where('_owner', '==', entityId)
      .onSnapshot((query) => callback(openQueryWithTimestamp(query)));
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToBulkPaymentByIdParams {
  bulkPaymentId: string;
  callback: (transfers: IBulkPayment | null) => void;
}

export const subscribeToBulkPaymentById = ({
  bulkPaymentId,
  callback,
}: SubscribeToBulkPaymentByIdParams) =>
  db
    .collection('bulkPayments')
    .doc(bulkPaymentId)
    .onSnapshot((doc) => callback(openDoc(doc)));
