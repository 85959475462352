import { useState } from 'react';
import { payWithBalance } from 'services/balances';
import { PayWithBalanceParams } from './../services/balances';
import { useStoreState } from 'state';
import { errorHandler } from 'utils/errors';
import { Notify } from 'utils';

interface UseAmountsParams {
  sellCurrency?: string;
  sellAmount?: number;
}

const useBalance = ({ sellCurrency, sellAmount }: UseAmountsParams) => {
  const { entityId } = useStoreState(({ UserState }) => UserState);
  const { balances } = useStoreState((state) => state.BalancesState);
  const [isLoading, setIsLoading] = useState(false);

  const balanceAvailableAmount =
    balances.find((item) => item.currency === sellCurrency)?.availableAmount ||
    0;
  const isEnoughBalance = !!sellAmount && balanceAvailableAmount >= sellAmount;

  const onPayWithBalance = async ({
    payload,
    onSuccess,
    onError,
  }: {
    payload: Omit<PayWithBalanceParams, 'entityId'>;
    onSuccess?: () => void;
    onError?: (error: any) => void;
  }) => {
    if (!entityId) {
      return;
    }
    try {
      setIsLoading(true);

      await payWithBalance({
        ...payload,
        entityId,
      });

      Notify.success('Transaction funded successfully');
      onSuccess?.();
    } catch (error) {
      errorHandler(error);
      onError?.(error);
    } finally {
      setIsLoading(false);
    }
  };

  return {
    balanceAmount: balanceAvailableAmount,
    isEnoughBalance,
    onPayWithBalance,
    isLoading,
  };
};

export default useBalance;
