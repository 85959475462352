import { FC } from 'react';
import { IHedgeRecommendationResult } from 'pages/HedgeRecommendations/useGetHedgeRecommendations';
import { parseIntoShortNumberString } from 'utils';
import { Paragraph, Row } from 'components';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import {
  getHexagonsStatus,
  getColorKey,
} from 'components/shared/HexagonRating/utils';
import { useTheme } from 'styled-components';

interface IOwnProps {
  record: IHedgeRecommendationResult;
}

const NewHedgeRatioCell: FC<IOwnProps> = ({ record }) => {
  const theme = useTheme();
  // Only use if this is in the opposite direction to the expectedCashflowsAmount
  const existingHedgeAmountToUse =
    record.existingHedgeAmount * record.expectedCashFlowsAmount <= 0
      ? record.existingHedgeAmount
      : 0;
  const newHedgeRatio =
    (record.newHedgeAmount + existingHedgeAmountToUse) /
    (record.expectedCashFlowsAmount / 100);
  const percentage = newHedgeRatio < 0 ? newHedgeRatio * -1 : newHedgeRatio;

  const rating = Math.round(percentage / 20);
  const ratingStatus = getHexagonsStatus(true, 'desc', rating);
  const ratingColor = getColorKey(ratingStatus);

  return (
    <Row gap={theme.spacing.s}>
      <Paragraph>{parseIntoShortNumberString(percentage)}%</Paragraph>

      <HexagonRating
        rating={rating}
        status={ratingStatus}
        titleColor={ratingColor}
      />
    </Row>
  );
};

export default NewHedgeRatioCell;
