import { useState, useCallback, useEffect, Dispatch, SetStateAction } from 'react';
import { errorHandler } from 'utils/errors';
import { generateSearchQuery } from 'utils';

export interface PaginationState<T> {
  data: T[];
  totalCount?: number;
  nextPageStartsAfter?: string;
}

interface FetchPaginatedParams {
  limit?: number;
  nextPageStartsAfter?: string;
  getAll?: boolean;
}

type ExtraQueryParams = Record<string, string | number | undefined>;

interface UsePaginationParams<T> {
  fetchCallback: (
    query: string
  ) => Promise<{
    data: {
      success: boolean;
      data?: {
        data: T[];
        totalCount?: number;
        nextPageStartsAfter?: string;
      };
    };
  }>;
  defaultLimit?: number;
  extraQueryParams?: ExtraQueryParams;
  isReadyToFetch?: boolean;
}

interface UsePaginationReturn<T> {
  data: T[];
  setData: Dispatch<SetStateAction<T[]>>;
  pagination: Omit<PaginationState<T>, 'data'>;
  isLoading: boolean;
  fetchPaginated: (params: FetchPaginatedParams) => Promise<void>;
  loadMore: () => Promise<void>;
  loadAll: () => Promise<void>;
}

const usePagination = <T>({
  fetchCallback,
  defaultLimit = 200,
  isReadyToFetch = true,
  extraQueryParams,
}: UsePaginationParams<T>): UsePaginationReturn<T> => {
  const [data, setData] = useState<T[]>([]);
  const [pagination, setPagination] = useState<
    Omit<PaginationState<T>, 'data'>
  >({});
  const [isLoading, setIsLoading] = useState(false);

  const fetchPaginated = useCallback(
    async ({ limit, nextPageStartsAfter, getAll }: FetchPaginatedParams) => {
      try {
        setIsLoading(true);

        const query = generateSearchQuery({
          limit,
          start_after: nextPageStartsAfter,
          get_all: getAll,
          ...extraQueryParams,
        });

        const { data: response } = await fetchCallback(query);

        if (response.success && response.data) {
          const { data: newData, ...paginationData } = response.data;

          if (getAll) {
            setData(newData || []);
          } else {
            setData((prev) => [...prev, ...(newData || [])]);
          }

          setPagination(paginationData);
        } else {
          errorHandler(response);
        }
      } catch (error) {
        errorHandler(error);
      } finally {
        setIsLoading(false);
      }
    },
    [fetchCallback, extraQueryParams]
  );

  const loadMore = useCallback(
    () =>
      fetchPaginated({
        nextPageStartsAfter: pagination.nextPageStartsAfter,
        limit: defaultLimit,
      }),
    [defaultLimit, fetchPaginated, pagination.nextPageStartsAfter]
  );

  const loadAll = useCallback(() => fetchPaginated({ getAll: true }), [
    fetchPaginated,
  ]);

  // Reset data and refetch when extraQueryParams change
  useEffect(() => {
    if (isReadyToFetch) {
      setData([]);
      setPagination({});
      fetchPaginated({ limit: defaultLimit });
    }
  }, [defaultLimit, fetchPaginated, extraQueryParams, isReadyToFetch]);

  return {
    data,
    setData,
    pagination,
    isLoading,
    fetchPaginated,
    loadMore,
    loadAll,
  };
};

export default usePagination;
