import useAmounts, { UseAmountsReturnValues } from 'hooks/useAmounts';
import useCurrencies, { UseCurrenciesReturnValues } from 'hooks/useCurrencies';
import useCurrencyRate, {
  UseCurrencyRateReturnValues,
} from 'hooks/useCurrencyRate';
import useUrlValues from 'hooks/useUrlValues';
import useRateType, { UseRateTypeReturnValues } from 'hooks/useRateType';
import useSelectedRateContract from 'hooks/useSelectedRateContract';
import useRateContracts, {
  UseRateContractsReturnValues,
} from 'hooks/useRateContracts';
import { useEffect, useMemo, useState } from 'react';
import { getConversionDates } from 'services/firebase/conversions';
import { useStoreState } from 'state';
import { ICurrency, Nullable, RATE_TYPE } from 'types';
import { IConversionDates } from 'types/conversions';
import {
  getFlexCurrentBestRate,
  getFlexCurrentBestRateUnparsed,
  isRateContractRemainingAmountValid,
} from 'utils';
import { getDefaultBuyCurrencyCode } from 'utils/currencies';
import { errorHandler } from 'utils/errors';

export interface UseExchangeDetailsReturnValues
  extends Pick<
      UseCurrenciesReturnValues,
      | 'buyCurrency'
      | 'sellCurrency'
      | 'updateBuyCurrency'
      | 'updateSellCurrency'
      | 'sellCurrencyCode'
      | 'buyCurrencyCode'
    >,
    UseCurrencyRateReturnValues,
    UseRateContractsReturnValues,
    Omit<UseAmountsReturnValues, 'sellAmount' | 'buyAmount'>,
    UseRateTypeReturnValues {
  sellCurrencies: ICurrency[];
  buyCurrencies: ICurrency[];
  isMarketRate: boolean;
  rateToUse: number | null;
  conversionDates: Nullable<IConversionDates>;
  prebookAndMarketRateDifference: number;
  canContinue: boolean;
  invoiceId?: string | null;
}

const useExchangeDetails = (): UseExchangeDetailsReturnValues => {
  const { entityCurrencyCode } = useStoreState((state) => state.UserState);
  const { sellCurrencies, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const { systemVariables } = useStoreState(
    (state) => state.ReferenceDataState
  );

  const {
    predefinedBuyAmount,
    predefinedSellAmount,
    predefinedSellCurrency,
    predefinedBuyCurrency,
    predefinedRateContractId,
    invoiceId,
  } = useUrlValues(
    'predefinedSellCurrency',
    'predefinedBuyAmount',
    'predefinedSellAmount',
    'predefinedBuyCurrency',
    'predefinedRateContractId',
    'invoiceId'
  );

  const [conversionDates, setConversionDates] = useState<
    Nullable<IConversionDates>
  >(null);
  const [isLoadingConversionDates, setIsLoadingConversionDates] = useState(
    false
  );

  const initialSellCurrency =
    predefinedSellCurrency ||
    entityCurrencyCode ||
    systemVariables?.defaultSellCurrency;

  const {
    buyCurrency,
    sellCurrency,
    updateBuyCurrency,
    updateSellCurrency,
    sellCurrencyCode,
    buyCurrencyCode,
  } = useCurrencies({
    predefinedSellCurrency: initialSellCurrency,
    predefinedBuyCurrency:
      predefinedBuyCurrency || getDefaultBuyCurrencyCode(initialSellCurrency),
    isForTransfer: true,
  });

  const {
    rate,
    conversionFeeRate,
    isError,
    isLoading,
    getRate,
  } = useCurrencyRate({
    buyCurrency: buyCurrencyCode,
    sellCurrency: sellCurrencyCode,
  });

  const { rateType, setRateType } = useRateType({
    predefinedRateContractId,
  });

  const {
    selectedRateContract,
    rateContracts,
    setSelectedRateContract,
  } = useRateContracts({
    buyCurrencyCode,
    sellCurrencyCode,
    rate,
    predefinedRateContractId,
    invoiceId,
  });

  const rateToUse = useMemo(() => {
    return rateType === RATE_TYPE.prebooked && selectedRateContract
      ? getFlexCurrentBestRateUnparsed(selectedRateContract, rate) ?? null
      : rate;
  }, [rate, rateType, selectedRateContract]);

  const {
    sellAmountAsNumber,
    updateSellAmount,
    updateBuyAmount,
    buyAmountAsNumber,
    setFixedSide,
  } = useAmounts({
    rate: rateToUse,
    predefinedBuyAmount,
    predefinedSellAmount,
    defaultAmount: systemVariables?.prebookDefaultAmount,
  });

  useSelectedRateContract({
    selectedRateContract,
    updateBuyCurrency,
    updateSellCurrency,
  });

  const isMarketRate = rateType === RATE_TYPE.market;

  const prebookAndMarketRateDifference = useMemo(() => {
    if (!selectedRateContract || !rate) {
      return 0;
    }

    const bestRate = getFlexCurrentBestRate(selectedRateContract, rate);

    if (!bestRate) {
      return 0;
    }

    return buyAmountAsNumber / rate - buyAmountAsNumber / bestRate;
  }, [buyAmountAsNumber, rate, selectedRateContract]);

  const isRateContractAmountValid = isRateContractRemainingAmountValid({
    selectedRateContract,
    buyAmountAsNumber,
  });

  const canContinue =
    sellAmountAsNumber > 0 &&
    buyCurrency?.code !== sellCurrency?.code &&
    !isError &&
    rateToUse &&
    conversionDates &&
    !isLoadingConversionDates &&
    isRateContractAmountValid;

  useEffect(() => {
    const getConversionDatesData = async () => {
      try {
        setIsLoadingConversionDates(true);
        const { data } = await getConversionDates({
          currencyPair: `${sellCurrencyCode}${buyCurrencyCode}`,
        });

        if (data && data.success) {
          setConversionDates(data.data ?? null);
        }
      } catch (error: any) {
        errorHandler(error);
      } finally {
        setIsLoadingConversionDates(false);
      }
    };

    getConversionDatesData();
  }, [buyCurrencyCode, sellCurrencyCode]);

  return {
    rateContracts,
    selectedRateContract,
    rate,
    rateType,
    isLoading,
    buyAmountAsNumber,
    sellAmountAsNumber,
    sellCurrency,
    buyCurrency,
    setSelectedRateContract,
    setRateType,
    updateSellAmount,
    updateBuyAmount,
    updateSellCurrency,
    updateBuyCurrency,
    setFixedSide,
    sellCurrencies,
    buyCurrencies,
    isMarketRate,
    rateToUse,
    conversionFeeRate,
    conversionDates,
    prebookAndMarketRateDifference,
    canContinue: !!canContinue,
    isError,
    getRate,
    buyCurrencyCode,
    sellCurrencyCode,
    invoiceId,
  };
};

export default useExchangeDetails;
