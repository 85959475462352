import { FC } from 'react';
import { useStoreState } from 'state';
import { IInvoice, INVOICE_STATUSES } from 'types';
import { isPayableInvoice } from 'utils/invoices';
import useCurrencyRate from 'hooks/useCurrencyRate';
import useInvoiceRecord from 'hooks/useInvoiceRecord';
import { WhiteCenteredContainer } from 'components/shared/WhiteCenteredContainer/WhiteCenteredContainer.styles';
import {
  WhiteFooterContainer,
  WhiteHeaderContainer,
  WhiteContentContainer,
} from 'components/shared/WhiteContentContainers/WhiteContentContainers.styles';
import InvoiceDetailsHeader from 'components/shared/InvoiceDetailsHeader/InvoiceDetailsHeader';
import InvoiceDetailsContent from 'components/shared/InvoiceDetailsContent/InvoiceDetailsContent';
import InvoiceDetailsFooter from 'components/shared/InvoiceDetailsFooter/InvoiceDetailsFooter';

interface IOwnProps {
  invoice: IInvoice;
  getDetailedInvoice: () => Promise<void>;
}

const InvoiceInfo: FC<IOwnProps> = ({ invoice, getDetailedInvoice }) => {
  const { hasApprovalFlow, entityCurrencyCode } = useStoreState(
    ({ UserState }) => UserState
  );
  const { isPayable } = useInvoiceRecord({
    record: invoice,
  });
  const { rate } = useCurrencyRate({
    buyCurrency: isPayable ? invoice.currency : entityCurrencyCode,
    sellCurrency: isPayable ? entityCurrencyCode : invoice.currency,
  });
  const showApprovalStatus =
    hasApprovalFlow &&
    (invoice.status === INVOICE_STATUSES.authorised ||
      invoice.status === INVOICE_STATUSES.partiallyPaid) &&
    isPayableInvoice(invoice);

  return (
    <WhiteCenteredContainer>
      <WhiteHeaderContainer>
        <InvoiceDetailsHeader
          invoice={invoice}
          showApprovalStatus={showApprovalStatus}
        />
      </WhiteHeaderContainer>

      <WhiteContentContainer>
        <InvoiceDetailsContent invoice={invoice} />
      </WhiteContentContainer>

      <WhiteFooterContainer>
        <InvoiceDetailsFooter
          invoice={invoice}
          rate={rate}
          getDetailedInvoice={getDetailedInvoice}
        />
      </WhiteFooterContainer>
    </WhiteCenteredContainer>
  );
};
export default InvoiceInfo;
