import { useState, useEffect } from 'react';
import { useStoreState } from 'state';

/**
 * Returns true of if a bulk payment is approved, and true if it's not approvable.
 *
 * Otherwise returns false.
 */
export const useIsBulkPaymentApproved = (bulkPaymentId?: string) => {
  const [isApproved, setIsApproved] = useState(false);

  const { bulkPaymentById } = useStoreState((state) => state.BulkPaymentsState);

  useEffect(() => {
    if (bulkPaymentById) {
      const bulkPayment = bulkPaymentById(bulkPaymentId);

      if (!bulkPayment?.approval) {
        setIsApproved(true);
      }

      if (bulkPayment?.approval?.status === 'submitted') {
        setIsApproved(false);
      }

      if (bulkPayment?.approval?.status === 'approved') {
        setIsApproved(true);
      }
    }
  }, [bulkPaymentById, setIsApproved, bulkPaymentId]);

  return { isApproved };
};
