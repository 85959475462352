import { Notify, openDoc, openQueryWithTimestamp } from 'utils';
import {
  ITransfer,
  IResponse,
  ITransferInput,
  ICurrency,
  ICountry,
  IInvoice,
} from 'types';
import { AxiosPrivateFirebaseInstance } from 'settings';
import db from 'services/firestore';
import { errorHandler } from 'utils/errors';
import { ERROR_MESSAGES } from 'variables';

export const getTransfers = async () => {
  try {
    const data = await db.collection('transfers').get();

    return openQueryWithTimestamp(data);
  } catch (error: any) {
    errorHandler(error);
  }
};

export interface SubscribeToTransfersParams {
  entityId: string;
  callback: (transfers: ITransfer[]) => void;
}

export const subscribeToTransfers = ({
  entityId,
  callback,
}: SubscribeToTransfersParams) =>
  db
    .collection('transfers')
    .where('_owner', '==', entityId)
    .onSnapshot((query) => callback(openQueryWithTimestamp(query)));

export interface SubscribeToTransferByIdParams {
  transferId: string;
  callback: (transfer: ITransfer | null) => void;
}

export const subscribeToTransferById = ({
  transferId,
  callback,
}: SubscribeToTransferByIdParams) =>
  db
    .collection('transfers')
    .doc(transferId)
    .onSnapshot((doc) => callback(openDoc(doc)));

export const createTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(`/transfers`, payload);

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export const createSimpleTransfer = async (payload: ITransferInput) => {
  try {
    const { data } = await AxiosPrivateFirebaseInstance.post<
      IResponse<ITransfer>
    >(`/transfers/simple`, payload);

    if (data.success) {
      return data;
    } else {
      // @ts-expect-error TS(2345) FIXME: Argument of type 'string | undefined' is not assig... Remove this comment to see the full error message
      Notify.error(data.message);
    }
  } catch (error: any) {
    errorHandler(error);
  }
};

export const getChallenge = async (payload: ITransferInput) => {
  const { data } = await AxiosPrivateFirebaseInstance.post<IResponse<string>>(
    `/transfers/challenge`,
    payload
  );

  if (data.success) {
    return data.data;
  } else {
    Notify.error(
      data.message ??
        ERROR_MESSAGES.somethingWentWrong(' getting the challenge')
    );
    throw new Error(data.message);
  }
};

export interface GetTransferPurposesParams {
  currency: ICurrency['code'];
  accountCountry?: ICountry['alpha2'];
}

export const getTransferPurposes = async ({
  currency,
  accountCountry,
}: GetTransferPurposesParams) => {
  return AxiosPrivateFirebaseInstance.get<IResponse>(
    `/transfers/purpose/${currency}/${accountCountry}`
  );
};

export const updateTransferPaymentSubmissionDetails = async (
  transferId: string
) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/transfers/mt103/${transferId}`
  );
};

interface ITtransferSendRemittanceParams {
  transferId: string;
  emails: string;
  shouldSendRemittance: boolean;
}

export const transferSendRemittance = async ({
  transferId,
  ...rest
}: ITtransferSendRemittanceParams) => {
  return AxiosPrivateFirebaseInstance.post<IResponse>(
    `/bff/transfers/${transferId}/send-remittance`,
    rest
  );
};

export interface IPaginatedResponse<T extends {}> {
  data: T[];
  totalCount?: number;
  startAfter?: string;
}

export const getTransfersPaginated = async (query: string) =>
  AxiosPrivateFirebaseInstance.get<IResponse<IPaginatedResponse<ITransfer>>>(
    `/transfers${query}`
  );

export const matchTransferToInvoices = async ({
  transferId,
  invoiceIds,
}: {
  transferId: string;
  invoiceIds: string[];
}) =>
  AxiosPrivateFirebaseInstance.post<IResponse<IInvoice[]>>(
    `/transfers/${transferId}/match-to-invoices`,
    { invoiceIds }
  );
