import { Col, Paragraph, Subtitle } from 'components';
import Button from 'components/shared/Button/Button';
import ExchangeRate from 'components/shared/ExchangeRate/ExchangeRate';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import useUrlValues from 'hooks/useUrlValues';
import { FC } from 'react';
import { isMobile } from 'react-device-detect';
import { useStoreState } from 'state';
import { ITransfer, RATE_TYPE } from 'types';
import { getTransferStatusDetails, parseIntoCurrencyString } from 'utils';

interface OwnProps {
  transfer: ITransfer;
}

const TransferDetails: FC<OwnProps> = ({ transfer }) => {
  const { setUrlValue } = useUrlValues('contractId');
  const { currencyByCode } = useStoreState(
    ({ CurrenciesState }) => CurrenciesState
  );
  const statusInfo = getTransferStatusDetails(transfer);
  const goToContract = () => {
    if (!transfer.contractId) {
      return;
    }

    setUrlValue({
      bulkPaymentId: '',
      transferId: '',
      contractId: transfer.contractId,
    });
  };

  return (
    <Col flex={1} alignSelf="stretch">
      <Subtitle variant="bold" mb>
        Transfer details
      </Subtitle>

      {transfer && (
        <>
          <RowInfo>
            <Paragraph>Amount to transfer</Paragraph>

            <Paragraph variant="bold">
              {`${parseIntoCurrencyString(
                transfer.buyAmount,
                currencyByCode(transfer.buyCurrency)?.precision
              )} ${transfer.buyCurrency}`}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Transfer type</Paragraph>
            <Paragraph>
              {transfer.transferType === 'priority'
                ? 'Priority (SWIFT)'
                : 'Regular'}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph>Payment fee</Paragraph>
            <Paragraph>{`${parseIntoCurrencyString(
              transfer.priorityTransferFeeAmount || 0,
              currencyByCode(transfer.sellCurrency)?.precision
            )} ${transfer.sellCurrency}`}</Paragraph>
          </RowInfo>

          <RowInfo>
            <Paragraph variant="bold">
              {transfer.status === 'awaiting_payment'
                ? 'Amount to pay'
                : 'Amount paid'}
            </Paragraph>
            <Paragraph variant="bold">
              {`${parseIntoCurrencyString(
                transfer?.payAmount,
                currencyByCode(transfer.sellCurrency)?.precision
              )} ${transfer?.sellCurrency}`}
            </Paragraph>
          </RowInfo>

          <RowInfo>
            <ExchangeRate
              rate={transfer?.rate}
              sellCurrencyCode={transfer?.sellCurrency}
              buyCurrencyCode={transfer?.buyCurrency}
            />
          </RowInfo>

          {transfer.rateType === RATE_TYPE.prebooked && (
            <RowInfo justifyContent="flex-end">
              <Button variant="link" onClick={goToContract}>
                Prebooked
              </Button>
            </RowInfo>
          )}

          {transfer?.bulkPaymentId && (
            <RowInfo>
              <Paragraph variant="bold">
                This transfer is part of a Bulk Payment
              </Paragraph>
            </RowInfo>
          )}

          {statusInfo && isMobile && (
            <RowInfo>
              <Paragraph>Status</Paragraph>
              <Paragraph>{statusInfo.text}</Paragraph>
            </RowInfo>
          )}
        </>
      )}
    </Col>
  );
};

export default TransferDetails;
