interface IBulkPaymentBase {
  paymentRunId?: string;
  scheduledPaymentDate?: string;
  fundedTimestamp: any;
  localCount?: number;
  swiftCount?: number;
  openBankingCount?: number;
  invoicesCount?: number;
  sellCurrency: string;
  sellAmount: number;
  paymentFee: number;
  payAmount: number;
  payByDate: string;
  payByTimeGMT: string;

  buyAmountsBreakdown: {
    currency: string;
    amount: number;
    rate: number;
    sellAmount: number;
  }[];
  notPaidViaHedgeFlows?: boolean;
  approval?: IBulkPaymentApproval;
}

interface IBulkPaymentSystemFields {
  id: string;
  _created: any;
  _createdBy: string;
  _updated?: any;
  _updatedBy?: string;
  _version: number;
  _owner: string;
  status: BULK_PAYMENT_STATUS;
}

export interface IBulkPayment
  extends IBulkPaymentBase,
    IBulkPaymentSystemFields {}

export enum BULK_PAYMENT_STATUS {
  awaitingPayment = 'awaiting_payment',
  /** Client has initiated funding, e.g. via Open Banking, but we've not received the funds */
  fundingInitiated = 'funding_initiated',
  funded = 'funded',
  processing = 'processing',
  completed = 'completed',
  cancelled = 'cancelled',
  transfersFailed = 'transfers_failed',
}

export interface IBulkPaymentApproval {
  // Status is submitted when payment run is moved to initiated
  status: 'submitted' | 'approved' | null;
  // For now, only single type with 1 or 2 reviewers requred
  type?: 'default';
  requiredNumberOfApprovers?: 1 | 2;
  approvedBy?: string;
  approvedAt?: string;
  approvedBy2?: string;
  approvedAt2?: string;
  messageForUi?: string;
  approvers?: string[];
}
