import { IInvoiceFromSearch, INVOICE_STATUSES } from 'types';

export const updateInMemoryInvoicesWithFundingId = ({
  invoices,
  invoicesForMatch,
  fundingId,
}: {
  invoices: IInvoiceFromSearch[];
  invoicesForMatch: IInvoiceFromSearch[];
  fundingId: string;
}): IInvoiceFromSearch[] =>
  invoices.map((invoiceFromMemory) => {
    const invoice = invoicesForMatch.find(
      (invoiceForMatch) => invoiceForMatch.id === invoiceFromMemory.id
    );

    const fundingIds = invoiceFromMemory.fundingIds || [];

    if (invoice) {
      return {
        ...invoiceFromMemory,
        status: INVOICE_STATUSES.paid,
        fundingIds: [...fundingIds, fundingId],
      };
    }

    return invoiceFromMemory;
  });

export const updateInMemoryInvoicesWithTransferId = ({
  invoices,
  invoicesForMatch,
  transferId,
}: {
  invoices: IInvoiceFromSearch[];
  invoicesForMatch: IInvoiceFromSearch[];
  transferId: string;
}): IInvoiceFromSearch[] => {
  return invoices.map((invoiceFromMemory) => {
    const invoice = invoicesForMatch.find(
      (invoiceForMatch) => invoiceForMatch.id === invoiceFromMemory.id
    );

    if (invoice) {
      return {
        ...invoiceFromMemory,
        status: INVOICE_STATUSES.paid,
        // We have transferIds in invoice in Firestore, but in Elastic we store first transferId as transferId for now
        transferId: transferId,
      };
    }

    return invoiceFromMemory;
  });
};
