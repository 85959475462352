import { IBulkPayment } from 'types';

export const isOneCurrencyBulkPayment = (bulkPayment?: IBulkPayment) => {
  if (!bulkPayment) {
    return false;
  }

  return bulkPayment.buyAmountsBreakdown.length === 1;
};

export const isAssetIdBulPaymentId = (id?: string) => !!id?.includes('-B-');

export const isBulkPaymentApprovableByUser = (
  bulkPayment: IBulkPayment,
  userId: string
): boolean => {
  if (!bulkPayment.approval) {
    return false;
  }

  /**
   * Doesn't matter if 1 or 2 approvals are required.
   *
   * The BE will set the list of approvers for each stage
   */
  return (
    !!bulkPayment.approval?.approvers?.includes(userId) &&
    !isBulkPaymentApprovedByCurrentUser(bulkPayment, userId)
  );
};

const isBulkPaymentApprovedByCurrentUser = (
  bulkPayment: IBulkPayment,
  userId: string
) => bulkPayment.approval?.approvedBy === userId;
