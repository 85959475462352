import { action, Action } from 'easy-peasy';

export interface UtilsStateModel {
  showLimitedAccess: boolean;
  setShowLimitedAccess: Action<UtilsStateModel, boolean>;
}

export const UtilsState: UtilsStateModel = {
  showLimitedAccess: false,
  setShowLimitedAccess: action((state, payload) => {
    state.showLimitedAccess = payload;
  }),
};
