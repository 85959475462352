import { Button, Paragraph, Popup, Row, Title } from 'components';
import { FC } from 'react';

interface IOwnProps {
  onClose: () => void;
  onConfirm: () => void;
}

const WriteWarningPopup: FC<IOwnProps> = ({ onClose, onConfirm }) => (
  <Popup
    width="400px"
    height="300px"
    HeaderContent={<Title variant="h3">Warning</Title>}
    FooterContent={
      <Row flex={1}>
        <Button onClick={onConfirm}>Confirm</Button>
        <Button variant="secondary" onClick={onClose}>
          Cancel
        </Button>
      </Row>
    }
    onClose={onClose}
  >
    <Paragraph>
      You are about to create a transaction record in ERP manually. Please check
      it after you create it.
    </Paragraph>
  </Popup>
);

export default WriteWarningPopup;
