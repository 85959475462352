import { FC, useState } from 'react';

import {
  Title,
  StaleCheckboxControlled,
  Paragraph,
  Subtitle,
} from 'components';
import { Wrapper } from './Review.styles';
import TransferSummaryInfo from 'components/shared/TransferSummaryInfo/TransferSummaryInfo';
import {
  FirstStepValues,
  SecondStepValues,
} from 'pages/SimpleTransfer/SimpleTransfer';
import { Notify, parseIntoCurrencyString, roundToPrecision } from 'utils';
import { Row } from 'components/shared/Row/Row';
import { useForm } from 'react-hook-form';
import { PAYMENT_TYPE, RATE_TYPE, TRANSFER_TYPE } from 'types';
import {
  createSimpleTransfer,
  getChallenge,
  updateRecipient,
} from 'services/firebase';
import StaleReAuthenticate from 'components/shared/StaleReAuthenticate/StaleReAuthenticate';
import {
  FlowContentLeft,
  FlowContentRight,
  FlowStepContent,
  FlowStepFooter,
  FlowStepWrapper,
} from 'components/shared/FlowStep/FlowStep.styles';
import StaleInput from 'components/shared/StaleInput/StaleInput';
import { RowInfo } from 'components/shared/RowInfo/RowInfo.styles';
import { useReAuthenticate } from 'hooks';
import TooManyTimePasswordAttemptsPopup from 'components/shared/TooManyTimePasswordAttemptsPopup/TooManyTimePasswordAttemptsPopup';
import Button from 'components/shared/Button/Button';
import { DATE_FORMAT, DB_DATE_FORMAT, ERROR_MESSAGES } from 'variables';
import dayjs from 'dayjs';

interface FormValues {
  password: string;
  reference: string;
  isTrustedRecipient?: boolean;
}

const getTransferInputFromSavedValues = ({
  savedValues,
  reference,
}: {
  savedValues: FirstStepValues & SecondStepValues;
  reference: string;
}) => ({
  sellAmount: savedValues.sellAmount,
  sellCurrency: savedValues.sellCurrency.code,
  buyAmount: savedValues.sellAmount,
  buyCurrency: savedValues.sellCurrency.code,
  rate: 1,
  rateType: RATE_TYPE.market,
  payAmount: roundToPrecision(savedValues.sellAmount + savedValues.fee),
  transferType:
    savedValues.recipient.paymentType === PAYMENT_TYPE.swift
      ? TRANSFER_TYPE.priority
      : TRANSFER_TYPE.regular,
  recipientId: savedValues.recipient.id,
  priorityTransferFeeAmount: savedValues.fee,
  reference,
  invoiceIds: !!savedValues.invoice?.id ? [savedValues.invoice.id] : undefined,
  scheduledPaymentDate: !!savedValues.scheduledPaymentDate
    ? dayjs(savedValues.scheduledPaymentDate, DATE_FORMAT).format(
        DB_DATE_FORMAT
      )
    : undefined,
});

interface OwnProps {
  onContinue: (transferId: string) => void;
  savedValues: FirstStepValues & SecondStepValues;
}

const Review: FC<OwnProps> = ({ onContinue, savedValues }) => {
  const { invoice, recipient, sellAmount, sellCurrency } = savedValues;

  const [isLoading, setIsLoading] = useState(false);
  const { handleSubmit, control, errors } = useForm<FormValues>();

  const {
    onReAuthenticateClick,
    isTooManyPasswordAttemptsError,
    setIsTooManyPasswordAttemptsError,
  } = useReAuthenticate();

  const fetchChallenge = async (reference: string) => {
    const transferInputForChallenge = getTransferInputFromSavedValues({
      savedValues,
      reference,
    });

    const challenge = await getChallenge(transferInputForChallenge);
    if (!challenge) {
      throw new Error(ERROR_MESSAGES.noChallengeReturned);
    }

    return challenge;
  };

  const onConfirm = async ({ isTrustedRecipient, reference }: FormValues) => {
    try {
      // 1. Fetch challenge
      const challenge = await fetchChallenge(reference);

      // 2. Add challenge when creating transfer
      const response = await createSimpleTransfer({
        ...getTransferInputFromSavedValues({ savedValues, reference }),
        challenge,
      });

      if (response && response.data && response.success) {
        if (isTrustedRecipient && !recipient.isTrusted) {
          updateRecipient({
            recipientId: recipient.id,
            recipientData: {
              ...recipient,
              isTrusted: true,
            },
          });
        }

        onContinue(response.data.id);
      }
    } catch (error: any) {
      Notify.error(error);
    }
  };

  const onSubmitHandler = async (values: any) => {
    try {
      setIsLoading(true);

      if (!recipient.isTrusted) {
        await onReAuthenticateClick(values);
      }
      await onConfirm(values);
    } catch (error: any) {
      const message =
        error?.code === 'auth/wrong-password'
          ? 'You have entered a wrong password'
          : error?.message;

      Notify.error(message);
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <>
      <FlowStepWrapper>
        <form
          id="simple-transfer-review-form"
          onSubmit={handleSubmit(onSubmitHandler)}
        >
          <FlowStepContent>
            <FlowContentLeft>
              <Wrapper>
                <Title variant="h5">Recipient details</Title>

                <RowInfo>
                  <Paragraph variant="thin">Account name</Paragraph>
                  <Paragraph variant="bold">
                    {recipient.recipientName}
                  </Paragraph>
                </RowInfo>
                <RowInfo>
                  <Paragraph variant="thin">Account number</Paragraph>
                  <Paragraph variant="bold">
                    {recipient.accountNumber}
                  </Paragraph>
                </RowInfo>

                {!!recipient.bankName && (
                  <RowInfo>
                    <Paragraph variant="thin">Bank name</Paragraph>
                    <Paragraph variant="bold">{recipient.bankName}</Paragraph>
                  </RowInfo>
                )}
                {!!recipient.bicSwift && (
                  <RowInfo>
                    <Paragraph variant="thin">Bank code (SWIFT/BIC)</Paragraph>
                    <Paragraph variant="bold">{recipient.bicSwift}</Paragraph>
                  </RowInfo>
                )}

                <Title variant="h5" mt>
                  Transfer details
                </Title>

                <RowInfo>
                  <Paragraph variant="thin">Amount</Paragraph>
                  <Paragraph variant="bold">{`${
                    sellCurrency.code
                  } ${parseIntoCurrencyString(sellAmount)}`}</Paragraph>
                </RowInfo>

                <Title variant="h5" mt mb>
                  Add your reference
                </Title>
                <StaleInput
                  id="reference"
                  name="reference"
                  view="moving"
                  label="Your reference"
                  control={control}
                  defaultValue={
                    recipient?.defaultReference || invoice?.invoiceNumber
                  }
                />
              </Wrapper>

              {!recipient.isTrusted && (
                <Row mt>
                  <Wrapper>
                    <StaleCheckboxControlled
                      id="isTrustedRecipient"
                      control={control}
                      name="isTrustedRecipient"
                      Label={
                        <Paragraph variant="bold">
                          Save as a Trusted Payee
                        </Paragraph>
                      }
                    />

                    <Paragraph style={{ marginTop: '28px' }}>
                      Future payments will not require a password. Please only
                      use it with Payees that you consider trustworthy.
                    </Paragraph>
                  </Wrapper>
                </Row>
              )}
            </FlowContentLeft>
            <FlowContentRight>
              <Subtitle variant="bold">Summary</Subtitle>

              <TransferSummaryInfo {...savedValues} />
            </FlowContentRight>
          </FlowStepContent>

          <FlowStepFooter>
            {recipient.isTrusted ? (
              <Button
                isLoading={isLoading}
                disabled={isLoading}
                form="simple-transfer-review-form"
                type="submit"
              >
                Confirm
              </Button>
            ) : (
              <StaleReAuthenticate control={control} errors={errors}>
                <Button
                  isLoading={isLoading}
                  disabled={isLoading}
                  form="simple-transfer-review-form"
                  type="submit"
                >
                  Confirm
                </Button>
              </StaleReAuthenticate>
            )}
          </FlowStepFooter>
        </form>
      </FlowStepWrapper>
      {isTooManyPasswordAttemptsError && (
        <TooManyTimePasswordAttemptsPopup
          onClose={() => setIsTooManyPasswordAttemptsError(false)}
        />
      )}
    </>
  );
};

export default Review;
