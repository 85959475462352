import { Column } from 'react-table';
import dayjs from 'dayjs';
import { Paragraph, Row } from 'components';
import {
  parseIntoCurrencyStringWithSymbol,
  formatCamelCaseToReadable,
} from 'utils';
import {
  IStatusCount,
  IWalletTransaction,
  IWalletTransactionWriteableResource,
  TEventResultStatus,
} from './types';
import StatementTableActionsCell from './components/StatementTableActionsCell/StatementTableActionsCell';
import { ICurrency, Nullable } from 'types';
import { DATE_FORMAT_SHORT_MONTH_NAME } from 'variables';
import { Dispatch, SetStateAction } from 'react';
import StatusIconsCell from './components/StatusIconsCell/StatusIconsCell';
import SingleStatusIconCell from './components/SingleStatusIconCell/SingleStatusIconCell';

export const generateStatementsTableColumns = ({
  currencyByCode,
  setWalletTransactions,
}: {
  currencyByCode: (currency: string) => Nullable<ICurrency>;
  setWalletTransactions: Dispatch<SetStateAction<IWalletTransaction[]>>;
}): Column<IWalletTransaction>[] => [
  {
    Header: 'Settled on',
    accessor: 'settledOn',
    Cell: ({ value }) => (
      <Paragraph>
        {value ? dayjs(value).format(DATE_FORMAT_SHORT_MONTH_NAME) : '-'}
      </Paragraph>
    ),
    width: 120,
  },
  {
    Header: 'Reference',
    accessor: 'reference',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 120,
  },
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 120,
  },
  {
    Header: 'Description',
    accessor: 'description',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 250,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Credit
      </Row>
    ),
    accessor: 'amountCredit',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value === undefined
            ? '-'
            : parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Debit
      </Row>
    ),
    accessor: 'amountDebit',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value === undefined
            ? '-'
            : parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Balance
      </Row>
    ),
    accessor: 'balance',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value === undefined
            ? '-'
            : parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    id: 'auto-sync',
    Header: () => (
      <Row justifyContent="center" flex={1}>
        Auto-sync
      </Row>
    ),
    accessor: (row) => {
      const statusCounts = row.writableResources?.reduce<IStatusCount>(
        (acc, resource) => {
          if (resource.status === 'success') {
            acc.success += 1;
          } else if (resource.status === 'pending') {
            acc.pending += 1;
          } else if (resource.status === 'manually_completed') {
            acc.manuallyCompleted += 1;
          } else {
            acc.other += 1;
          }
          return acc;
        },
        { success: 0, pending: 0, other: 0, manuallyCompleted: 0 }
      );
      return statusCounts;
    },
    Cell: ({ value }: { value: IStatusCount }) => <StatusIconsCell value={value} />,
    width: 120,
  },
  {
    id: 'dropdown',
    disableSortBy: true,
    Header: () => null,
    Cell: ({ row }: any) => (
      <StatementTableActionsCell
        walletTransaction={row.original}
        setWalletTransactions={setWalletTransactions}
      />
    ),
    width: 100,
  },
];

export const generateWriteableResourceTableColumns = ({
  currencyByCode,
}: {
  currencyByCode: (currency: string) => Nullable<ICurrency>;
}): Column<IWalletTransactionWriteableResource>[] => [
  {
    Header: 'Type',
    accessor: 'type',
    Cell: ({ value }) => (
      <Paragraph>{formatCamelCaseToReadable(value)}</Paragraph>
    ),
    width: 120,
  },
  {
    Header: 'Currency',
    accessor: 'currency',
    Cell: ({ value }) => <Paragraph>{value || '-'}</Paragraph>,
    width: 100,
  },
  {
    Header: () => (
      <Row justifyContent="flex-end" flex={1}>
        Amount
      </Row>
    ),
    accessor: 'amount',
    Cell: ({ value, row }) => (
      <Row justifyContent="flex-end" flex={1}>
        <Paragraph>
          {value === undefined
            ? '-'
            : parseIntoCurrencyStringWithSymbol(
                value,
                currencyByCode(row.original.currency)?.symbol
              )}
        </Paragraph>
      </Row>
    ),
    width: 120,
  },
  {
    id: 'status',
    Header: () => (
      <Row justifyContent="center" flex={1}>
        Status
      </Row>
    ),
    accessor: 'status',
    Cell: ({
      value,
    }: {
      value: TEventResultStatus | 'permission-error' | undefined;
    }) => <SingleStatusIconCell value={value} />,
    width: 100,
  },
];
