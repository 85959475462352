import { FC } from 'react';
import { Icon, Paragraph, Row, StaleInfo } from 'components';
import { TEventResultStatus } from '../../types';

interface IOwnProps {
  value: TEventResultStatus | 'permission-error' | undefined;
}

const SingleStatusIconCell: FC<IOwnProps> = ({ value }) => (
  <Row justifyContent="center" flex={1}>
    <StaleInfo
      infoSize="16px"
      mode="hover"
      trigger={
        <>
          {value !== 'success' &&
            value !== 'pending' &&
            value !== 'manually_completed' && (
              <Icon
                icon="cross-ico"
                fill="red"
                width="16px"
                height="16px"
              />
            )}
          {value === 'pending' && (
            <Icon icon="clock-ico" width="16px" height="16px" />
          )}
          {value === 'success' && (
            <Icon
              icon="checkbox-checkmark"
              fill="green"
              width="16px"
              height="16px"
            />
          )}
          {value === 'manually_completed' && (
            <Icon
              icon="person-edit"
              fill="green"
              width="16px"
              height="16px"
            />
          )}
        </>
      }
    >
      <Paragraph color="white">
        {value === 'success' && 'Synced'}
        {value === 'pending' && 'Pending'}
        {value !== 'success' &&
          value !== 'pending' &&
          value !== 'manually_completed' &&
          'Not synced'}
        {value === 'manually_completed' && 'Manually completed'}
      </Paragraph>
    </StaleInfo>
  </Row>
);

export default SingleStatusIconCell; 