import { FC, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router';
import { useStoreActions, useStoreState } from 'state';
import { getReduceRiskRecommendationsPageLink } from 'utils/links';
import { getRiskLevelRatingText } from 'utils/analysis';
import useUrlValues from 'hooks/useUrlValues';
import { InlineLoader, StaleInputFile, StaleInputSelect } from 'components';
import HexagonRating from 'components/shared/HexagonRating/HexagonRating';
import { getHexagonsStatus } from 'components/shared/HexagonRating/utils';
import Button from 'components/shared/Button/Button';
import { ISelectItem } from 'components/shared/StaleInputSelect/StaleInputSelect';
import NetWorthSingle from './components/NetWorthSingle/NetWorthSingle';
import { StyledCard, StyledRow } from './CashflowAtRisk.styles';
import { TCashflowAtRiskNew, INetWorthValues } from 'types';
import { TRisksDataPeriod } from 'pages/Risks/types';
import { getCurrenciesFromEntityCashflows } from 'services/cashflows';
import { errorHandler } from 'utils/errors';
import { IFileWithId } from 'components/shared/StaleInputFile/StaleInputFile';
import {
  IRiskGroupedByEntityData,
  isRiskGroupedByEntityDataTypeGuard,
} from 'services/firebase/analysis';
import NetWorth from './components/NetWorth/NetWorth';

interface IOwnProps {
  getCashflowsRisksPerCurrencyHandler: (
    currencyCode: string,
    period: TRisksDataPeriod
  ) => Promise<void>;
  cashflowsRisksData?:
    | {
        perPeriod: TCashflowAtRiskNew[];
        netWorthValues: INetWorthValues;
      }
    | IRiskGroupedByEntityData;
}

const CashflowAtRisk: FC<IOwnProps> = ({
  getCashflowsRisksPerCurrencyHandler,
  cashflowsRisksData,
}) => {
  const history = useHistory();
  const { entityId, entityCurrencyCode } = useStoreState(({ UserState }) => UserState);
  const { entityCashflowsSettings } = useStoreState(({ EntityState }) => EntityState);
  const { currencyByCode, buyCurrencies } = useStoreState(
    (state) => state.CurrenciesState
  );
  const {
    isRefreshingCashflowsRisksData,
    showSpinnerInRecalculateButton,
  } = useStoreState((state) => state.ReferenceDataState);
  const {
    refreshCashflowsRisksData,
    setShowSpinnerInRecalculateButton,
  } = useStoreActions((actions) => actions.ReferenceDataState);
  const { currency: currencyCode, period, setUrlValue } = useUrlValues(
    'currency',
    'period'
  );
  const [currencyCodesFromCashflows, setCurrencyCodesFromCashflows] = useState<
    string[]
  >([]);
  const currency = currencyByCode(currencyCode);

  useEffect(() => {
    getCurrenciesFromEntityCashflows()
      .then((result) => {
        if (result.data.data) {
          setCurrencyCodesFromCashflows(result.data.data);
        }
      })
      .catch(errorHandler);
  }, []);

  useEffect(() => {
    if (!currency && currencyCode !== 'all') {
      const initialCurrency = currencyCodesFromCashflows[0];

      if (initialCurrency) {
        setUrlValue({ currency: initialCurrency });
      }
    }
  }, [currency, currencyCode, currencyCodesFromCashflows, setUrlValue]);

  const selectData = useMemo(() => {
    const currenciesFromCashflowsRisks = currencyCodesFromCashflows.reduce<
      ISelectItem[]
    >((acc, itemCurrencyCode) => {
      if (buyCurrencies.find((curr) => curr.code === itemCurrencyCode)) {
        acc.push({
          id: itemCurrencyCode,
          name: itemCurrencyCode,
          value: itemCurrencyCode,
          icon: currencyByCode(itemCurrencyCode)?.countryCode,
        });
      }

      return acc;
    }, []);

    return [
      { id: 'all', name: 'All currencies', value: 'all' },
      ...currenciesFromCashflowsRisks,
    ];
  }, [buyCurrencies, currencyByCode, currencyCodesFromCashflows]);

  const showRating =
    currencyCode &&
    entityCurrencyCode !== currencyCode &&
    currencyCode !== 'all';
  const showRecalculateButton =
    !entityCashflowsSettings?.fileShareSystem ||
    entityCashflowsSettings?.fileShareSystem === 'google';
  const showReuploadFileButton =
    entityCashflowsSettings?.fileShareSystem === 'microsoftFileUpload';

  const processRecalculateRequest = async (files?: IFileWithId[]) => {
    if (!entityId || !entityCurrencyCode || !currencyCode) {
      return;
    }

    await refreshCashflowsRisksData({
      entityId,
      sellCurrency: entityCurrencyCode,
      files,
    });
    await getCashflowsRisksPerCurrencyHandler(
      currencyCode,
      period as TRisksDataPeriod
    );

    setShowSpinnerInRecalculateButton(false);
  };

  return (
    <>
      <StyledCard>
        <StyledRow>
          <StaleInputSelect
            selected={selectData.find((item) => item.value === currencyCode)}
            data={selectData}
            onSelect={(item) => setUrlValue({ currency: item.value })}
            id="invoices-currency"
            style={{ minWidth: '132px' }}
            strategy="fixed"
          />
          {showRating && (
            <HexagonRating
              rating={cashflowsRisksData?.netWorthValues.riskRating}
              status={getHexagonsStatus(
                true,
                'asc',
                cashflowsRisksData?.netWorthValues.riskRating
              )}
              title={`Risk: ${getRiskLevelRatingText(
                cashflowsRisksData?.netWorthValues.riskRating
              )}`}
              description="We track for you how close your currency exposures are to the risk tolerance limits you've set."
            />
          )}
        </StyledRow>

        {isRiskGroupedByEntityDataTypeGuard(cashflowsRisksData) ? (
          <NetWorth
            currencyCode={currencyCode}
            values={cashflowsRisksData.netWorthValues}
          />
        ) : (
          <NetWorthSingle
            currencyCode={currencyCode}
            values={cashflowsRisksData?.netWorthValues}
          />
        )}

        <StyledRow>
          <Button
            variant={showSpinnerInRecalculateButton ? 'secondary' : 'primary'}
            onClick={() =>
              history.push(
                getReduceRiskRecommendationsPageLink({
                  currency: currencyCode || undefined,
                })
              )
            }
          >
            Reduce risks
          </Button>
          {showRecalculateButton && (
            <Button
              variant={showSpinnerInRecalculateButton ? 'primary' : 'secondary'}
              disabled={isRefreshingCashflowsRisksData}
              isLoading={isRefreshingCashflowsRisksData}
              onClick={() => processRecalculateRequest()}
            >
              Recalculate
              {!isRefreshingCashflowsRisksData && (
                <InlineLoader
                  ml
                  icon="refresh"
                  spin={showSpinnerInRecalculateButton}
                />
              )}
            </Button>
          )}

          {showReuploadFileButton && (
            <StaleInputFile
              style={{ width: 'auto' }}
              accept=".xlsx"
              onChange={processRecalculateRequest}
              multiple={false}
              trigger={
                <Button
                  variant="primary"
                  disabled={isRefreshingCashflowsRisksData}
                  isLoading={isRefreshingCashflowsRisksData}
                >
                  Reupload file
                </Button>
              }
            />
          )}
        </StyledRow>
      </StyledCard>
    </>
  );
};

export default CashflowAtRisk;
