import { ICustomCashflowDefinition } from './cashflows';
import { ICurrency } from './currencies';
import {
  IPermitRoleV2,
  TEntityPackageKeys,
  TEntityUserAttributes,
} from './permissions';
import { ISystemFieldsWithOwner } from './systemFields';
import { TInvoicesAggregationPerFilter } from './invoices';

export enum IEntityAccountDetailsType {
  priority = 'priority',
  regular = 'regular',
  regularSwift = 'regularSwift',
  ach = 'ach', // AccountDetails in USD where routingCodeType === ach_routing_number
}

export interface IEntityPriorityAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.priority;
  accountHolder: string;
  iban: string;
  bankName: string;
  bic: string;
  bankAddress: string;
  bankCountry: string;
}

export interface IEntityRegularAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regular;
  accountHolder: string;
  accountNumber: string;
  sortCode: string;
  currencyCode: string;
}

export interface IEntityRegularSwiftAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.regularSwift;
  accountHolder: string;
  iban: string;
  currencyCode: string;
}

export interface IEntityAchAccountDetails {
  id: string;
  type: IEntityAccountDetailsType.ach;
  accountHolder: string;
  accountNumber: string;
  routingNumber: string;
  bankName: string;
  bankAddress: string;
  bankCountry: string;
}

export type IOneOfEntityAccountDetails =
  | IEntityPriorityAccountDetails
  | IEntityRegularAccountDetails
  | IEntityRegularSwiftAccountDetails
  | IEntityAchAccountDetails;

export interface IEntityDetails {
  address: IAddress;
  addressCorrespondence: IAddress;
  companyRegistrarId?: string;
  countriesSendingMoneyTo: string[];
  natureOfBusiness: string;
  natureOfBusinessCode: string;
  natureOfBusinessSicFromCh?: string[];
  purposeOfAccount: string;
  vat: boolean;
  vatNumber?: string;
  invoicesAndReceivesPayments?: boolean;
  canProvideInvoicesAndBankStatements?: boolean;
  name?: string; //You can optionally also store the name here
  creditLimit?: number;
}

export interface IAddress {
  addressLine1: string;
  addressLine2?: string; //Sometimes referred to as "Premises", e.g. "Lower Ground Floor" or "New Burlington House"
  postalCode: string;
  city: string;
  country: string; //alpha2 code
  stateOrProvince?: string; //Used for some countries, e.g. Companies House usese "region": "Hertfordshire"
}

export enum ENTITY_TYPE {
  soleTrader = 'soleTrader',
  limitedCompany = 'limitedCompany',
  publicLimitedCompany = 'publicLimitedCompany',
  simplePartnership = 'simplePartnership',
  limitedLiabilityPartnership = 'limitedLiabilityPartnership',
}

enum ENTITY_LOADING_STATE_NAMES {
  cashflowAtRisk = 'cashflowAtRisk',
  reports = 'reports',
}

export interface IEntityLoadingState
  extends Record<ENTITY_LOADING_STATE_NAMES, boolean> {
  id: string;
}

export interface IEntityPayoutAccount {
  id: string;
  currencyCode: string;
  accountNumber: string;
  routingNumber?: string;
  bicSwift?: string;
}

export interface IEntityDataStatuses {
  /** ISO date string */
  invoicesLastUpdated: string;
  /** ISO date string */
  newPaidAndDeletedInvoicesLastUpdated: string;
  /** ISO date string */
  receivableAuthorisedInvoicesLastUpdated: string;
}

interface IAccountNumberAndRouting {
  type: 'local' | 'sepa' | 'swift';
  accountNumber: string;
  accountNumberType: 'accountNumber' | 'iban';
  routingCode: string;
  routingCodeType: 'sortCode' | 'bicSwift'; // | 'ach'
}

export interface IFundingAccount {
  id: string;
  currencies: string[];
  accountDetails: IAccountNumberAndRouting;
  secondaryAccountDetails?: IAccountNumberAndRouting;
  accountName: string;
  reference: string;
  bankName: string;
  bankAddress: string;
}

export interface IEntityApprovalSettings {
  hasApprovalFlow?: boolean;
  // level 1
  autoApproveEnabled?: boolean;
  autoApproveAmount?: number;
  autoApproveRecipientList?: IApprovalSettingsRecipientOrApprover[];
  // level 2
  approver1List?: IApprovalSettingsRecipientOrApprover[];
  approver2List?: IApprovalSettingsRecipientOrApprover[];
  requiredNumberOfApprovals?: number;
  // level 3
  exceptionalApprovalEnabled: boolean;
  exceptionalAmount?: number;
  exceptionalRecipientList?: IApprovalSettingsRecipientOrApprover[];
  exceptionalApproverList?: IApprovalSettingsRecipientOrApprover[];

  /** PaymentRunSection */
  paymentRunsApprovalsEnabled?: boolean;
  paymentRunsApprover1List: IApprovalSettingsRecipientOrApprover[];
  paymentRunsApprover2List?: IApprovalSettingsRecipientOrApprover[];
  paymentRunsTwoApprovers?: boolean;
  requiredNumberOfApprovalsForPaymentRuns?: number;
}

export interface IEntitySettings {
  email?: string;
  shouldSendTransactionConfirmations?: boolean;
  shouldSendApprovalAlerts?: boolean;
  shouldSendRemittanceCopiesToSelf?: boolean;
  shouldSendEmailIfRiskToleranceExceeded?: boolean;
  riskTolerance?: number;
  accountingSystem?: string;
  howDidYouFindUs?: string;
  riskSettingCommittedCashFlows?: string;
  riskSettingExpectedCashFlows?: string;
  HFGuru?: {
    integrations: {
      isDone: boolean;
    };
  };
  deferredSuggestedFollowedCurrencies?: Record<ICurrency['code'], string>; // "YYYY-MM-DD"
  riskSettingHedgeRatio?: TRiskSettingHedgeRatio;
  notificationSettingsRiskAlertsEnabled?: boolean;
  excludedAccountIds?: string[];
  approvalSettings?: IEntityApprovalSettings;
}

export type TEntityPackages = Record<TEntityPackageKeys, IEntityPackage>;

interface IEntityPackage {
  freeTrialUntil?: string | null;
  enabledUntil?: string | null;
}

/**
 * Live means that the entity has been onboarded and is enabled.
 * Dormant means that a company has not been trading for a wile and we want disable it until we understand what to do with it.
 * Exited means that the company has decided to stop trading with us and we want to disable it.
 * OnHold means that the company has been put on hold for some reason and we want to disable it temporarily.
 * Any other value than "live" should disable the entity (enabled=false).
 */
enum ENTITY_SUB_STATUS_AFTER_ONBOARDING {
  live = 'live',
  dormant = 'dormant',
  exited = 'exited',
  onHold = 'onHold',
}

export interface IEntity extends IEntitySettings {
  expectedAnnualTurnover: number;
  expectedFxTurnoverUpperLimit: number;
  externalRefs: {
    ccId: string; // "9367e94f-7015-4a78-882c-67009c14e74c"
    ccOnBehalfOfId: string; //"91f2cfb7-2eb0-4ab9-9fef-9469fee1280"
    ccShortReference: string; // "210908-58552"
  };
  invoicesAndReceivesPayments: boolean;
  name: string;
  onboarded: boolean;
  status?: string;
  /**
   * Used to indicate the entity's status after getting status=onboarded.
   * When set to anything else than "live" then enabled should be set to false and the entity should be unusable.
   */
  subStatusAfterOnboarding?: ENTITY_SUB_STATUS_AFTER_ONBOARDING;
  companyCountry?: string;
  canProvideInvoicesAndBankStatements: boolean;
  companyType: ENTITY_TYPE; // "limitedCompany"
  enabled: boolean;
  entityCurrency: string; // GBP for example
  remainingCredit?: number;
  hfGuru?: boolean;
  approverId?: string;
  shouldShowReports?: boolean;
  packages?: TEntityPackages;
  isUsingDeposits?: boolean; // true if entity wants to always use deposits during prebooking

  /** ISO 8601 string, e.g., 2024-07-01T09:51:16.748Z */
  deferAddAuthenticator?: string;
}

export interface IEntityUser {
  id: string;
  firstName: string;
  lastName: string;
  name: string;
  email: string;
  roles: IPermitRoleV2[];
  enabled: boolean;
  attributes: TEntityUserAttributes[];
}
export interface IEntityInvite extends ISystemFieldsWithOwner {
  userId: string; // invitee
  firstName: string;
  lastName: string;
  email: string;
  entityId: string;
  roles: string[];
  status: TInviteStatus;
}
export type TInviteStatus = 'pending' | 'accepted' | 'rejected' | 'expired';
export interface IClient {
  entityId: string;
  name: IEntity['name'];
  entityCurrency: IEntity['entityCurrency'];
  worstRiskRatingStars: number;
  topCurrenciesByCashflowRisk: string[];
  outstandingPaymentRunsCount: number;
  billsOutstanding?: TInvoicesAggregationPerFilter;
  billsOverdue?: TInvoicesAggregationPerFilter;
}
export type TRiskSettingHedgeRatio = Record<
  TRiskSettingHedgeRatioKeys,
  IRiskSettingHedgeRatioValues
>;
export interface IRiskSettingHedgeRatioValues {
  min: number;
  max: number;
}
export type TRiskSettingHedgeRatioKeys = '3' | '6' | '9' | '12' | '24' | '36';
export type TCashflowsShareSystem = 'google' | 'microsoftFileUpload';
export interface IApprovalSettingsRecipientOrApprover {
  id: string;
  name: string;
}

export interface IEntityCashflowsSettings {
  customCashflowDefinitions?: ICustomCashflowDefinition[];
  /** Used for loading part of the cashflows data from CSV */
  fileShareUrl?: string;
  fileShareSystem?: TCashflowsShareSystem;
  /** Used to net the cashflows at risk by period e.g. month or week */
  cashflowsAtRiskNettingPeriod?: 'month' | 'week';
}
