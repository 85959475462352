import { Icon, Paragraph, Row, StaleInfo } from 'components';
import { FC } from 'react';
import { IStatusCount } from '../../types';

interface IOwnProps {
  value: IStatusCount;
}

const StatusIconsCell: FC<IOwnProps> = ({ value }) => (
  <Row justifyContent="center" flex={1} gap="4px">
    <StaleInfo
      infoSize="16px"
      mode="hover"
      trigger={
        <Row gap="4px">
          {value.other > 0 && (
            <Row alignItems="center">
              <Icon icon="cross-ico" fill="red" width="16px" height="16px" />
              {value.other > 1 && (
                <Paragraph color="red">{`(${value.other})`}</Paragraph>
              )}
            </Row>
          )}
          {value.pending > 0 && (
            <Row alignItems="center">
              <Icon icon="clock-ico" width="16px" height="16px" />
              {value.pending > 1 && (
                <Paragraph color="red">{`(${value.pending})`}</Paragraph>
              )}
            </Row>
          )}
          {value.success > 0 && (
            <Row alignItems="center">
              <Icon
                icon="checkbox-checkmark"
                fill="green"
                width="16px"
                height="16px"
              />
              {value.success > 1 && (
                <Paragraph color="green">{`(${value.success})`}</Paragraph>
              )}
            </Row>
          )}
          {value.manuallyCompleted > 0 && (
            <Row alignItems="center">
              <Icon
                icon="person-edit"
                fill="green"
                width="16px"
                height="16px"
              />
              {value.manuallyCompleted > 1 && (
                <Paragraph color="green">{`(${value.manuallyCompleted})`}</Paragraph>
              )}
            </Row>
          )}
        </Row>
      }
    >
      <>
        {value?.success ? (
          <Paragraph color="white">{`${value.success} Synced`}</Paragraph>
        ) : null}
        {value?.pending ? (
          <Paragraph color="white">{`${value.pending} Pending`}</Paragraph>
        ) : null}
        {value?.other ? (
          <Paragraph color="white">{`${value.other} Not synced`}</Paragraph>
        ) : null}
        {value?.manuallyCompleted ? (
          <Paragraph color="white">{`${value.manuallyCompleted} Manually completed`}</Paragraph>
        ) : null}
      </>
    </StaleInfo>
  </Row>
);

export default StatusIconsCell;
